<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <system-users-table :title="$t('System Users List')"></system-users-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SystemUsersTable from "../Tables/SystemUsersTable"
export default {
  name: "SystemUsers",
  components: {
    SystemUsersTable,
  },
}
</script>
<style></style>