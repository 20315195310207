<template>
<div>
  <employee-reviews-table :title="$t('Reviews')"></employee-reviews-table>
</div>
</template>

<script>
import EmployeeReviewsTable from "@/views/Tables/EmployeeReviewsTable.vue"
export default {
  name: "EmployeeReviews",
  components: {EmployeeReviewsTable}
}
</script>

<style scoped>

</style>