<template>
  <div>
      <base-input
      v-model="model.email"
      type="email"
      form-classes="input-group-alternative mb-3"
      placeholder="Email"
      addon-left-icon="fa fa-envelope m-3"
  >
  </base-input>

    <base-input
        v-model="model.password"
        form-classes="input-group-alternative mb-3"
        placeholder="Password"
        type="password"
        addon-left-icon="fa fa-lock m-3"
    >
    </base-input>

    <div class="text-center">
      <submit-button
          :alert-message="errorMessage"
          :state="loadingProcess"
          @clicked="logIn"
      >
        {{ $t("Submit") }}
      </submit-button>
    </div>
  </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton"
import BaseInput from "@/components/BaseInput"

export default {
  name: "EmailPassword",
  components: {BaseInput, SubmitButton},
  data() {
    return {
      errorMessage: "",
      loadingProcess: "",
      model: {
        email: "",
        password: "",
      },
    }
  },
  methods:{
    async logIn() {
      this.loadingProcess = "processing"

      try {
        await this.$store.dispatch("logIn", {
          email: this.model.email,
          password: this.model.password
        })
        this.loadingProcess = "done"
      } catch (error) {
        this.loadingProcess = "failed"
        const errorCode = error.code
        const errorMessage = error.message
        this.errorMessage = error.message
        console.log(errorCode, errorMessage)
      }
    },
  }
}
</script>

<style scoped>

</style>