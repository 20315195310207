<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-primary"
        style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->
      <div class="">
        <div class="row">
          <div class="col-12 text-start pb-2">
            <div class="badge p-1 font-size-xs float-start" :class="getStatusClass(employeeData.status)">
              <span class="status">{{ $t(employeeData.status ?? "") }}</span>
            </div>
          </div>
          <div class="col-12 text-start">
            <h3 class="text-white float-start pe-3">
              {{ employeeData.firstName }} {{ employeeData.lastName }}
            </h3>
            <rating-box :rating-score="employeeData.ratingScore" :rating-count="employeeData.ratingCount"></rating-box>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="text-start p-2 ps-0">
        <div class="btn-group me-2" role="group" aria-label="secondary nnavigation">
          <router-link :to="'/employees/'+employeeData.id+'/info'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Info") }}</span>
          </router-link>
          <router-link :to="'/employees/'+employeeData.id+'/documents'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Documents") }}</span>
          </router-link>
<!--          <router-link :to="'/employees/'+employeeData.id+'/paymentDetails'" class="btn btn-secondary mt-1 mb-1">-->
<!--            <span class="nav-link-text">{{ $t("Payment Details") }}</span>-->
<!--          </router-link>-->
          <router-link :to="'/employees/'+employeeData.id+'/requests'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Requests") }}</span>
          </router-link>
          <router-link :to="'/employees/'+employeeData.id+'/reviews'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Reviews") }}</span>
          </router-link>
<!--          <router-link :to="'/employees/'+employeeData.id+'/invoices'" class="btn btn-secondary mt-1 mb-1">-->
<!--            <span class="nav-link-text">{{ $t("Invoices") }}</span>-->
<!--          </router-link>-->
        </div>
      </div>
      <router-view :employee-data="employeeData"></router-view>
    </div>
  </div>
</template>
<script>
import {getStatusClass} from "@/helpers/common"
import RatingBox from "@/components/RatingBox.vue"
export default {
  name: "Employee",
  components: {RatingBox},
  data() {
    return {
      employeeId: null,
      nav: null,
      employeeData: {},
      address: {}
    }
  },
  head: {
    title: "Partner Info | AllCare Cyprus",
      meta: [
        {
          name: "description",
          content: "An AllCare partner Profile",
        },
      ]
  },
  async mounted() {
    // var self = this;
    this.employeeId = this.$route.params.id ?? this.$store.state.user.claims.user_id
    await this.getEmployee()
    await this.getEmployeePrivateInfo()
  },
  methods: {
    getStatusClass,
    async getEmployee() {
      let self = this
      await this.$store
          .dispatch("fetchEmployee", this.employeeId)
          .then((data) => {
            console.log("data", data)
            self.employeeData = data
          })
    },
    async getEmployeePrivateInfo(){
      let res = await this.$store.dispatch("fetchEmployeePrivateInfo", this.employeeId)
      console.log("res", res )
      this.address = res.address
      this.employeeData.lang = res.lang
      this.employeeData.email = res.email
      this.employeeData.registrationNumber = res.hasOwnProperty("registrationNumber") ? res.registrationNumber : ""
      this.employeeData.idCard = res.hasOwnProperty("idCard") ? res.idCard : ""
      this.employeeData.phoneNumber = res.phoneNumber
      this.employeeData.iban = res.iban
      this.employeeData.bankName = res.bankName
      this.employeeData.tiNumber = res.tiNumber
      if (res.hasOwnProperty("address")){
        this.employeeData.address = res.address
      }
    },
  },
}
</script>
<style></style>
