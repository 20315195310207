<template>
<div class="container booking-flow__tab">
  <div class="row m-3 mb-5 pt-2 justify-content-md-center bg-white bg-opacity-75" style="border-radius: 10px">
    <div class="col-md-auto col-sm-12 text-sm-left pb-2" style="    min-width: 242px;" :class="{'has-danger': v$.model.departure.$error }">
      <label class="form-label">{{ $t("Departure") }}</label>
      <multiselect
          v-model="model.departure"
          :options="departureOptions"
          placeholder="Select departure"
          :show-labels="false"
          label="value"
          required
          track-by="key"
          class=""
      ></multiselect>
      <div v-if="v$.model.departure.$invalid" class="text-danger invalid-feedback" style="display: block;">
        {{ v$.model.departure.$errors.map( err => { return err.$message })[0] }}
      </div>
    </div>
    <div class="col-md-auto col-sm-12 text-sm-left pb-2" style="    min-width: 242px;" :class="{'has-danger': v$.model.destination.$error }">
      <label class="form-label">{{ $t("Destination") }}</label>
      <multiselect
          v-model="model.destination"
          :options="destinationOptions"
          :show-labels="false"
          placeholder="Select Destination"
          label="value"
          track-by="key"
></multiselect>
      <div v-if="v$.model.destination.$invalid" class="text-danger invalid-feedback" style="display: block;">
        {{ v$.model.destination.$errors.map( err => { return err.$message })[0] }}
      </div>
    </div>
    <div class="col-md-auto col-sm-6 text-sm-left pb-2" :class="{'has-danger': v$.model.depDatetime.$error }">
      <label class="form-label">{{ $t("Departure date") }}</label>
      <VueDatePicker
          v-model="model.depDatetime"
          auto-apply
          :start-time="{ hours: 10, minutes: 0 }"
          minutes-increment="10"
          minutes-grid-increment="10"
          format="dd/MM/yyyy HH:mm"
          close-on-scroll
          :flow="['calendar', 'time']"
          placeholder="Departure date"
      ></VueDatePicker>
      <div v-if="v$.model.depDatetime.$invalid" class="text-danger invalid-feedback" style="display: block;">
        {{ v$.model.depDatetime.$errors.map( err => { return err.$message })[0] }}
      </div>
    </div>
    <div class="col-md-auto col-sm-6 text-sm-left pb-2">
      <label class="form-label">{{ $t("Return date") }}</label>
      <VueDatePicker
          v-model="model.returnDatetime"
          :start-time="{ hours: 10, minutes: 0 }"
          minutes-increment="10"
          minutes-grid-increment="10"
          format="dd/MM/yyyy HH:mm"
          close-on-scroll
          :flow="['calendar', 'time']"
          auto-apply
          placeholder="Return date (optional)"
      ></VueDatePicker>
    </div>
    <div class="col-md-auto col-sm-12 text-sm-left">
      <label class="form-label ">{{ $t("People") }}</label>
      <div class="no_selection pt-2 text-center">
        <i class="fa fa-minus-square " style="cursor: pointer" @click="model.persons !== 1 && model.persons--"></i>
        <span class="p-2">
                    <i class="fa fa-user"></i> {{ model.persons }}
                    </span>
        <i class="fa fa-plus-square " style="cursor: pointer" @click="model.persons++"></i>
      </div>
    </div>
    <div class="col-md-auto col-sm-12 pb-3">
      <label style="color: transparent;" class="form-label">Search</label>
      <button class="w-100 btn btn-primary text-white" @click="searching ? {} : apply()">
        <span v-if="searching" class="spinner-border spinner-border-sm" aria-hidden="true"></span>
<!--        <span v-if="searching"> </span>-->
        <span v-else>{{ $t("Search") }}</span>
      </button>
</div>
  </div>
  <div class="result_list">
    <div v-if="searching" class="spinner-border text-primary">
      <span class="visually-hidden text-white">{{ $t("Loading") }}...</span>
    </div>
    <Transition>
      <div v-if="(searchResults.length === 0 && !searching && showHelper)" style="background: #f0f8ffd9;padding: 15px;border-radius: 10px;">
        <h4>We dont have what you need?</h4>
        <h5>Dont worry we can still cover your needs. Just click Get Quote so we can find you a ride for your trip.</h5>
        <button class="btn btn-primary mt-2" @click="getQuote()">Get Quote</button>
      </div>
    </Transition>
    <div v-for="item in searchResults" :key="item.id" class="card result-card shadow-sm mb-3 ">
      <div class="row no-gutters result_box bg-opacity-75">
        <div class="p-2 col-md-4 col-lg-3">
          <img v-if="item.vehicle.image" :src="item.vehicle.image" class="hotel-card__img card-img">
          <img v-else src="@/assets/images/default.jpeg" class="hotel-card__img card-img">
        </div>
        <div class="col-md-8 col-lg-9">
          <div class="card-body d-flex flex-column h-100">
            <div class="result_title">
              <h4 style="float:left;">{{ item.vehicle.name }}</h4>
              <span :class="item.vehicle.premium ? 'bg-warning' :'bg-primary'"
                    class="m-2 mt-1 badge float-start"
>{{ item.vehicle.premium ? 'PREMIUM' : 'NORMAL' }}</span>
            </div>

            <p class="text-start"> {{ item.vehicle.description }}</p>
            <div class="text-start">
              {{ $t("Max persons") }}: <b>{{ item.vehicle.maxPeople }}</b>
            </div>
            <hr class="w-100">

            <div class="row justify-content-between align-items-center">
              <div class="col-md-8 col-12">
                <div class="row">
                  <span class="col-md-4 col-6 font-1h">{{ $t("Trip cost") }}:
                    <h4> {{ calculateTripCost(model.depDatetime, model.returnDatetime, item.tripCost) }}€</h4></span>
                  <span class="col-md-4 col-6 font-1h">{{ $t("Trip duration") }}:
                    <h4> {{ convertMinsToHrsMins(item.tripDuration) }}</h4></span>
                </div>
              </div>
              <div class="col-md-4 pt-3">
                <button class="btn btn-primary float-end" @click="bookNow(item)">{{ $t("Book Now") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {calculateTripCost} from "@/helpers/common"
import useVuelidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
export default {
  name: "search-view",
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      model: {
        departure: {
          required
        },
        destination: {
          required
        },
        depDatetime: {
          required
        },
      },
    }
  },
  data() {
    return {
      showHelper: false,
      availableRoutes: [],
      searching: false,
      searchResults: [
      ],
      model: {
        departure: null,
        destination: null,
        returnDatetime: null,
        depDatetime: null,
        persons: 2
      }
    }
  },
  computed: {
    departureOptions() {
      console.log("destination", this.model.destination)
      let routes = JSON.parse(JSON.stringify(this.availableRoutes))
      if (this.model.destination) {
        let index = routes.findIndex(r => r.key === this.model.destination.key)
        console.log("index",index)
        if (index >= 0) {
          console.log("departureOptions routes[index]", routes[index])
          routes[index]["$isDisabled"] = true
        }
      }
      return routes
      // return this.availableRoutes.filter(function(el) { return el.key !== selected }, this);
    },
    destinationOptions() {
      let routes = JSON.parse(JSON.stringify(this.availableRoutes))
      if (this.model.departure) {
        console.log("destinationOptions routes", routes)
        let index = routes.findIndex(r => r.key === this.model.departure.key)
        console.log("destinationOptions index",index)
        if (index >= 0) {
          console.log("routes[index]", routes[index])
          routes[index]["$isDisabled"] = true
        }
      }
      console.log("destinationOptions routes final", routes)
      return routes
    }
  },
  async mounted() {
    console.log("$route", this.$route)
    if(this.$store.state.routes) {
      this.availableRoutes = this.$store.state.routes
    } else {
      this.availableRoutes =await this.$store.dispatch("getRouteList")
    }
    this.mapParams()
    await this.search(false)
    this.addDelayToHelper()
  },
  methods: {
    calculateTripCost,
    addDelayToHelper(){
      let self = this
      setTimeout(function () {
        self.showHelper = true
      }, 7000)
    },
    getQuote(){
      let item = {
        tripCost: "",
        tripDuration: "",
        vehicle: {
          id: "",
          name:"",
          premium: "",
          image: ""
        }
      }
      this.bookNow(item)
    },
    validateForm(validate = true){
      if(validate) {
        this.v$.$touch()
      }

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return false
      }
      return  true
    },
    bookNow(item){
      const params = {
        departure: this.model.departure.key,
        destination: this.model.destination.key,
        depDatetime: this.$moment(this.model.depDatetime.toString()).format(),
        returnDatetime: this.model.returnDatetime ? this.$moment(this.model.returnDatetime.toString()).format() : null,
        persons: this.model.persons,
        tripCost: item.tripCost,
        tripDuration: item.tripDuration,
        vehicleId: item.vehicle.id,
        vehicleName: item.vehicle.name,
        vehiclePremium: item.vehicle.premium,
        vehicleImage: item.vehicle.image
      }
      this.$router.push({ path: "/checkout", query: params })
    },
    mapParams(){
      const params = this.$route.query

      const departure = this.availableRoutes.find(i => i.key === params.departure)
      const destination = this.availableRoutes.find(i => i.key === params.destination)

      this.model.departure = departure ?? this.model.departure
      this.model.destination = destination ?? this.model.destination
      this.model.returnDatetime = params.returnDatetime ?? this.model.returnDatetime
      console.log("mapParams params.depDatetime", params.depDatetime)
      console.log("mapParams this.$moment(params.depDatetime)", this.$moment(params.depDatetime).toDate())
      this.model.depDatetime = params.depDatetime ? params.depDatetime : this.model.depDatetime
      this.model.persons = params.persons ? parseInt(params.persons) : this.model.persons
    },
    apply(){
      const params = {
        departure: this.model.departure ? this.model.departure.key : null,
        destination: this.model.destination ? this.model.destination.key : null,
        depDatetime: this.model.depDatetime ? this.$moment(this.model.depDatetime.toString()).format() : null,
        returnDatetime: this.model.returnDatetime ? this.$moment(this.model.returnDatetime.toString()).format() : null,
        persons: this.model.persons.toString(),
      }
      if(JSON.stringify(params) === JSON.stringify(this.$route.query)){
        this.search()
      } else {
        this.$router.push({path: "/search", query: params})
      }
    },
    async search(validate = true) {
      if(!this.validateForm(validate)) {
        return
      }
      this.searching = true

      const payload = {
        model:  this.model,
        toggle: false
      }

      let routes = await this.$store.dispatch("fetchRoutes", payload)
      if(routes.length === 0) {
        payload.toggle = true
        routes = await this.$store.dispatch("fetchRoutes", payload)
      }
      this.searching = false
      console.log("routes", routes)

      if (routes.length === 0 ){
        this.showHelper = true
      }
      this.searchResults = routes
    },
    convertMinsToHrsMins(mins, longText = true) {
      let h = Math.floor(mins / 60)
      let m = mins % 60


      if(h === 1){
        if(m > 0) {
          if(longText){
            return `${h+ " "+ this.$t("hour") } ${m+ " "+ this.$t("minutes")}`
          } else {
            return `${h+ this.$t("h") } ${m+ this.$t("m")}`
          }

        } else {
          return `${h+ " "+ this.$t("hour") }`
        }
      }

      else if (h > 1) {
        if(m > 0) {
          if(longText){
            return `${h+ " "+ this.$t("hours") } ${m+ " "+ this.$t("minutes")}`
          } else {
            return `${h+ this.$t("h") } ${m+ this.$t("m")}`
          }

        }

        if(longText){
          return `${h + " " + this.$t("hours") }`
        } else {
          return `${h+ this.$t("h") }`
        }
      } else {
        if(longText){
          return m + " "+ this.$t("minutes")
        } else {
          return m + " "+ this.$t("m")
        }

      }
    }
  }
}

</script>

<style scoped>
@media (min-width: 568px) {
  .multiselect__tags {
    width: 150px;
  }
}
.booking-flow__tab {
  margin-top: 50px;
}

.hotel-card__img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.result_list .card.shadow-sm.mb-3:hover {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 10%) !important;
  transition: box-shadow 0.3s ease-in-out;
}

.result_list .card.shadow-sm.mb-3 {
  transition: box-shadow 0.3s ease-in-out;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>