<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="col-xl-8 order-xl-1">
        <card shadow type="secondary">
          <template #header>
            <div class="border-0">
              <div class="row align-items-center">
                <div class="col-8 text-start">
                  <h5 class="mb-0">Manage Claims</h5>
                </div>
                <!--                  <div class="col-4 text-right">-->
                <!--                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>-->
                <!--                  </div>-->
              </div>
            </div>
          </template>
          <!--          <b-form-select v-model="packageSelected" :options="options"></b-form-select>-->

          <form>
            <!--            <h6 class="heading-small text-muted mb-4">select Package</h6>-->
            <div class="ps-lg-4">
              <div class="row">
                <div class="col-lg-3">
                  <div class="form-group has-label">
                    <label class="form-control-label">{{ $t("Search Type") }}</label>
                    <select
                      id="cars"
                      v-model="searchType"
                      class="form-control form-control-alternative"
                      name="cars"
                      @change="resetSearchFormInputs($event)"
                    >
                      <option value="authId">By Auth</option>
                      <option value="regMobileNumber">
                        By Reg Mobile Number
                      </option>
                      <option value="email">By Email</option>
                    </select>
                  </div>
                </div>
                <div v-if="searchType === 'authId'" class="col-lg-5">
                  <base-input
                    v-model="authId"
                    alternative=""
                    label="Auth Id"
                    placeholder="Auth Id"
                    input-classes="form-control-alternative"
                    @keydown="resetSearchForm()"
                  />
                </div>
                <div v-if="searchType === 'email'" class="col-lg-5">
                  <base-input
                    v-model="email"
                    alternative=""
                    label="Email"
                    placeholder="Email"
                    input-classes="form-control-alternative"
                    @keydown="resetSearchForm()"
                  />
                </div>
                <div v-if="searchType === 'regMobileNumber'" class="col-lg-5">
                  <base-input
                    v-model="regMobileNumber"
                    alternative=""
                    label="Registration Phone Number"
                    placeholder="Phone Number (include +357)"
                    input-classes="form-control-alternative"
                    @keydown="resetSearchForm()"
                  />
                </div>
                <div class="col-lg-4 pt-2">
                  <br />
                  <submit-button :state="loadingProcess" @clicked="getClaims()">
                    {{ $t("Get Claims") }}
</submit-button>

                  <!--                                    <base-button @click="getClaims()" :state="loadingProcess" :class="loadingProcess === 'done' ? 'bg-gradient-green border-0' : 'bg-gradient-red border-0' " :disabled="paymentProcess !== ''" >-->
                  <!--                    <template   v-if="loadingProcess === 'processing'">-->
                  <!--                      <span class="fas fa-cog fa-spin" role="status" aria-hidden="true"></span>-->
                  <!--                      Loading...-->
                  <!--                    </template>-->
                  <!--                    <template v-else-if="loadingProcess === 'done'">-->
                  <!--                      <span class="fas fa-money-check" role="status" aria-hidden="true"></span>-->
                  <!--                      Done!-->
                  <!--                    </template>-->
                  <!--                    <template v-else>-->
                  <!--                      Get Claims-->
                  <!--                    </template>-->
                  <!--                  </base-button>-->
                </div>
                <div class="col-lg-12">
                  <base-alert v-if="showError" type="danger" dismissible>
                    <span class="alert-inner--text">
                      <strong>Search Failed!</strong><br />
                      <label class="ps-1">{{ claimAlertMessage }} </label>
                    </span>
<!--                    <button-->
<!--                      type="button"-->
<!--                      class="close"-->
<!--                      data-dismiss="alert"-->
<!--                      aria-label="Close"-->
<!--                    >-->
<!--                      <span aria-hidden="true">&times;</span>-->
<!--                    </button>-->
                  </base-alert>
                </div>
              </div>
            </div>
            <hr class="my-4" />
          </form>
        </card>
        <br />
        <card v-if="showForm" shadow type="secondary">
          <template #header>
            <div class="border-0">
              <div class="row align-items-center">
                <div class="col-6 text-start">
                  <h5 class="mb-0">User Claims</h5>
                </div>
                <div class="col-6 text-right">
                  <label
                    class="text-white rounded shadow bg-gradient-green ps-2 pe-2"
                    >{{ model.authId }}</label>
                </div>
              </div>
            </div>
          </template>
          <form
            :style="
              loadingProcess === 'processing'
                ? 'filter: blur(8px);-webkit-filter: blur(1px);'
                : ''
            "
          >
            <div
              v-if="loadingProcess === 'processing'"
              style="
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                text-align: center;
                padding-top: 20%;
                font-size: 30px;
                z-index: 10;
              "
            >
              <span
                style="position: absolute; top: 20%"
                class="fas fa-cog fa-spin"
                role="progressbar"
                aria-label="updating form"
                aria-hidden="true"
              ></span>
            </div>
            <div class="ps-lg-4">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-model="model.role"
                    alternative=""
                    label="Role"
                    placeholder="Role"
                    input-classes="form-control-alternative"
                    :valid="!v$.model.role.$invalid"
                    :error="v$.model.role.$errors.map((err) => {return err.$message;}).join()"
                  />
                </div>
                <div class="col-lg-12">
                  <base-alert v-if="showFormError" type="danger" dismissible>
                    <span class="alert-inner--text">
                      <strong>Search Failed!</strong><br />
                      <label class="ps-1">{{ formAlertMessage }} </label>
                    </span>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </base-alert>
                </div>

                <div class="col-12 text-end">
                  <base-button class="me-2" outline type="info" @click="resetClaims">{{ $t("Reset") }}</base-button>
                  <submit-button :state="updatingProcess" @clicked="submitClaims">{{ $t("Update") }}</submit-button>
                </div>
                <!--                <hr class="my-4" />-->
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import { or, sameAs } from "@vuelidate/validators"

export default {
  name: "Manage Claims",
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      model: {
        companyId: {
          // minLength: minLength(34),
          // maxLength: maxLength(34),
          // requiredIfFunction: requiredIf(this.model.companyId.length > 0),
        },
        role: {
          comparison: or(
            sameAs(""),
            sameAs("EMPLOYEE"),
            sameAs("SYSTEM_USER"),
            sameAs("OWNER")
          ),
          // comparison: helpers.withMessage('This field cannot be empty',or(sameAs("") ,sameAs("SYSTEM_USER"), sameAs("OWNER")))
        },
      },
    }
  },
  data() {
    return {
      showFormError: false,
      formAlertMessage: "",
      showError: false,
      claimAlertMessage: "",
      updatingProcess: "",
      loadingProcess: "",
      showForm: false,
      packageSelected: "50",
      options: [
        { value: null, text: "Please select an option" },
        { value: "100", text: "100 Tokens" },
        { value: "200", text: "200 Tokens" },
        { value: "300", text: "300 Tokens" },
      ],
      searchType: "regMobileNumber",
      email: "",
      regMobileNumber: "",
      authId: "",
      model: {
        companyId: null,
        role: null,
      },
      claims: {
        authId: null,
        companyId: null,
        role: null,
      },
    }
  },
  methods: {
    resetSearchForm() {
      this.loadingProcess = ""
      this.showError = false
    },
    resetSearchFormInputs() {
      this.authId = ""
      this.regMobileNumber = ""
      this.email = ""
    },
    resetClaims() {
      this.model = JSON.parse(JSON.stringify(this.claims))
    },
    submitClaims() {
      let self = this
      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      console.log(this.model)

      FirebaseFunctionsHelper.callFunction(
        "updateUserClaims",
        // eslint-disable-next-line no-undef
        this.model
      )
        .then((result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result && result.hasOwnProperty("message")) {
            this.showFormError = true
            this.formAlertMessage = result.message
            return
          }
          this.updatingProcess = "done"
          setTimeout(function () {
            self.updatingProcess = ""
          }, 2000)
        })
        .catch((error) => {
          this.showError = true
          this.claimAlertMessage = error.message
          console.log("error", error)
        })
    },
    setModel(result) {
      this.model.companyId = result.companyId ?? ""
      this.model.role = result.role ?? ""
      this.model.authId = result.authId
    },
    getClaims() {
      let self = this
      this.loadingProcess = "processing"
      let data = {}
      switch (this.searchType) {
        case "authId":
          data["authId"] = this.authId
          break
        case "email":
          data["email"] = this.email
          break
        case "regMobileNumber":
          data["regMobileNumber"] = this.regMobileNumber
          break
      }
      FirebaseFunctionsHelper.callFunction(
        "getUserClaims",
        // eslint-disable-next-line no-undef
        data
      )
        .then((result) => {
          // eslint-disable-next-line no-prototype-builtins
          if (result && result.hasOwnProperty("message")) {
            this.showError = true
            this.loadingProcess = ""
            this.claimAlertMessage = result.message
            return
          }

          this.claims = result
          this.setModel(result)
          this.loadingProcess = "done"
          this.showForm = true
          setTimeout(function () {
            self.loadingProcess = ""
          }, 2000)
        })
        .catch((error) => {
          this.showError = true
          this.loadingProcess = ""
          this.claimAlertMessage = error.message
          console.log("error", error)
        })
    },
  },
}
</script>
<style></style>
