<template>
    <modal :show="modalBool" @close="modalBool = false">
      <template v-slot:header>
        <h5 id="exampleModalLabel" class="modal-title">
          {{ isUpdate ? $t("Update User") : $t("Create User") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-6">
              <base-input
                  v-model="model.firstName"
                  alternative=""
                  :label="$t('First name')"
                  :placeholder="$t('First name')"
                  input-classes="form-control-alternative"
                  :error="v$.model.firstName.$errors.map( err => { return err.$message })[0]"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                  v-model="model.lastName"
                  alternative=""
                  :label="$t('Last name')"
                  :placeholder="$t('Last name')"
                  input-classes="form-control-alternative"
                  :error="v$.model.lastName.$errors.map( err => { return err.$message })[0]"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                  v-model="model.email"
                  :label="$t('Email address')"
                  input-classes="form-control-alternative"
                  :placeholder="$t('Email address')"
                  >
                <template #infoBlock>
                  <span class="text-sm text-muted w-100">Log in email.</span>

                </template>
              </base-input>
              <div v-if="v$.model.email.$invalid" class="text-danger invalid-feedback" style="display: block;">
                {{ v$.model.email.$errors.map( err => { return err.$message })[0] }}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label float-start">{{ $t("Contact phone (optional)") }}</label>
                <base-input
                    v-model="model.contactPhone"
                    type="number"
                    formClasses="input-group-alternative mb-3 phone-input"
                    style="display: flex;border-radius: 0;"
                    :placeholder="$t('Contact phone:')"
                    :error="v$.model.contactPhone.$errors.map( err => { return err.$message })[0]"
                >
                  <template #addonLeft>
                    <select v-model="model.contactPhoneCode" class="form-control form-control-alternative" style="padding: 0;height: 23px;border: none !important;">
                      <option :key="country.code" v-for="country in countryCodes" :value="country.code"> {{ isWindows ? "" : isoCountryCodeToFlagEmoji(country.iso)
                        }} {{ country.iso }} {{ country.code }}</option>
                    </select>
                  </template>
                </base-input>
              </div>
            </div>
            <div v-if="!isUpdate" class="col-lg-6">
              <base-input
                  v-model="model.password"
                  alternative=""
                  :label="$t('Password')"
                  :placeholder="$t('Password')"
                  input-classes="form-control-alternative"
                  :error="v$.model.password.$errors.map( err => { return err.$message })[0]"
              >
              </base-input>
            </div>
            <div class="col-lg-6">
              <div class="form-group has-label">
                <label class="form-control-label">{{ $t("Preferred language") }} </label>
                <multiselect
                    v-model="model.lang"
                    :options="['en','el']"
                    :placeholder="$t('Select language')"
                    :show-labels="false"
                    required
                    class=""
                >
                  <template #singleLabel="{ option }">{{ $t(option) }}</template>
                  <template #option="props" >
                    {{ $t(props.option) }}
                  </template>
                </multiselect>
              </div>
            </div>
<!--            <div class="col-lg-6">-->
<!--              <div class="form-group has-label">-->
<!--                <label class="form-control-label">{{ $t("Status") }} </label>-->
<!--                <multiselect-->
<!--                    v-model="model.status"-->
<!--                    :options="['VERIFIED', 'PENDING', 'DISABLED']"-->
<!--                    :placeholder="$t('Select status')"-->
<!--                    :show-labels="false"-->
<!--                    required-->
<!--                    class=""-->
<!--                >-->
<!--                  <template v-slot:singleLabel="{ option }" >{{ $t(option) }}</template>-->

<!--                </multiselect>-->
<!--              </div>-->
<!--            </div>-->

          </div>
        </div>
      </div>
      <template v-slot:footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="submit()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
</template>

<script>
import Modal from "@/components/Modal"
import BaseInput from "@/components/BaseInput"
import SubmitButton from "@/components/SubmitButton"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {helpers, required, email, requiredIf, minLength} from "@vuelidate/validators"
import {getCountries, getCountryCallingCode, isValidNumber, parsePhoneNumber} from "libphonenumber-js"
import {isoCountryCodeToFlagEmoji, isWindows} from "@/helpers/common"
const isValidPhone = (param) => helpers.withParams(
    { type: "isValidPhone", value: param }, (value) => {

      if (value == null || value === ""){
        return true
      }
      return isValidNumber(param+value)
    })

export default {
  name: "ClientUserForm",
  components: {SubmitButton, BaseInput, Modal},
  props:{
    clientId: {
      type: String,
      default: null
    },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isWindows: isWindows,
      modalBool: true,
      loadingProcess: "",
      errorMessage: "",
      countryCodes: [],
      model: {
        lang: "en",
        clientId: null,
        email: null,
        userId: null,
        firstName: null,
        lastName: null,
        status: "VERIFIED",
        contactPhone: null,
        contactPhoneCode: "+357"
      },
    }
  },
  validations () {
    return {
      model: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        status: {
          required,
        },
        email: {
          required, email
        },
        password: {
          requiredIdNotUpdate: requiredIf(!this.isUpdate),
          minLength: minLength(6)
        },
        contactPhone: {
          validated: helpers.withMessage(this.$t("Must be valid phone number."), isValidPhone(this.getValueOrDefault(this.model, "contactPhoneCode", null )))
        },
      }
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
    isUpdate() {
      return ["editClientUser","editClientAccountUser"].includes(this.$route.name)
    },
  },
  watch: {
    clientId(newVal) {
        console.log("clientId ?????", newVal)
      this.model.clientId = newVal
        this.getUser()
    },
    modalBool(newVal) {
      if (!newVal){
        this.$router.push({ path: this.userRole === "CLIENT" ? "/account/users" : "/clients/"+this.clientId+"/users" })
      }
    },
  },
  async mounted() {
    if (this.isUpdate){
      await this.getUser()
    }

    let countries = getCountries()

    countries.forEach( country => {
      let code = getCountryCallingCode(country)
      this.countryCodes.push({code: "+" + code, text: country + " +" + code, iso: country})
    })
  },
  methods:{
    isoCountryCodeToFlagEmoji,
    async getUser() {
      if (this.clientId == null && this.userRole === "SYSTEM_USER" || !this.isUpdate){
        return
      }

      let result = await this.$store.dispatch("fetchClientUser", {
        id: this.userRole === "SYSTEM_USER" ? this.$route.params.userId : this.$route.params.id,
        clientId : this.clientId
      })

      if (result) {
        this.model = result
        console.log("looks for contact phone", this.model)
        if (result.hasOwnProperty("contactPhone") && result.contactPhone !== null) {

          let phoneDetails = parsePhoneNumber(result.contactPhone)
          this.model.contactPhone = phoneDetails.nationalNumber
          this.model.contactPhoneCode = "+" + phoneDetails.countryCallingCode
        } else {
          this.model.contactPhoneCode = "+357"
        }
      }
    },
    submit(){
      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      const payload = {
        clientId: this.model.clientId,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        status: this.model.status,
        email: this.model.email,
        password: this.model.password,
        lang: this.model.lang
      }

      if (this.isUpdate){
        payload.userId = this.model.id
      }

      if (this.model.contactPhone != null && this.model.contactPhone !== ""){
        payload.contactPhone = this.model.contactPhoneCode + this.model.contactPhone
      }

      console.log("payload", payload)

      this.loadingProcess = "processing"
      let self = this
      FirebaseFunctionsHelper.callFunction(
          this.isUpdate ? "client-updateUser" : "client-createUser",
          payload
      )
          .then((result) => {
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              this.loadingProcess = "failed"
              this.errorMessage = result.message
              return
            }
            this.loadingProcess = ""

            this.modalBool = false
          }, this)
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })
    },
    getValueOrDefault(obj, prop, defaultValue){
      return Object.prototype.hasOwnProperty.call(obj, prop) ? (obj[prop] || defaultValue) : defaultValue
    }
  },
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 776px) {
  .modal-dialog{
    max-width: 50% !important;
  }
}

</style>