<template>
    <modal :show="modalBool" @close="modalBool = false">
      <template #header>
        <h5 id="exampleModalLabel" class="modal-title">
          {{ $t(isUpdate ? "Edit record" : "New Record") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-12">
              <base-input
                  v-model="position.name"
                  alternative=""
                  :label="$t('Position name')"
                  :placeholder="$t('Position name')"
                  input-classes="form-control-alternative"
                  :error="v$.position.name.$errors.map( err => { return err.$message })[0]"
              />
            </div>
            <div class="col-lg-6">
              <base-input
                  v-model="position.normalDayHourlyRate"
                  alternative=""
                  type="number"
                  :label="$t('Normal day hourly rate (€)')"
                  :placeholder="$t('Normal day hourly rate')"
                  input-classes="form-control-alternative"
                  :error="v$.position.normalDayHourlyRate.$errors.map( err => { return err.$message })[0]"
              >
                <template #addonRight>{{ calcRate(position.normalDayHourlyRate) }}</template>
              </base-input>
            </div>
            <div class="col-lg-6">
<base-input
                  v-model="position.normalNightHourlyRate"
                  alternative=""
                  type="number"
                  label="Normal night hourly rate (€)"
                  placeholder="Normal night hourly rate"
                  input-classes="form-control-alternative"
                  :error="v$.position.normalNightHourlyRate.$errors.map( err => { return err.$message })[0]"
              >
                <template #addonRight>{{ calcRate(position.normalNightHourlyRate) }}</template>
              </base-input>
            </div>

            <div class="col-lg-6">
              <base-input
                  v-model="position.holidayDayHourlyRate"
                  alternative=""
                  type="number"
                  label="Holiday day hourly rate (€)"
                  placeholder="Holiday day hourly rate"
                  input-classes="form-control-alternative"
                  :error="v$.position.holidayDayHourlyRate.$errors.map( err => { return err.$message })[0]"
              >
                <template #addonRight>{{ calcRate(position.holidayDayHourlyRate) }}</template>
              </base-input>
            </div>
            <div class="col-lg-6">
              <base-input
                  v-model="position.holidayNightHourlyRate"
                  alternative=""
                  type="number"
                  label="Holiday night hourly rate (€)"
                  placeholder="Holiday night hourly rate (€)"
                  input-classes="form-control-alternative"
                  :error="v$.position.holidayNightHourlyRate.$errors.map( err => { return err.$message })[0]"
              >
                <template #addonRight>{{ calcRate(position.holidayNightHourlyRate) }}</template>
              </base-input>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="submit()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
</template>

<script>
import Modal from "@/components/Modal"
import BaseInput from "@/components/BaseInput"
import SubmitButton from "@/components/SubmitButton"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import {countDecimals, getPercentage, toCents} from "@/helpers/common"
import useVuelidate from "@vuelidate/core"
import {helpers, required, email, requiredIf, minLength} from "@vuelidate/validators"
const customFloatValidation = (value) => {
  console.log("value", value.toString().split(".")[1])
  let dec = value.toString().split(".")[1]

  if (dec){
    return dec.length <=2
  }
  return true
}

export default {
  name: "PositionForm",
  components: {SubmitButton, BaseInput, Modal},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      modalTitle: "New Position",
      modalBool: true,
      loadingProcess: "",
      errorMessage: "",
      position: {
        name: "",
        holidayDayHourlyRate: null,
        holidayNightHourlyRate: null,
        normalNightHourlyRate: null,
        normalDayHourlyRate: null
      }
    }
  },
  validations () {
    return {
      position: {
        name: {
          required
        },
        holidayDayHourlyRate: {
          required,
          customFloatValidation: helpers.withMessage("2 decimals max", customFloatValidation)
        },
        holidayNightHourlyRate: {
          required,
          customFloatValidation: helpers.withMessage("2 decimals max", customFloatValidation)
        },
        normalNightHourlyRate:{
          required,
          customFloatValidation: helpers.withMessage("2 decimals max", customFloatValidation)
        },
        normalDayHourlyRate: {
          required,
          customFloatValidation: helpers.withMessage("2 decimals max", customFloatValidation)
        },
      }
    }
  },
  computed: {
    isUpdate() {
      return this.$route.name === "editPositionRecord"
    },
    calcRate() {
      return rate => (getPercentage(rate, this.$store.state.settings.companyCommissionRatePercent).toFixed(2) + "/" +
          getPercentage(rate, this.$store.state.settings.employeeRatePercent).toFixed(2))
    }
  },
  watch: {
    modalBool(newVal) {
      if (!newVal){
        this.$router.push({ path: "/positions" })
      }
    },
  },
  async mounted() {
    if (this.isUpdate) {
      let positions = await this.$store.dispatch("fetchAllPositions")
      this.position = positions.find(o => o.id === this.$route.params.id)
      this.position.holidayDayHourlyRate = this.position.holidayDayHourlyRate/100
      this.position.holidayNightHourlyRate = this.position.holidayNightHourlyRate/100
      this.position.normalNightHourlyRate = this.position.normalNightHourlyRate/100
      this.position.normalDayHourlyRate = this.position.normalDayHourlyRate/100
    }
  },
  methods:{
    submit(){
      this.v$.$touch()

      if (this.v$.position.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      const payload = {
        positionId: this.position.id,
        name: this.position.name,
        holidayDayHourlyRate: toCents(this.position.holidayDayHourlyRate),
        holidayNightHourlyRate: toCents(this.position.holidayNightHourlyRate),
        normalNightHourlyRate: toCents(this.position.normalNightHourlyRate),
        normalDayHourlyRate: toCents(this.position.normalDayHourlyRate),
      }

      console.log("payload", payload)

      this.loadingProcess = "processing"
      let self = this
      FirebaseFunctionsHelper.callFunction(
          this.isUpdate ? "position-update" : "position-create",
          payload
      )
          .then(async (result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("resmessage", result.message)
              this.loadingProcess = "failed"
              this.errorMessage = result.message
              return
            }
            console.log("submitUser result", result)
            this.$store.commit("SET_EXPIRE_POSITIONS")
            this.loadingProcess = ""
            this.modalBool = false
          }, this)
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })
    },
  },
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (min-width: 776px) {
  .modal-dialog{
    max-width: 50% !important;
  }
}

</style>