<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <h5 v-if="!isUpdate" class="mb-0">{{ $t('New Invoice') }}</h5>
                      <h5 v-else class="mb-0">{{ $t('Update Invoice') }}</h5>
                    </div>
                  </div>
                </div>
              </template>
              <form>
<!--                <div class="text-start fw-bolder text-uppercase text-muted">Personal Details </div>-->
<!--                <hr class="mt-0">-->
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.employeeId.$error }">
                        <label class="form-label">{{ $t("Select Employee") }}</label>
                        <multiselect
                            v-model="model.employeeId"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            :placeholder="$t('Select Employee')"
                            :options="employees" :multiple="false"
                            :error="v$.model.employeeId.$errors.map( err => { return err.$message })[0]"
                            @change="console.log('adadd')"
                        ></multiselect>
                        <div v-if="v$.model.employeeId.$invalid" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.employeeId.$errors.map( err => { return err.$message })[0] }}
                        </div>
                      </div>
                    </div>

                    <div v-if="model.employeeId != null" class="col-lg-12 pb-3">
                        <base-button  class="float-start" @click="requestsModal = true" size="sm" type="primary">Add requests</base-button>
                    </div>
                    <div class="col-lg-12 pb-3">

                        <table v-if="model.requests.length > 0" class="table text-start">
                          <thead><tr>
                            <th>Position</th>
                            <th>{{ $t("Company commission") }}</th>
                            <th>Remove</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="request in model.requests " :key="request">
                            <th>{{request.position.name}} ({{ request.clientName }})</th>
                            <td>{{ request.cost.companyCommission/100+'€' }}</td>
                            <td>
                              <button class="btn btn-link float-end text-danger" @click="removeRequest(request.id)"><i class="fa fa-times"></i></button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <div v-else class="text-start">No request added.</div>

                    </div>
                    <modal id="requestsModal" :show="requestsModal" @close="requestsModal = false">
                      <template v-slot:header>
                        <h5 class="modal-title">
                          {{ "Select requests to add" }}
                        </h5>
                      </template>
                      <div class="table-responsive">
                        <base-table
                            v-model:loading="loadingRequests"
                            :columns="columns"
                            class="table align-items-center table-flush text-start"
                            tbody-classes="list"
                            :data="requests"
                            :sort-by="sortBy"
                            :sort-direction="sortDirection"
                            :paginated="true"
                            @sort="getRequests"
                            @fetch-more="getRequests"
                        >


                          <template #default="row">
                            <td>
                              <input class="form-check-input p-2" type="checkbox" value="" @change="toggleRequest(row.item)">
                            </td>
                            <td>
                              <a
                                  target="_blank"
                                  :href="'/requests/' + row.item.id"
                              >
                                <span class="name mb-0 text-sm">{{ row.item.refNumber ?? "No Ref" }}</span>
                              </a>

                            </td>
                            <td v-if="!hasUser" class="budget">
                              <a
                                  target="_blank"
                                  :href="'/clients/' + row.item.clientId"
                              ><span class="name mb-0 text-sm">{{ row.item.clientName }}</span></a>
                            </td>
                            <td>
                              {{ row.item.cost.companyCommission/100+'€' }}
                            </td>
                            <!--          <td>-->
                            <!--            <div class="badge p-1 font-size-xs" :class="getStatusClass(row.item.paymentStatus)">-->
                            <!--              <span class="status">{{ $t(row.item.paymentStatus) }}</span>-->
                            <!--            </div>-->
                            <!--          </td>-->
                            <td>
                              <div class="name mb-0 text-md-left">
                                {{ row.item.endDate }}
                              </div>
                            </td>
                          </template>
                        </base-table>
                      </div>
                      <template v-slot:footer>
                        <base-button
                            :alert-message="errorMessage"
                            :state="loadingProcess"
                            type="primary"
                            @click="requestsModal = false"
                        >
                          {{ $t("Done") }}
                        </base-button>
                      </template>
                    </modal>
<!--                    <div class="col-lg-6">-->
<!--                      <div class="form-group has-label">-->
<!--                        <label class="form-label">{{ $t("Status") }}</label>-->
<!--                        <multiselect-->
<!--                            v-model="model.invoiceStatus"-->
<!--                            :show-labels="false"-->
<!--                            label="name"-->
<!--                            track-by="id"-->
<!--                            :placeholder="$t('Select Status')"-->
<!--                            :options="[{id: 'PENDING', name: $t('Pending')}, {id: 'VERIFIED', name: $t('Verified')}]" :multiple="false"-->
<!--                        ></multiselect>-->

<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>

                <div class="text-start fw-bolder text-uppercase text-muted">Invoice Cost </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <label v-if="!invoiceCost" class="text-start float-start">Cost cannot be calculated yet.</label>
                    <div class="col-md-4 pt-2">
                      <display-label
                          label="Total cost:"
                          :value="model.totalAmount == null ? '€' : model.totalAmount/100+'€'"
                      ></display-label>

                    </div>
                    </div>
                  </div>

<!--                <div class="text-start fw-bolder text-uppercase text-muted">Documents </div>-->
<!--                <hr class="mt-0">-->
<!--                <div class="ps-lg-4">-->
<!--                  <div class="row"></div>-->
<!--                </div>-->
                <div class="col-12 text-end">
                  <base-button v-if="!isUpdate" class="me-2" outline type="info" @click="clearForm">{{ $t("Reset") }}</base-button>
                  <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submit">{{ $t('Submit') }}</submit-button>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {required, minLength} from "@vuelidate/validators"
import DisplayLabel from "@/components/DisplayLabel"
import {calculateShiftCost} from "@/helpers/shiftCostCalculator"
import {getStatusClass, toCents} from "@/helpers/common"
import BaseButton from "@/components/BaseButton"
import Modal from "@/components/Modal"
import SubmitButton from "@/components/SubmitButton"
//
// const maxIfNotEmpty = (param) => helpers.withParams(
//     { type: "maxIfNotEmpty", value: param }, (value) => {
//
//       if (param == null || param === "" || value == null || value === ""){
//         return true
//       }
//
//       return param < value
//     })
export default {
  name: "newInvoice",
  components: {SubmitButton, Modal, BaseButton, DisplayLabel},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loadingRequests: false,
      requests: [],
      columns: [
        {
          key:"Add",
          label:"Add"
        },
        {
          key:"Ref",
          label:"Ref"
        },
        {
          key:"Client",
          label:"Client"
        },
        {
          key:"Commission",
          label:"Commission"
        },
        {
          key: "endDate",
          label:"End Date",
          sorting: true
        }
      ],
      sortDirection: "desc",
      sortBy: "createdAt",
      positions: [],
      employees: [],
      requestsModal: false,
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      model: {
        requests: [],
        employeeId: null,
        totalAmount: 0
      }
    }
  },
  validations () {
    return {
      model: {
        requests: {
          required,
          minLength: minLength(1)
        },
        employeeId: {
          required
        }
      },
    }
  },
  computed: {
    invoiceCost(){

      if (this.model.requests.length === 0) {
        // console.log(this.v$.$errors)
        return false
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.model.totalAmount = this.model.requests.map(item => item.cost.companyCommission).reduce((prev, next) => prev + next)
      return true
    }
  },
  watch: {
    "model.employeeId": function (newValue){
      console.log("newValue", newValue)
      this.model.requests = []
      this.getRequests()
    }
  },
  async mounted() {
    await this.getPositions()
    await this.getSettings()
    await this.getEmployees()
    if (this.$route.params.hasOwnProperty("id")) {
      this.isUpdate = true
      await this.getInvoice()
    }
  },
  methods: {
    toggleRequest(item){
      console.log("item", item)
      let index = this.model.requests.findIndex(r => r.id === item.id)
      console.log("toggleRequest index", index)
      if (index > -1){
        this.model.requests.splice(index, 1)
      } else {
        this.model.requests.push(item)
      }
    },
    removeRequest(id){
      console.log("removeRequest id", id)
      console.log("this.model.requests", this.model.requests)
      let index = this.model.requests.findIndex(r => r.id === id)
      console.log("index", index)
      if (index > -1){
        this.model.requests.splice(index, 1)
      }
    },
    getStatusClass,
    async getRequests(){
      this.loadingRequests =true

      if (this.model.employeeId == null){
        return
      }

      await this.$store.dispatch("fetchRequests", {
        filters: {
          statuses: ["CLAIMED"],
          invoiceId: null,
          employees: [this.model.employeeId.id]
        }
      }).then((data) => {
        this.loadingRequests = false
        console.log("fetchRequests", data)
        this.requests = data.data
      })
    },
    async getSettings() {
      await this.$store.dispatch("fetchSettings").then((data) => {
        console.log("fetchSettings", data)
      })
    },
    async getEmployees() {
      await this.$store.dispatch("fetchEmployees", {limit: 20}).then((data) => {
        console.log("fetchEmployees", data)
        this.employees = data.data
      })
    },
    async getPositions() {
      await this.$store.dispatch("fetchAllPositions").then((data) => {
        console.log("dataaa", data)
        this.positions = data
      })
    },
    customCityLabel (option) {
      return this.$t(option)
    },
    async getInvoice() {
      const data = await this.$store.dispatch("fetchInvoice", this.$route.params.id)

      let selectedPositions = []
      data.forEach( positionsId => {
        let selected = this.positions.find(pos => pos.key === positionsId )
        if (selected) {
          selectedPositions.push(selected)
        }
      })

      this.model = data
      this.model.positionIds = selectedPositions
    },
    async submit() {
      let self = this

      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      let payload = JSON.parse(JSON.stringify(this.model))

      payload.employeeId = payload.employeeId.id

      payload.requests = payload.requests.map( r => { return {requestId: r.id, clientId: r.clientId} })
      // payload.invoiceStatus = payload.invoiceStatus.id

      console.log("payload", payload)

      this.loadingProcess = "processing"
      FirebaseFunctionsHelper.callFunction(
          "invoice-create",
          // eslint-disable-next-line no-undef
          payload
      )
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              self.loadingProcess = "failed"
              self.errorMessage = result.message
              return
            }
            console.log("submit invoice result", result)
            this.loadingProcess = "done"
            this.$router.push({path: "/invoices"})

          })
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      this.loadingProcess = ""
      this.model = {
        clientId: null,
        deadline: null,
        startDate: null,
        endDate: null,
        position: null,
        cost: {
          totalCost: null,
          employeeEarnings: null,
          companyCommission: null,
        }
      }
    },
  },
}
</script>
<style scoped>
.form-group.has-label {
  padding-top: 0.5rem !important;
}
</style>
