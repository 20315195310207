<template>
  <div v-if="ratingScore !== ''" class="badge bg-white" style="background-color: #F79426;">
<span class="font-size-md text-black pe-1">
              {{ parseFloat(ratingScore).toFixed(2) }}</span>
    <i class="fa fa-star font-size-md" style="color: #F79426;"></i>
    <br>
    <span class="text-black-50 d-block pt-1">{{ ratingCount }} {{ $t(ratingCount > 1 ? "Reviews" : "Review") }}</span>
  </div>
</template>

<script>
export default {
  name: "rating-box",
  components: {},
  props: {
    ratingScore: {
      type: [Number, String],
      default: "",
      description: "the score of rating",
    },
    ratingCount: {
      type: Number,
      description: "the count of rating",
    },
  }
}
</script>

<style scoped>

</style>