<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ $t(title) }}
          </h5>
        </div>
        <div v-if="userRole === 'SYSTEM_USER'" class="col">
          <router-link class="btn btn-sm btn-primary border-0 float-end" :to="'/clients/'+$route.params.id+'/users/new'">
            {{ $t("Create") }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush text-start"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
        :fetching="fetching"
      >
        <template #columns>
          <th>{{ $t("Name") }}</th>
          <th>{{ $t("Status") }}</th>
<!--          <th>Contact Phone</th>-->
          <th>{{ $t("Date Created") }}</th>
          <th></th>
        </template>

        <template #default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <router-link
                    :to="userRole === 'CLIENT' ? '/account/users/'+row.item.id+'/edit' : '/clients/'+$route.params.id+'/users/'+row.item.id+'/edit'">
                  <span class="name mb-0 text-sm fw-bolder">
                  {{ row.item.firstName }} {{ row.item.lastName }}
                  </span>
                </router-link>
              </div>
            </div>
          </th>
          <td>
            <div class="badge p-1" :class="row.item.status !== 'ACTIVE' ? 'bg-gradient-green' : 'bg-gradient-orange'">
              <span class="status text-sm ">{{ $t(row.item.status) }}</span>
            </div>
          </td>
<!--          <td>-->
<!--            <a :href="'tel:' + row.item.phoneNumber"><div class="name mb-0 text-md-left">-->
<!--                {{ row.item.phoneNumber }}-->
<!--              </div></a>-->
<!--          </td>-->
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-sm text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-end">
            <base-button class="me-2 "  size="sm" type="primary" @click="sendPasswordResetEmail(row.item.email)">{{ $t("Send password reset email") }}</base-button>

            <router-link
                class="btn bg-primary btn-sm me-2 text-white fw-bold"
                :to="userRole === 'CLIENT' ? '/account/users/'+row.item.id+'/edit' : '/clients/'+$route.params.id+'/users/'+row.item.id+'/edit'">
              {{ $t("Edit") }}
            </router-link>

            <base-button v-if="tableData.length > 1" :disabled="loadingProcess !== ''" type="danger" class="me-2 " size="sm" @click="deleteUser(row.item.id)">{{ $t("Delete") }}</base-button>
          </td>
        </template>

        <template #footer>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton.vue"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"

export default {
  name: "client-users-table",
  components: {BaseButton},
  props: {
    type: {
      type: String,
    },
    refresh: {
      type: Boolean,
      default: false
    },
    clientId: String,
    title: String,
  },
  data() {
    return {
      loadingProcess: "",
      fetching: false,
      tableData: [],
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  watch: {
    refresh(newVal) {
      if (newVal === "clientUsers"){
        this.getUsers()
      }
    },
    clientId(newVal) {
      if(this.tableData.length === 0 && newVal != null) {
        this.getUsers()
      }
    },
    "$route.name"(newVal){
        if (["clientUsers", "clientAccountUsers"].includes(newVal) ){
          this.getUsers()
        }
    }
  },
  async mounted() {
    await this.getUsers()
  },
  methods: {
    async getUsers(refresh = true) {
      if (refresh){
        this.tableData = []
      }
      // let lastId = null
      // if (this.tableData.length > 0) {
      //   lastId = this.tableData[this.tableData.length - 1].id
      // }
      await this.$store.dispatch("fetchDocs", {
        "path": "clients/"+this.clientId+"/users"
      }).then((data) => {
        this.tableData = this.tableData.concat(data)
        this.fetching = false
      })
    },
    deleteUser(id){
      this.$swal.fire({
        title: "Are you sure?",
        text: "Delete user.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "green",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loadingProcess = "processing"
          FirebaseFunctionsHelper.callFunction("client-removeUser", {
            userId: id,
            clientId: this.clientId,
          }).then((data) => {
            console.log("client-removeUser", data)
            if (data === null) {
              this.loadingProcess = ""
              this.$swal.fire({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                icon: "success",
                title: this.$t("User deleted!"),
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer)
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer)
                }
              })
              this.getUsers()
            } else {
              this.loadingProcess = ""
              this.$swal.fire({
                icon: "error",
                html: "Try again later",
                title: "There was an error!",
              })
            }
          }).catch((error) => {
            this.loadingProcess = ""
            this.$swal.fire({
              icon: "error",
              html: error.message,
              title: "There was an error!",
            })
            console.error(error)
          })
        }
      })
    },
    sendPasswordResetEmail(email){
      this.$swal.fire({
        title: "Are you sure?",
        text: "Send an email to reset users password.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "green",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$swal.showLoading()
          try {
            await this.$store.dispatch("sendPasswordResetEmail", email)
            this.$swal.fire({
              title: "Email sent!",
              html: "A reset password email was sent successfully!",
              icon:"success",
              timer: 1000,
              timerProgressBar: true
            })
          } catch (e) {
            console.log("error", e)
            this.$swal.fire({
              icon: "error",
              html: e.message + " <br> <b>Please contact support.</b>",
              title: "Something went wrong!",
            })
          }


        }
      })
    },
  },
}
</script>
<style></style>
