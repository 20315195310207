import moment from "moment-timezone"

const timeZone = "Asia/Nicosia"
const today = moment()
today.tz(timeZone)

function getOrthodoxEaster(year = today.year()) {

    const a = year % 19
    const b = year % 7
    const c = year % 4

    const d = (19 * a + 16) % 30
    const e = (2 * c + 4 * b + 6 * d) % 7
    const f = (19 * a + 16) % 30

    const key = f + e + 3
    const month = (key > 30) ? 5 : 4
    const date = (key > 30) ? key - 30 : key

    return convertToCypriotDate(year, month, date)
}


function convertToCypriotDate(year, month, date) {
    const _month = month.toString().padStart(2, "0")
    const _date = date.toString().padStart(2, "0")

    return moment.tz(`${year}-${_month}-${_date}`, timeZone).toDate()
}

// function getCatholicEaster(year: number = new Date().getFullYear()) {
//
//     let month = 2
//
//     const a = year % 19 + 1
//     const b = year / 100 + 1
//     const c = (3 * b) / 4 - 12
//     const d = (8 * b + 5) / 25 - 5
//     const e = (5 * year) / 4 - c - 10
//
//     let f = (11 * a + 20 + d - c) % 30
//     if (f == 24) {
//         f++
//     }
//     if ((f == 25) && (a > 11)) {
//         f++
//     }
//
//     let g = 44 - f
//     if (g < 21) {
//         g = g + 30
//     }
//
//     let day = (g + 7) - ((e + g) % 7)
//     if (day > 31) {
//         day = day - 31
//         month = 3
//     }
//     return new Date(year, month, day)
// }

function addDays(date, days) {
    date.setDate(date.getDate() + days)
    return date
}

export const isCyprusPublicHoliday = (date) => {
    const publicHolidays = getCyprusPublicHolidays(date.getFullYear())
    const d = moment.tz(date, timeZone)
    return publicHolidays.some((item) => {
        return d.isSame(item.date, "date")

    })
}

export const getCyprusPublicHolidays = (year = today.year()) => {
    const easter = getOrthodoxEaster(year)
    const cleanMonday = addDays(new Date(easter), -48)
    const goodFriday = addDays(new Date(easter), -2)
    const holySaturday = addDays(new Date(easter), -1)
    const easterMonday = addDays(new Date(easter), 1)
    const pentecostMonday = addDays(new Date(easter), 50)


    const holidays = [
        {
            description: "New Year's Day",
            date: convertToCypriotDate(year, 1, 1)
        },
        {
            description: "Epiphany",
            date: convertToCypriotDate(year, 1, 6)
        },
        {
            description: "Clean Monday",
            date: cleanMonday
        },
        {
            description: "Greek Independence Day",
            date: convertToCypriotDate(year, 3, 25)
        },
        {
            description: "Cyprus National Day",
            date: convertToCypriotDate(year, 4, 1)
        },
        {
            description: "Good Friday",
            date: goodFriday
        },
        {
            description: "Holy Saturday",
            date: holySaturday
        },
        {
            description: "Easter Sunday",

            date: easter
}, {
        description: "Easter Monday",
            date: easterMonday
    }, {
        description: "Labour Day",
            date: convertToCypriotDate(year, 5, 1)
    },
    {
        description: "Pentecost Monday",
            date: pentecostMonday
    }, {
        description: "Dormition of the Theotokos",
            date: convertToCypriotDate(year, 8, 15)
    }, {
        description: "Cyprus Independence Day",
            date: convertToCypriotDate(year, 10, 1)
    }, {
        description: "Greek National Day",
            date: convertToCypriotDate(year, 10, 28)
    }, {
        description: "Christmas Eve",
            date: convertToCypriotDate(year, 12, 24)
    },
    {
        description: "Christmas Day",
            date: convertToCypriotDate(year, 12, 25)
    },
    {
        description: "Boxing Day",
            date: convertToCypriotDate(year, 12, 26)
    },
]

    return holidays

}