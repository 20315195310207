import ChartJS from "chart.js/auto"
export const generalCountChart = {
  createChart(chartId, labels, data) {
    const chartColor = "#FFFFFF"
    const fallBackColor = "#f96332"
    const color = this.color || fallBackColor
    const ctx = document.getElementById(chartId).getContext("2d")
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0)
    gradientStroke.addColorStop(0, color)
    gradientStroke.addColorStop(1, chartColor)

    new ChartJS(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: data,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        // scales: {
        //   yAxes: [
        //     {
        //       gridLines: {
        //         borderDash: [2],
        //         borderDashOffset: [2],
        //         drawBorder: false,
        //         drawTicks: false,
        //         lineWidth: 1,
        //         zeroLineWidth: 0,
        //         zeroLineBorderDash: [2],
        //         zeroLineBorderDashOffset: [2],
        //       },
        //       ticks: {
        //         beginAtZero: true,
        //         padding: 10,
        //         fontSize: 13,
        //         fontColor: "#8898aa",
        //         fontFamily: "Open Sans, sans-serif",
        //         callback: function (value) {
        //           if (!(value % 10)) {
        //             return value
        //           }
        //         },
        //       },
        //     },
        //   ],
        //   xAxes: [
        //     {
        //       gridLines: {
        //         drawBorder: false,
        //         drawOnChartArea: false,
        //         drawTicks: true,
        //       },
        //       ticks: {
        //         padding: 10,
        //         fontSize: 12,
        //         fontColor: "#8898aa",
        //         fontFamily: "Open Sans, sans-serif",
        //       },
        //     },
        //   ],
        // },
      },
    })
  },
}

const funcs = {
  appointmentsCountChart() {},
}

export default funcs
