<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-primary"
        style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->
      <div class="">
        <div class="row">
          <div class="col-12 text-start">
            <h3 class="text-white float-start pe-3 text-uppercase">
              {{ requestData.position.name }} </h3>
<!--            <div class="badge p-1 font-size-xs float-start" :class="getStatusClass(requestData.status)">-->
<!--              <span class="status">{{ requestData.status }}</span>-->
<!--            </div>-->
            <br>
          </div>
<!--          <div class="col-12 text-start">-->
<!--            <h3 class="text-white float-start pe-3">-->
<!--              <span v-if="requestData.hasOwnProperty('mStartDate')">{{ requestData.mStartDate.format("dddd, DD MMM YYYY") }}</span>-->
<!--               <br><span v-if="requestData.hasOwnProperty('mEndDate')">{{ requestData.mStartDate.format("HH:mm") }} - {{ requestData.mEndDate.format("HH:mm") }}</span>-->
<!--            </h3>-->
<!--            </div>-->
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->

        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
<!--      <div class="text-start p-2 ps-0">-->
<!--        <div class="btn-group me-2" role="group" aria-label="First group">-->
<!--          <router-link :to="'/requests/'+$route.params.id+'/info'" class="btn btn-secondary mt-1 mb-1">-->
<!--            <span class="nav-link-text">{{ $t("Info") }}</span>-->
<!--          </router-link>-->
<!--          <router-link :to="'/requests/'+$route.params.id+'/documents'" class="btn btn-secondary mt-1 mb-1">-->
<!--            <span class="nav-link-text">{{ $t("Documents") }}</span>-->
<!--          </router-link>-->
<!--          <router-link :to="'/requests/'+$route.params.id+'/paymentDetails'" class="btn btn-secondary mt-1 mb-1">-->
<!--            <span class="nav-link-text">{{ $t("Payment Details") }}</span>-->
<!--          </router-link>-->
<!--          <router-link :to="'/requests/'+$route.params.id+'/requests'" class="btn btn-secondary mt-1 mb-1">-->
<!--            <span class="nav-link-text">{{ $t("Requests") }}</span>-->
<!--          </router-link>-->
<!--        </div>-->
<!--      </div>-->
      <request-details-card :request-data="requestData" @get-request="getRequest"></request-details-card>
      <div class="row">
      <div class=" col-md-6">
        <card shadow type="secondary" class="mt-3">
        <template #header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-8 text-start">
                <h5 class="mb-0">{{ $t("Price overview") }}</h5>
              </div>

            </div>
          </div>
        </template>
        <request-pricing-card v-if="requestData.hasOwnProperty('cost')" :cost="requestData.cost"></request-pricing-card>
      </card>
      </div>
        <div class=" col-md-6">
      <card shadow type="secondary" class="mt-3">
        <template #header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-8 text-start">
                <h5 class=" mb-0">{{ $t("Address") }}</h5>
              </div>
              <div class="col-4 text-end">
                <a class="h5" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query='+addressText">
                  <i class="fa fa-map-location"></i>
                </a>
                </div>
            </div>
          </div>
        </template>
        <client-address :client-data="clientData"></client-address>
      </card>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {getStatusClass} from "@/helpers/common"
import RequestDetailsCard from "@/views/Requests/RequestDetailsCard"
import ClientAddress from "@/views/Cards/ClientAddressCard"
import RequestPricingCard from "@/views/Cards/RequestPricingCard"

export default {
  name: "NormalUserRequestView",
  components: {RequestPricingCard, ClientAddress, RequestDetailsCard},
  data() {
    return {
      nav: null,
      clientData: {},
      requestData: {
        mStartTime: null,
        position: { name: ""}},
      address: {}
    }
  },
  computed: {
    addressText(){
      if (this.clientData.hasOwnProperty("address")) {
        let address = this.clientData.address ?? {}
        let addressText = address.street ?? ""
        addressText += "+" + address.city ?? ""
        addressText += "+" + address.postalCode ?? ""
        return addressText
      }

      return "Empty"
    }
  },
  async mounted() {
    // var self = this;
    await this.getRequest()
    await this.getClient()
    // this.getEmployeePrivateInfo()
  },
  methods: {
    getStatusClass,
    async getRequest() {
      let self = this
      await this.$store
          .dispatch("fetchRequest", this.$route.params.id)
          .then((data) => {
            console.log("data", data)
            self.requestData = data
          })
    },
    async getClient(){
      await this.$store
          .dispatch("fetchClient", this.requestData.clientId)
          .then((data) => {
            console.log("fetchClient", data)
            this.clientData = data
          })
    },
    async getEmployeePrivateInfo(){
      let res = await this.$store.dispatch("fetchEmployeePrivateInfo", this.$route.params.id)
      console.log("res", res )
      this.address = res.address
      // this.employeeData.email = res.email
      // this.employeeData.phoneNumber = res.phoneNumber
    },
  },
}
</script>
<style></style>
