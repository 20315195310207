<template>
  <div class="pl-lg-4 text-start">
    <div class="row">
      <div class="col-md-6 pt-2">
        <display-label
            label="Street"
            :value="clientData.hasOwnProperty('address') ? clientData.address.street : ''"
        ></display-label>
      </div>
      <div class="col-md-6 pt-2">
        <display-label
            label="Street 2"
            :value="clientData.hasOwnProperty('address') ? clientData.address.street2 : ''"
        ></display-label>
      </div>
      <div class="col-md-4 pt-2">
        <display-label
            label="City"
            :value="clientData.hasOwnProperty('address') ? $t(clientData.address.city) : ''"
        ></display-label>
      </div>
      <div class="col-md-4 pt-2">
        <display-label
            label="Country"
            :value="clientData.hasOwnProperty('address') ? $t(clientData.address.countryCode) : ''"
        ></display-label>
      </div>
      <div class="col-md-4 pt-2">
        <display-label
            label="Postal code"
            :value="clientData.hasOwnProperty('address') ? clientData.address.postalCode : ''"
        ></display-label>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayLabel from "@/components/DisplayLabel"
export default {
  name: "ClientAddress",
  components: {DisplayLabel},
  props: {
    clientData: {
      type: Object,
      description: "Client data",
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>