<template>
  <div>
    <base-header type="gradient-primary" class="pb-8 pb-5 pt-3 pt-md-5">
      <div class="row">
        <div class="col-xl-3 col-sm-6">
          <stats-card
            :title="$t('Open Requests')"
            type="gradient-orange"
            :sub-title="companyStatistics.requests"
            icon="fa fa-pie-chart"
            class="mb-4 mb-xl-0"
          >
            <template #footer>
              <span class="text-success me-2"> </span>
              <!--              <span class="text-nowrap">Since last month</span>-->
            </template>
</stats-card>
        </div>
        <div class="col-xl-3 col-sm-6">
          <stats-card
            :title="$t('Total Partners')"
            type="gradient-red"
            :sub-title="companyStatistics.employeeCount"
            icon="fa fa-users"
            class="mb-4 mb-xl-0"
          >
            <template #footer>
              <span class="text-success me-2"> </span>
              <!--              <span class="text-nowrap">Since last month</span>-->
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-sm-6">
          <stats-card
              :title="$t('Total Clients')"
              type="gradient-red"
              :sub-title="companyStatistics.clientCount"
              icon="fa fa-users"
              class="mb-4 mb-xl-0"
          >
            <template #footer>
              <span class="text-success me-2"> </span>
              <!--              <span class="text-nowrap">Since last month</span>-->
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-sm-6">
          <stats-card
            :title="graphMonthsLabels.at(-2)"
            type="gradient-green"
            :sub-title="(lastMonthRevenue/100)+'€'"
            icon="fa fa-coins"
            class="mb-4 mb-xl-0"
          >
            <template #footer>
              <span
                :class="
                  lastMonthRevenuePercentage >= 0
                    ? 'text-success me-2'
                    : 'text-danger me-2'
                "
              >
                <i
                  :class="
                    lastMonthRevenuePercentage >= 0
                      ? 'fa fa-arrow-up'
                      : 'fa fa-arrow-down'
                  "
                ></i>
                {{ lastMonthRevenuePercentage.toFixed(2) }}%
              </span>
              <span class="text-nowrap">Since last Month</span>
            </template>
          </stats-card>
        </div>
<!--        <div class="col-xl-3 col-lg-6">-->
<!--          <stats-card-->
<!--            title="Rating"-->
<!--            type="gradient-primary"-->
<!--            sub-title=""-->
<!--            sub-title-icon="fa fa-star"-->
<!--            :sub-title-icon-count="5"-->
<!--            sub-title-icon-color="gold"-->
<!--            icon="ni ni-chart-bar-32"-->
<!--            class="mb-4 mb-xl-0"-->
<!--          >-->
<!--            <template #footer>-->
<!--              <span class="text-success me-2"> COMING SOON </span>-->
<!--            </template>-->
<!--          </stats-card>-->
<!--        </div>-->
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--Charts-->
      <div class="row">
        <div class="col-xl-6 mb-5 mb-xl-0">
          <div class="position-absolute">
            <i class="fas fa-cog fa-spin"></i>
          </div>
          <card type="secondary" header-classes="bg-transparent">
            <template #header>
              <div class="row align-items-center">
                <div class="col">
                  <div class="row d-none d-sm-flex">
                    <div class="col-6">
                      <div class="text-muted text-start text-uppercase ls-1 mb-1 text-sm">
                        Revenue
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="text-muted text-uppercase ls-1 mb-1 text-end text-sm">
                        Last update: {{ formatExpiryDate }}
                      </div>
                    </div>
                  </div>

                  <h5 class="h4 mb-0">Request based revenue</h5>
                  <label class="text-sm">
                    Monthly Revenue is calculated based on the status of requests of each month.
                  </label>
                </div>
                <!--                <div class="col">-->
                <!--                  <ul class="nav nav-pills justify-content-end">-->
                <!--                    <li class="nav-item mr-2 mr-md-0">-->
                <!--                      <a-->
                <!--                        class="nav-link py-2 px-3"-->
                <!--                        href="#"-->
                <!--                        :class="{ active: bigLineChart.activeIndex === 0 }"-->
                <!--                        @click.prevent="initBigChart(0)"-->
                <!--                      >-->
                <!--                        <span class="d-none d-md-block">Month</span>-->
                <!--                        <span class="d-md-none">M</span>-->
                <!--                      </a>-->
                <!--                    </li>-->
                <!--                    <li class="nav-item">-->
                <!--                      <a-->
                <!--                        class="nav-link py-2 px-3"-->
                <!--                        href="#"-->
                <!--                        :class="{ active: bigLineChart.activeIndex === 1 }"-->
                <!--                        @click.prevent="initBigChart(1)"-->
                <!--                      >-->
                <!--                        <span class="d-none d-md-block">Week</span>-->
                <!--                        <span class="d-md-none">W</span>-->
                <!--                      </a>-->
                <!--                    </li>-->
                <!--                  </ul>-->
                <!--                </div>-->
              </div>
            </template>
            <div class="chart-area">
              <canvas :id="salesChartID" :height="350"></canvas>
            </div>
          </card>
        </div>

        <div class="col-xl-6">
          <card header-classes="bg-transparent">
            <template #header>
              <div class="row align-items-center">
                <div class="col">
                  <div class="row d-none d-sm-flex">
                    <div class="col-6">
                      <h6 class="text-muted text-uppercase text-muted text-start mb-1 text-sm">
                        Performance
                      </h6>
                    </div>
                    <div class="col-6">
                      <h6 class="text-muted text-uppercase mb-1 text-end text-sm">
                        Last update: {{ formatExpiryDate }}
                      </h6>
                    </div>
                  </div>
                  <h5 class="h4 mb-0">Total Requests</h5>
                </div>
              </div>
            </template>
            <div class="chart-area">
              <canvas :id="generalCountChartId" :height="385"></canvas>
            </div>
          </card>
        </div>
      </div>
      <!-- End charts-->
    </div>
  </div>
</template>
<script>
// Charts
import { generalCountChart } from "@/components/Charts/Chart"
import ChartJS from "chart.js/auto"
import moment from "moment"
import StatsCard from "@/components/StatsCard"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
let chart

export default {
  components: {
    StatsCard
    // PageVisitsTable,
    // SocialTrafficTable,
  },
  data() {
    return {
      requestMonthlyStatistics: [],
      graphMonths: [],
      lastMonthRevenue: 0,
      lastMonthRevenuePercentage: 0,
      graphMonthsLabels: [],
      statuses:{
        "VERIFIED": {
          "title": "VERIFIED",
          "color": "#2e9f00",
          "tension": 0.3,
        },
        "CANCELED": {
          "title": "CANCELED",
          "color": "#FF0000",
          "tension": 0.2,
        },
        "FINISHED": {
          "title": "FINISHED",
          "color": "#6e6e6e",
          "tension": 0.4,
        },
        "CLAIMED": {
          "title": "CLAIMED",
          "color": "#046380",
          "tension": 0.3,
        },
        "ABANDONED": {
          "title": "ABANDONED",
          "color": "#c7c31e",
          "tension": 0.3,
        }

      },
      companyStatistics: {
        clientCount: null,
        employeeCount: null,
        requests: null,
      },
      salesChartID: "salesChart",
      generalCountChartId: "generalCountChartId",
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 35, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
      requestStatisticsExpDate: new Date(),
    }
  },
  computed: {
    formatExpiryDate() {
      return moment(this.requestStatisticsExpDate ?? new Date())
        .format("DD MMM YYYY HH:mm")
    },
  },
  mounted() {
    // ChartJS.defaults.color = '#dedbdb';
    this.getStatistics()
    // this.createBigChart();
    this.getRevenueStatistics()
  },
  methods: {
    calculateCountData() {
      let self = this
      let data = []

      for (let status in self.requestMonthlyStatistics) {
        const dataByStatus = []
        this.graphMonths.forEach((key) => {
          // console.log("key", key)
          // console.log("statusList", status)
          let theMonth = self.requestMonthlyStatistics[status].find(m => key === moment(m.date, "Y-MM").format("YYYYMM"))
          if (theMonth) {
            dataByStatus.push(theMonth.shiftCount ?? 0)
          } else {
            dataByStatus.push(0)
          }
        })

        console.log("status", status)
        data.push({
          label: this.$t(status),
          tension: this.statuses[status].tension,
          borderWidth: 0,
          pointRadius: 0,
          backgroundColor: this.statuses[status].color,
          data: dataByStatus,
          maxBarThickness: 30,
        })
      }
      return data
    },
    calculateData(months) {
      let self = this
      let data = []

      for (let status in self.requestMonthlyStatistics) {
        const dataByStatus = []
        months.forEach((key) => {
          let theMonth = self.requestMonthlyStatistics[status].find(m => key === moment(m.date, "Y-MM").format("YYYYMM"))
          if (theMonth) {
            dataByStatus.push(theMonth.revenue / 100 ?? 0)
          } else {
            dataByStatus.push(0)
          }
        })
        console.log("statussssss", status)
          data.push({
            label: this.$t(status),
            tension: this.statuses[status].tension,
            borderWidth: 4,
            borderColor: this.statuses[status].color,
            pointRadius: 3,
            backgroundColor: this.statuses[status].color,
            data: dataByStatus,
          },)

      }
      return data
    },
    createBigChart() {
      // console.log("graphMonths", this.graphMonths)
      let data = this.calculateData(this.graphMonths)
      // console.log("createBigChart data", data)
      // eslint-disable-next-line no-unused-vars
      chart = new ChartJS(
        document.getElementById(this.salesChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: this.graphMonthsLabels,
            datasets: data,
          },
          options: {
            color: "#000000",
            responsive: true,
            maintainAspectRatio: false,
            // legend: {
            //   display: false,
            // },
            tooltips: {
              enabled: true,
              mode: "index",
              interaction: {
                mode: "index",
              },
              intersect: true,
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: "Month",
                  color: "#333333",
                },
                ticks: {
                  color: "#333333",
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: "Amount",
                  color: "#333333",
                },
                ticks: {
                  color: "#333333",
                }
              }
            }
            // scales: {
            //   yAxes: [
            //     {
            //       barPercentage: 1.6,
            //       gridLines: {
            //         drawBorder: false,
            //         color: "rgba(29,140,248,0.1)",
            //         zeroLineColor: "red",
            //       },
            //       ticks: {
            //         padding: 0,
            //         fontColor: "#8898aa",
            //         fontSize: 13,
            //         fontFamily: "Open Sans, sans-serif",
            //       },
            //     },
            //   ],
            //   xAxes: [
            //     {
            //       barPercentage: 1.6,
            //       gridLines: {
            //         drawBorder: false,
            //         color: "rgba(29,140,248,0.0)",
            //         zeroLineColor: "transparent",
            //       },
            //       ticks: {
            //         padding: 10,
            //         fontColor: "#8898aa",
            //         fontSize: 13,
            //         fontFamily: "Open Sans, sans-serif",
            //       },
            //     },
            //   ],
            // },
            // layout: {
            //   padding: 0,
            // },
          },
        }
      )
    },
    async getStatistics() {
      this.companyStatistics.requests =  await this.$store.dispatch("countCollectionGroupDocs", {path: "requests", statuses: ["VERIFIED"], sorting: {sortBy: "createdAt", sortDirection: "desc"}}).then((data) => {
        // console.log("requests", data)
        return data
      })

      this.companyStatistics.clientCount =  await this.$store.dispatch("countDocs", {path: "clients"}).then((data) => {
        // console.log("clientCount", data)
        return data
      })

      this.companyStatistics.employeeCount =  await this.$store.dispatch("countDocs", {path: "employees"}).then((data) => {
        // console.log("employeeCount", data)
        return data
      })
    },
    getMonths(numb) {
      let labels = []
      let months = []
      for (let step = numb - 1; step >= -1; ) {
        let month = moment().subtract(step, "months").format("YYYYMM")
        let monthLabel = moment().subtract(step, "months").format("MMMM")
        step--
        months.push(month)
        labels.push(monthLabel)
        // console.log("monthssssssssssss", months)
      }
      return { months: months, labels: labels }
    },
    async getBigQueryData() {
      let self = this
      console.log("this.$store.state.requestStatistics", this.$store.state.requestStatistics)
      const requestStatistics = await self.$store.dispatch("getCookie", "requestStatistics")

      if (requestStatistics != null && Object.keys(requestStatistics).length > 0) {
        console.log("from cache", requestStatistics)
        self.requestStatisticsExpDate = requestStatistics.createdAt
        return requestStatistics.value
      }
      console.log("from bigquery")
      return await FirebaseFunctionsHelper.callFunction("request-getMonthlyStatistics", {})
          .then((data) => {
            // console.log("dataaaaa", data)
            let groupedData = {}
            data.forEach((month) => {
              // console.log("month", month)
              // eslint-disable-next-line no-prototype-builtins
              if(!groupedData.hasOwnProperty(month.status)){
                groupedData[month.status] = []
              }

              groupedData[month.status].push(month)
              // if (month.status === "VERIFIED") {
              //   verifiedData.push(month);
              // }
            })
            // console.log("verifiedData", groupedData)
            self.$store.dispatch("setCookie", {key: "requestStatistics", value: groupedData})
            self.requestStatisticsExpDate = new Date()
            return groupedData
          })
          .catch((error) => {
            console.log("Error", error)
          })
    },
    async getRevenueStatistics() {
      let self = this
      let results = this.getMonths(5)
      this.graphMonths = results.months
      this.graphMonthsLabels = results.labels

      self.requestMonthlyStatistics = await this.getBigQueryData()
      self.createBigChart()
      self.calcLastMonthRevenue()
      generalCountChart.createChart(
        self.generalCountChartId,
        self.graphMonthsLabels,
        self.calculateCountData()
      )
    },
    calcLastMonthRevenue() {
      let lastTowMonths = this.graphMonths.slice(
        Math.max(this.graphMonths.length - 3, 0)
      )
      lastTowMonths.pop()
      console.log("lastMonthRevenue lastTowMonths", lastTowMonths)

      if (this.requestMonthlyStatistics.length === 0){
        return
      }

      let data = []
      for (let month of lastTowMonths) {
        if (this.requestMonthlyStatistics){
          let theMonth = this.requestMonthlyStatistics["FINISHED"].find(m => month === moment(m.date, "Y-MM").format("YYYYMM"))
          if (theMonth) {
            data.push(theMonth.revenue)
          } else {
            data.push(0)
          }
        }
      }
      console.log("lastMonthRevenue data", data)

      this.lastMonthRevenue = data[1]
      this.lastMonthRevenuePercentage = ((data[1] - data[0]) / ((data[1] + data[0]) / 2)) * 100
    },
  },
}
</script>
<style></style>
