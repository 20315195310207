<template>
  <div class="row mb-5">

    <div class="col-xl-12 order-xl-1">
      <config v-if="!loading" v-model="model" :for-client="true" name="Client configuration" @submit="submitConfig">
        <template #buttons>
          <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submitConfig">{{ $t('Submit') }}</submit-button>
        </template>
      </config>
    </div>
  </div>
</template>
<script>
import Config from "@/components/Config.vue"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import SubmitButton from "@/components/SubmitButton.vue"
import {required} from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export default {
  name: "client-config",
  components: {SubmitButton, Config},
emits: ["fetchClient"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      model: {
        companyCommissionRatePercent: null,
        employeeRatePercent: null,
        nightShiftStartHour: null,
        nightShiftEndHour: null,
        dueInvoiceDays: null,
        mandatoryRestHours: null
      },
    }
  },
  validations () {
    return {
      model: {
        companyCommissionRatePercent: {
          required
        },
        employeeRatePercent: {
          required
        },
        nightShiftStartHour: {
          required
        },
        nightShiftEndHour: {
          required
        },
      }
    }
  },
  async mounted() {
    await this.getSettings()
  },
  methods:{
    async getSettings() {
      let clientSettings = await this.$store.dispatch("fetchClient", this.$route.params.id)
      if (!clientSettings.hasOwnProperty("configs") || clientSettings.configs == null) {
        this.model = await this.$store.dispatch("fetchSettings")
        delete this.model.dueInvoiceDays
        delete this.model.mandatoryRestHours
      } else {
        console.log("clientSettings", clientSettings)
        this.model = clientSettings.configs
      }
      this.loading = false
    },
    async submitConfig() {
      let self = this

      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }


      const payload = {
        clientId: this.$route.params.id,
        configs: {
          companyCommissionRatePercent: parseInt(this.model.companyCommissionRatePercent),
          employeeRatePercent: this.model.employeeRatePercent,
          nightShiftStartHour: this.model.nightShiftStartHour.id,
          nightShiftEndHour: this.model.nightShiftEndHour.id,
        }
      }
      console.log("payload", payload)

      this.loadingProcess = "processing"
      FirebaseFunctionsHelper.callFunction(
          "client-update",
          payload
      )
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              self.loadingProcess = "failed"
              self.errorMessage = result.message
              return
            }
            console.log("submitUser result", result)
            this.loadingProcess = "done"

            this.$emit("fetchClient")

            setTimeout(function () {
              self.loadingProcess = ""
            }, 1000)
          })
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
  }
}
</script>
<style scoped>

</style>