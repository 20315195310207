import {getCyprusPublicHolidays} from "./publicHolidays"
import moment from "moment-timezone"

export const calculateShiftCost = (position, config, shiftStartTime, shiftEndTime) => {
    config = JSON.parse(JSON.stringify(config))

    const startMoment = moment.tz(shiftStartTime, config.timeZone).set({seconds: 0, milliseconds: 0})
    const endMoment = moment.tz(shiftEndTime, config.timeZone).set({seconds: 0, milliseconds: 0})
    let publicHolidays = []
    let companyCommission = 0
    let employeeEarnings = 0
    let totalCost = 0
    const nightHours = getNightHours(config.nightShiftStartHour, config.nightShiftEndHour)

    let currentMoment = startMoment.clone()

    while (currentMoment < endMoment) {
        // Calculate the next hour mark or the end of the shift, whichever comes first
        const nextHourMoment = currentMoment.clone().add(1, "hour").startOf("hour")
        const minutesThisHour = Math.min(nextHourMoment.diff(currentMoment, "minutes"), endMoment.diff(currentMoment, "minutes"))

        if (publicHolidays.length === 0 || currentMoment.year() !== currentMoment.clone().subtract(1, "hour").year()) {
            publicHolidays = getCyprusPublicHolidays(currentMoment.year())
        }

        const hourlyRate = getHourlyRate(publicHolidays, nightHours, position, currentMoment)
        const hourCost = calculatePrice(100, hourlyRate, minutesThisHour)
        totalCost += hourCost
        companyCommission += calculatePrice(config.companyCommissionRatePercent, hourlyRate, minutesThisHour)
        employeeEarnings += calculatePrice(config.employeeRatePercent, hourlyRate, minutesThisHour)

        // console.log(`Processing time from ${currentMoment.format()} for ${minutesThisHour} minutes at rate ${hourlyRate}, cost ${Math.round(hourCost)}`)

        // Move to the next hour
        currentMoment = nextHourMoment
    }

    return {
        currency: "EUR",
        companyCommission: Math.round(companyCommission),
        employeeEarnings: Math.round(employeeEarnings),
        totalCost: Math.round(totalCost)
    }
}
function calculatePrice(percentage, hourlyRate, minutes) {
    return ((hourlyRate * minutes) / 60) * (percentage / 100)
}

function getHourlyRate(holidays, nightHours, position, date) {
    const isHoliday = isPublicHoliday(holidays, date) || isSunday(date)
    const isNight = nightHours.includes(date.hour())

    if (isNight && isHoliday) {
        return position.holidayNightHourlyRate
    } else if (isHoliday) {
        return position.holidayDayHourlyRate
    } else if (isNight) {
        return position.normalNightHourlyRate
    }
    return position.normalDayHourlyRate
}

function isPublicHoliday(holidays, date) {
    return holidays.some((item) => date.isSame(item.date, "date"))
}

function isSunday(date) {
    // 0 for Sunday
    return date.day() === 0
}

function getNightHours(nightStartHour, nightEndHour) {
    const hours = []
    if (nightStartHour < nightEndHour) {
        for (let hour = nightStartHour; hour < nightEndHour; hour++) {
            hours.push(hour)
        }
    } else {
        for (let hour = nightStartHour; hour < 24; hour++) {
            hours.push(hour)
        }
        for (let hour = 0; hour < nightEndHour; hour++) {
            hours.push(hour)
        }
    }
    return hours
}
