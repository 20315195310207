<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h5>
        </div>
        <div class="col text-end">
          <base-button @click="resetData" type="primary" class="btn-sm me-2 text-white">Refresh</base-button>
          <router-link class="btn btn-primary btn-sm" to="/positions/new">{{ $t("Add New") }}</router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
          class="table align-items-center table-flush text-center"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="tableData"
          :paginated="false"
          :loading="loading"
      >
        <template #columns>
          <th class="text-start">Name</th>
          <th>Day Rate</th>
          <th>Night Rate</th>
          <th>Holiday Day Rate</th>
          <th>Holiday Night Rate</th>
          <th>Date Created</th>
          <th></th>
        </template>

        <template #default="row">
          <th scope="row">
            <div class="media align-items-center text-start">
              <div class=" name mb-0 text-sm fw-bolder text-primary">
                {{ row.item.name }}
              </div>
            </div>
          </th>

          <td class="budget">
            <span
                v-tooltip:top.tooltip=" '€'+( $store.state.settings.companyCommissionRatePercent/100 * row.item.normalDayHourlyRate/100)/100 + ' commission'"
> €{{ row.item.normalDayHourlyRate/100 }}/h </span>
          </td>
          <td class="budget">
            €{{ row.item.normalNightHourlyRate/100 }}/h
          </td>
          <td class="budget">
            €{{ row.item.holidayDayHourlyRate/100 }}/h
          </td>
          <td class="budget">
            €{{ row.item.holidayNightHourlyRate/100 }}/h
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-end">
            <router-link class="btn bg-gradient-green border-0 me-2 text-white" :to="'/positions/' + row.item.id + '/edit'">{{ $t("Edit") }}</router-link>

            <base-button
                type="success"
                class="bg-red border-0"
                size="md"
                @click="deletePosition(row.item.id)"
            >
              {{ $t("Delete") }}
            </base-button>
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import BaseButton from "@/components/BaseButton"

export default {
  name: "positions-table",
  components: {BaseButton},
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      loading: true,
      tableData: [],
    }
  },
  async mounted() {
    await this.getPositions()
  },
  methods: {
    deletePosition(id){
      this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          FirebaseFunctionsHelper.callFunction("position-remove",
              {positionId: id}
          )
              .then((result) => {
                console.log("res", result)
                // eslint-disable-next-line no-prototype-builtins
                if (result != null && result.hasOwnProperty("message")) {
                  console.log("resmessage", result.message)
                  this.loadingProcess = "failed"
                  this.errorMessage = result.message
                  this.$swal.fire({
                    icon: "error",
                    html: result.message,
                    title: "Something went wrong!",
                  })
                  return
                }

                this.$swal.fire({
                  title: this.$t("Deleted!"),
                  html: this.$t("Your file has been deleted."),
                  icon:"success",
                  timer: 1500,
                  timerProgressBar: true
                })

              }, this)
              .catch((error) => {
                console.error("submitUser error", error)
                this.$swal.fire({
                  icon: "error",
                  html: result.message,
                  title: this.$t("Something went wrong!"),
                })
              })

        }
      })
    },
    resetData() {
      this.tableData = []
      this.getPositions(false, true)
    },
    async getPositions(loadMore = false, reset = false) {
      // if (
      //   this.$store.state.system.companies.length > 0 &&
      //   this.$store.getters.isCacheExpired
      // ) {
      //   this.tableData = this.$store.state.system.companies
      //   return
      // }
      this.loading = true
      await this.$store.dispatch("fetchAllPositions").then((data) => {
        this.tableData = data
        console.log("tableDate", this.tableData)
        this.loading = false
      })
    },
  },
}
</script>
<style></style>
