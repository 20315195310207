<template>
  <employee-profile v-if="userRole === 'EMPLOYEE'"></employee-profile>
</template>

<script>
import EmployeeProfile from "@/views/Employee/EmployeeProfile"
export default {
  name: "Profile",
  components: {EmployeeProfile},
  data() {
    return {
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
}
</script>

<style scoped>

</style>