<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-primary"
        style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->

      <div class="row">
        <div class="text-start">
          <h3 class="text-white float-start pe-3">
            {{ clientData.name }}
          </h3>
          <div class="badge p-1 font-size-xs float-start" :class="getStatusClass(clientData.status)">
            <span class="status">{{ $t(clientData.status ?? "") }}</span>
          </div>
            <!--            <p class="text-white mt-0 mb-5">-->
            <!--              This is your profile page. You can see the progress you've made-->
            <!--              with your work and manage your projects or assigned tasks-->
            <!--            </p>-->
            <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->
        </div>
      </div>
</base-header>

    <div class="container-fluid mt--7">
      <div v-if="['SYSTEM_USER', 'CLIENT'].includes(userRole)" class="row text-start p-2 ps-0">
        <div v-if="clientData != null" class="col-md-4 btn-group float-start" role="group" aria-label="secondary navigation">
          <router-link :to="userRole === 'SYSTEM_USER' ? '/clients/'+clientData.id+'/info' : '/account/info'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Info") }}</span>
          </router-link>
          <router-link :to="userRole === 'SYSTEM_USER' ? '/clients/'+clientData.id+'/users' : '/account/users'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Users") }}</span>
          </router-link>
          <router-link v-if="userRole === 'SYSTEM_USER'" :to="'/clients/'+clientData.id+'/requests'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Requests") }}</span>
          </router-link>
          <router-link v-if="userRole === 'SYSTEM_USER' && (hasConfig || $route.name === 'clientConfig')" :to="'/clients/'+clientData.id+'/config'" class="btn btn-secondary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("Configuration") }}</span>
          </router-link>
        </div>
        <div class="col-md-3">
        <router-link
            v-if="userRole === 'SYSTEM_USER' && !hasConfig && $route.name !== 'clientConfig'"
            class="btn btn-secondary mt-1 mb-1"
            :to="'/clients/'+clientData.id+'/config'"
        >
          Add custom config
        </router-link>
        </div>
      </div>
      <router-view :client-data="clientData" @fetch-client="getClient"></router-view>
    </div>
  </div>
</template>
<script>
import ClientDetailsCard from "@/views/Cards/ClientDetailsCard"
import ClientOwnerCard from "@/views/Cards/ClientOwnerCard"
import {getStatusClass} from "@/helpers/common"
import moment from "moment"
export default {
  name: "Client",
  data() {
    return {
      nav: null,
      clientData: {},
    }
  },
  computed: {
    hasConfig(){
      return this.clientData.hasOwnProperty("configs") && this.clientData.configs !== null
    },
    moment() {
      return moment
    },
    userRole(){
      return this.$store.state.user.claims.role ?? null
    }
  },
  mounted() {
    // var self = this;
    this.getClient()
  },
  methods: {
    getStatusClass,
    async getClient() {
      let self = this
      console.log("getting client from Client1 ", this.$route)
      let clientId

      debugger
      if ( ["SYSTEM_USER", "EMPLOYEE"].includes(this.userRole)){
        clientId = this.$route.params.id
      } else if (this.userRole === "CLIENT"){
        clientId = this.$store.state.user.claims.clientId
      }

      if (this.$route.name === "editClientUser"){
        let urlPaths = this.$route.href.split("/")
        clientId = urlPaths[2]
      }
      console.log("clientId", clientId)
      await this.$store
          .dispatch("fetchClient", clientId)
          .then((data) => {
            console.log("data", data)
            self.clientData = data
          })
    },
  },
}
</script>
<style></style>
