<template>
<div>
  <client-users-table v-if="clientData != null" title="Users" :client-id="clientData.id"></client-users-table>
  <router-view :client-id="clientData != null ? clientData.id : null"></router-view>
</div>
</template>

<script>
import ClientUsersTable from "@/views/Tables/ClientUsersTable"
export default {
  name: "Users",
  components: {ClientUsersTable},
  props:{
    clientData: {
      type: Object
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>