<template>
  <div>
  <card shadow type="secondary">
    <template #header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8 text-start">
            <h5 class="mb-0">{{ $t("Filter by") }}</h5>
          </div>
          <!--              <div class="col-4 text-end">-->
          <!--                <base-button tag="a" :href="'/clients/'+$route.params.id+'/edit'" type="primary" class="btn btn-sm btn-primary">-->
          <!--                  Edit-->
          <!--                </base-button>-->
          <!--              </div>-->
        </div>
      </div>
    </template>

    <div class="row text-start">
      <div class="col-md-4">
        <div class="form-group has-label">
          <label class="form-label">{{ $t("Status") }}</label>
          <multiselect
              v-model="model.statuses"
              :show-labels="false"
              label="label"
              track-by="key"
              :placeholder="$t('Select status')"
              :options="statuses" :multiple="true">
          </multiselect>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group has-label">
          <label class="form-label">{{ $t("Job positions") }}</label>
          <multiselect
              v-model="model.positions"
              :show-labels="false"
              label="label"
              track-by="key"
              :close-on-select="false"
              :placeholder="$t('Select positions')"
              :options="positions" :multiple="true"

          ></multiselect>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group has-label">
          <label class="form-label">{{ $t("Shift date range") }}</label>
          <VueDatePicker
              v-model="model.dates"
              format="dd/MM/yyyy"
              :flow="['calendar']"
              :placeholder="$t('Select dates')"
              range
              auto-apply
          ></VueDatePicker>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="col float-end">
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="getRequests(null, true)">
          {{ $t("Search") }}
        </submit-button>
      </div>
    </template>

  </card>
  <br>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-6">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h5>
        </div>
        <div class="col-6 text-end top-0">
          <router-link v-if="$store.state.user.data.status === 'VERIFIED'" :to="'/requests/multiCreate'" class="btn btn-sm btn-primary mt-1 mb-1 me-2">
            <span class="nav-link-text">{{ $t("Create multiple requests") }}</span>
          </router-link>
          <router-link v-if="$store.state.user.data.status === 'VERIFIED'" :to="'/requests/new'" class="btn btn-sm btn-primary mt-1 mb-1">
            <span class="nav-link-text">{{ $t("New Request") }}</span>
          </router-link>
        </div>
<!--        <div class="col text-end">-->
<!--          <base-button tag="a" href="/requests/new" type="primary" size="sm">Add New</base-button>-->
<!--        </div>-->
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush text-start"
        :columns="columns"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
        :loading="loading"
        :list-full="listFull"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :paginated="true"
        @sort="getRequests"
        @fetch-more="getRequests(null, false)"
      >
        <template #default="row">
          <td>
            <router-link
                :to="'/requests/' + row.item.id"
            >
              <span class="name mb-0 text-sm">{{ row.item.refNumber ?? "No Ref" }}</span>
            </router-link>

          </td>
<!--          <td v-if="!hasUser" class="budget">-->
<!--            <span class="name mb-0 text-sm">{{ row.item.clientName }}</span>-->
<!--          </td>-->
          <td class="fw-bold">
            {{ row.item.position.name }}
          </td>
          <td>
            <div class="status-block">
              <div class="badge m-1 p-1 font-size-sm d-block" :class="getStatusClass(row.item.status)">
                <span class="status">{{ $t(row.item.status) }}</span>
              </div>

              <div v-if="hasReview(row.item.clientReviewId ?? null)" class="badge m-1 p-1 font-size-sm d-block bg-primary">
                <span class="status">{{ $t("Reviewed") }}</span>
              </div>
              <div v-else-if="pendingReview(row.item.mEndDate ?? null,row.item.clientReviewId ?? null, row.item.status ?? null)" class="badge m-1 p-1 font-size-sm d-block bg-warning">
                <span class="status">{{ $t("Pending Review") }}</span>
              </div>
            </div>
<!--            <div class="badge p-1 font-size-sm" :class="getStatusClass(row.item.status)">-->
<!--              <span class="status">{{ $t(row.item.status) }}</span>-->
<!--            </div>-->
          </td>
          <td>
            <div class="fw-bold">
            <span>{{ row.item.cost.totalCost == null ? '€' : row.item.cost.totalCost/100+'€' }}</span>
            </div>
          </td>
          <td>
            <div class="name mb-0 text-md-left">
              {{ row.item.mStartDate.format("DD/MM/YYYY") }}  <br>
              {{ row.item.mStartDate.format("HH:mm") + "-"+ row.item.mEndDate.format("HH:mm") }}
              {{ getDuration(row.item.mStartDate, row.item.mEndDate) }}
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-start p-2">
            <router-link
                class="btn bg-gradient-green border-0 me-2 text-white"
                :to="'/requests/' + row.item.id"
            >
              {{ $t("View") }}
            </router-link>

<!--            <base-button-->
<!--              type="success"-->
<!--              class="bg-red border-0"-->
<!--              size="md"-->
<!--              >-->
<!--              {{ $t("Delete") }}-->
<!--</base-button>-->
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
  </div>
</template>
<script>
import {getDuration, getStatusClass} from "@/helpers/common"

export default {
  name: "client-requests-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      clientData: {},
      listFull: false,
      loading: true,
      clients: [],
      loadingProcess: "",
      errorMessage: "",
      positions: [],
      model: {
        dates: [],
        positions: [],
        statuses: [],
        employees: []
      },
      columns: [
        {
          key:"Ref",
          label:"Ref"
        },
        // {
        //   key:"Client",
        //   label:"Client"
        // },
        {
          key:"Position",
          label:"Position"
        },
        {
          key:"status",
          label:"Status",
        },
        {
          key: "cost",
          label: "Cost"
        },
        {
          key: "startDate",
          label:"Start Date",
          sorting: true
        },
        {
          key: "createdAt",
          label: "Date Created",
          sorting: true
        },
        {
          key: "Actions",
          label: "Actions"
        },
      ],
      statuses: [
        {key: "VERIFIED", label: this.$t("Verified")},
        {key: "PENDING", label: this.$t("Pending")},
        {key: "CLAIMED", label: this.$t("Claimed")},
        {key: "FINISHED", label: this.$t("Finished")},
        {key: "CANCELED", label: this.$t("Canceled")},
      ],
      sortDirection: "desc",
      sortBy: "createdAt",
      tableData: [],
    }
  },
  async mounted() {
    if (this.$store.state.user.claims.role === "SYSTEM_USER") {
      await this.getClients()
    } else if (this.$store.state.user.claims.role === "CLIENT"){
      await this.getClient()
    }
    await this.getPositions()
    await this.getRequests()
  },
  methods: {
    getDuration,
    pendingReview(mEndDate, clientReviewId, status){
      if (this.userRole && this.userRole !== "SYSTEM_USER"){
        return false
      }
      if (status != null && status !== "FINISHED") {
        return false
      }
      if (mEndDate != null && mEndDate.toDate() > this.today ){
        return false
      }
      if (clientReviewId != null){
        return false
      }
      return true
    },
    hasReview(clientReviewId){
      if (this.userRole && !["SYSTEM_USER"].includes(this.userRole)){
        return false
      }

      return clientReviewId != null
    },
    async getClient(){
      await this.$store
          .dispatch("fetchClient", this.$store.state.user.claims.clientId)
          .then((data) => {
            console.log("fetchClient ======= ", data)
            this.clientData = data
          })
    },
    getStatusClass,
    async getPositions() {
      this.positionsLoading = true
      await this.$store.dispatch("fetchAllPositions", true).then((data) => {
        this.positionsLoading = false
        // this.positions = data.map( p => { return {key: p.id, value: p.name}})

        this.positions = data.flatMap( p => {
          if (this.$store.state.user.claims.role === "CLIENT"){
            if (this.clientData.positionIds.includes(p.id)){
              return {key: p.id, label: p.name}
            } else {
              return []
            }
          } else {
            return {key: p.id, label: p.name}
          }
        })
      })
    },
    async getClients() {
      await this.$store.dispatch("fetchClients").then((data) => {
        this.clients = data
      })
    },
    async getRequests(sorter = null, clear = false) {
      console.log("sorter", sorter)
      console.log("clear", clear)

      this.loading = true
      if (sorter){
        this.sortBy = sorter.field
        this.sortDirection = sorter.order
      }

      if (clear){
        this.tableData = []
        this.lastSnapshot = null
      }

      const filters = {
        positions: this.model.positions.map(c => c.key) ?? null,
        statuses: this.model.statuses.map(c => c.key) ?? null,
        employees: this.isEmployee && this.hasUser ? [this.$route.params.id] : this.model.employees.map(c => c.key),
        dates: this.model.dates ?? null,
      }
      let sorting
      if (this.model.dates != null && this.model.dates.length > 0){
        sorting = {
          sortBy: "startDate",
          sortDirection: "desc"
        }
      } else {
        sorting = {
          sortBy: this.sortBy,
          sortDirection: this.sortDirection
        }
      }
      console.log(filters)
      await this.$store.dispatch("fetchClientRequests", {clientId: this.$store.state.user.claims.clientId ?? "",filters: filters, sorting: sorting, lastSnapshot: this.lastSnapshot}).then((result) => {
        console.log("fetchClientRequests", result)
        this.loading = false
        if (result.data === null){
          this.listFull = true
          return
        }

        if (result.lastSnapshot !== null){
          this.lastSnapshot = result.lastSnapshot
        }

        result.data.forEach ( record => {
          // console.log("record", record)
          let index = this.tableData.findIndex(r => r.id === record.id)
          if (index > -1){
            this.tableData[index] = record
          } else {
            this.tableData.push(record)
          }
        })

        this.listFull = result.data === null || result.data.length < 10
      })
    },
  },
}
</script>
<style></style>
