<template>
  <div class="row">
    <div class="col-xl-4 col-lg-6">
      <stats-card
          :title="$t('Company commission')"
          type="gradient-orange"
          icon="fa fa-building"
          :loading="loading"
          :sub-title="settings.companyCommissionRatePercent+ '%'"
          class="mb-4 mb-xl-0"
      >
        <template v-slot:footer>
              <span class="text-success mr-2 float-start">
                <router-link class="btn btn-sm bg-gradient-gray text-white border-0" to="/configuration">{{ $t("Edit") }}</router-link>
              </span>
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </div>
    <div class="col-xl-4 col-lg-6">
      <stats-card
          :title="$t('Employee commission')"
          type="gradient-orange"
          :loading="loading"
          :sub-title="settings.employeeRatePercent+ '%'"
          icon="fa fa-user-doctor"
          class="mb-4 mb-xl-0"
      >
        <template v-slot:footer>
              <span class="text-success mr-2 float-start">
                <router-link class="btn btn-sm bg-gradient-gray text-white border-0" to="/configuration">{{ $t("Edit") }}</router-link>
              </span>
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </div>
    <div class="col-xl-4 col-lg-6">
      <stats-card
          :title="$t('Night shift range')"
          type="black"
          icon="fa fa-moon"
          :loading="loading"
          :sub-title="settings.nightShiftStartHour+':00 - ' + settings.nightShiftEndHour+':00'"
          class="mb-4 mb-xl-0"
      >
        <template v-slot:footer>
              <span class="text-success mr-2 float-start">
                <router-link class="btn btn-sm bg-gradient-gray text-white border-0" to="/configuration">{{ $t("Edit") }}</router-link>
              </span>
          <span class="text-nowrap"></span>
        </template>
      </stats-card>
    </div>
  </div>
</template>

<script>
import StatsCard from "@/components/StatsCard"

export default {
  name: "ConfigCards",
  components: {StatsCard},
  data() {
    return {
      loading: true,
      settings: {
        companyCommissionRatePercent: null,
        employeeRatePercent: null,
        nightShiftStartHour: null,
        nightShiftEndHour: null
      },
    }
  },
  async mounted() {
    await this.getSettings()
  },
  methods: {
    async getSettings() {
      // if (
      //   this.$store.state.system.companies.length > 0 &&
      //   this.$store.getters.isCacheExpired
      // ) {
      //   this.tableData = this.$store.state.system.companies
      //   return
      // }
      await this.$store.dispatch("fetchSettings").then((data) => {
        console.log("fetchSettings", data)
        this.settings = data
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>