<template>
  <card shadow type="secondary">
    <template #header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8 text-start">
            <h5 class="mb-0">{{ $t("Client Details") }}</h5>
          </div>
          <div v-if="['SYSTEM_USER', 'CLIENT'].includes(userRole)" class="col-4 text-end">
            <router-link class="btn btn-primary btn-sm" :to="userRole === 'SYSTEM_USER' ? '/clients/'+$route.params.id+'/edit' : '/account/edit'">
              {{ $t("Edit") }}
            </router-link>

          </div>
        </div>
      </div>
    </template>

    <form>
<!--      <div class="fw-bolder text-uppercase text-muted mt-4 text-start">Client Details</div>-->
<!--      <hr class="m-0">-->
      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-6">
            <display-label
                label="Legal name"
                :value="clientData.legalName"
            ></display-label>
          </div>
        </div>
      </div>
      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-6">
            <display-label
                label="Created at:"
                :value="clientData.createdAt"
            ></display-label>
          </div>
          <div class="col-md-6">
            <display-label
                label="Contact Phone:"
                :value="clientData.phoneNumber"
            ></display-label>
          </div>
        </div>
      </div>
      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-12 pt-2">
            <display-label
                label="Description:"
                :value="clientData.description"
            ></display-label>
          </div>
        </div>
      </div>

      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-12">
            <div class="pl-lg-4 text-start">
<!--              <div class="fw-bolder text-uppercase text-muted mt-4 text-start mt-4">Positions</div>-->
<!--              <hr class="m-0">-->
              <label class="form-label fw-bold m-0">{{$t("Positions") }}</label>
              <div style="    font-size: 20px;">
                <div v-for="item in positions" :key="item" class="badge bg-gradient-primary me-1" > {{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="fw-bolder text-uppercase text-muted mt-4 text-start">Contact Details</div>-->
<!--      <hr class="m-0">-->

<!--        <client-address :client-data="clientData"></client-address>-->
    </form>
  </card>
</template>
<script>
import DisplayLabel from "@/components/DisplayLabel"
export default {
  name: "client-details-card",
  components: {DisplayLabel},
  props: {
    clientData: {
      type: Object,
      description: "Client data",
    },
  },
  data() {
    return {
      positions: [],
      isDisabled: true,
      alertMessage: "",
      loadingProcess: "",
      model: {
        description: "asas",
        address: {
          street: "",
          countryCode: "",
          city: "",
          lat: 0,
          long: 0 ,
          postalCode: "",
          street2: "",
        },
      }
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  watch: {
    clientData(newVal) {
      console.log("newVal", newVal)
      this.model = newVal
      this.mapPositions()
    },
  },
  mounted() {
    // var self = this;
    console.log(this.$route.params)
    console.log("clientData card", this.clientData)
    // this.mapCompany();
    this.mapPositions()
  },
  methods: {
    async mapPositions(){
      if (!this.clientData.hasOwnProperty("positionIds")){
        return
      }
      let positions = await this.$store.dispatch("fetchAllPositions")

      this.clientData.positionIds.forEach(positionsId => {
        let selected = positions.find(pos => pos.id === positionsId)

        if (selected ?? false) {
          this.positions.push(selected)
        }
      })
    }
  },
}
</script>
<style></style>
