<template>
  <footer class="footer px-4 p-3">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-start text-muted">
          © {{ year }}
<!--          <a-->
<!--            href="https://www.pine-native.com"-->
<!--            class="font-weight-bold ml-1"-->
<!--            target="_blank"-->
<!--            >-->
            AllCare
<!--          </a>-->
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a
              href="/"
              class="nav-link p-0"
              target="_blank"
              >AllCare</a
            >
          </li>
          <li class="nav-item  ps-2">
<!--            <a-->
<!--              href="/about-us"-->
<!--              class="nav-link"-->
<!--              target="_blank"-->
<!--              >-->
<!--              {{ $t("About Us") }}-->
<!--            </a>-->
          </li>
          <li class="nav-item ps-2">
<!--            <a-->
<!--              href="/license"-->
<!--              class="nav-link"-->
<!--              target="_blank"-->
<!--              >-->
<!--            {{ $t("License") }}-->
<!--            </a>-->
          </li>
          <li><span class="ps-1 fw-bold"> v{{ version }}</span></li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
import packageData from "../../package.json"
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      version: ""
    }
  },
  mounted() {
    // console.log("packageData", packageData)
    this.version = packageData.version
  }
}
</script>
<style></style>
