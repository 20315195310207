<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <card shadow type="secondary">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8 text-start">
                    <h5 class="mb-0">{{ $t("Filter by") }}</h5>
                  </div>
                  <!--              <div class="col-4 text-end">-->
                  <!--                <base-button tag="a" :href="'/clients/'+$route.params.id+'/edit'" type="primary" class="btn btn-sm btn-primary">-->
                  <!--                  Edit-->
                  <!--                </base-button>-->
                  <!--              </div>-->
                </div>
              </div>
            </template>

            <div class="row text-start">
              <div class="col-md-4">
                <div class="form-group has-label">

                  <label class="form-label">{{ $t("Shift positions") }}</label>
                  <multiselect
                      v-model="model.positions"
                      :show-labels="false"
                      label="label"
                      track-by="key"
                      :close-on-select="true"
                      :placeholder="$t('Select positions')"
                      :options="positions" :multiple="true"

                  ></multiselect>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group has-label">
                  <label class="form-label">{{ $t("Status") }}</label>
                  <multiselect
                      v-model="model.statuses"
                      :show-labels="false"
                      label="label"
                      track-by="key"
                      :placeholder="$t('Select status')"
                      :options="$route.name === 'myRequests' ? statuses : limitedStatuses" :multiple="true">
                  </multiselect>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group has-label">
                  <label class="form-label">{{ $t("Shift date range") }}</label>
                  <VueDatePicker
                      v-model="model.dates"
                      format="dd/MM/yyyy"
                      :flow="['calendar']"
                      :placeholder="$t('Select dates')"
                      :enable-time-picker="false"
                      range
                      auto-apply
                      :min-date="$route.name === 'myRequests' ? null : $moment().add(0, 'day').format()"
                  ></VueDatePicker>
                </div>
              </div>
            </div>

            <template #footer>
              <div class="col float-end">
                <submit-button
                    :alert-message="errorMessage"
                    :state="loadingProcess"
                    @clicked="getRequests(null, true)">
                  {{ $t("Search") }}
                </submit-button>
              </div>
            </template>

          </card>
          <br>
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
                class="card-header border-0"
                :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col-md-4">
                  <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
                    {{ $route.name === "myRequests" ? $t("My shifts") : $t("Available shift list") }}
                  </h5>
                </div>
                <div v-if="$route.name !== 'myRequests'"
                     class="col-md-8 text-md-end text-start pt-md-0 pt-3 text-black-50">
                  * {{ $t("Shifts marked with") }} <span class="text-danger">{{ $t("CLAIMED") }}</span>
                  {{ $t("are not available because someone else claimed the shift") }}.
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                  v-model:loading="loading"
                  :columns="columns"
                  class="table align-items-center table-flush text-start"
                  :class="type === 'dark' ? 'table-dark' : ''"
                  :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                  tbody-classes="list"
                  :data="tableData"
                  :list-full="listFull"
                  :sort-by="sortBy"
                  :sort-direction="sortDirection"
                  :paginated="true"
                  @sort="getRequests"
                  @fetch-more="getRequests(null, false)"
              >
                <template #default="row">
                  <td>
                    <!--                    <a-->
                    <!--                        :href="'/requests/' + row.item.id"-->
                    <!--                    >-->
                    <!--                      <span class="name mb-0 text-sm">{{ row.item.refNumber ?? "No Ref" }}</span>-->
                    <!--                    </a>-->
                    <div
                        v-if="row.item.hasOwnProperty('claimedBy') && row.item.claimedBy != null && userRole === 'EMPLOYEE' && row.item.claimedBy !== $store.state.user.claims.user_id">
                      {{ row.item.refNumber ?? "No Ref" }}
                    </div>
                    <router-link v-else :to="'/requests/' + row.item.id"> {{
                        row.item.refNumber ?? "No Ref"
                      }}
                    </router-link>
                  </td>
                  <td v-if="!hasUser" class="budget">
                    <router-link class="fw-bold" :to="'/clients/' + row.item.clientId"> {{
                        row.item.clientName
                      }}
                    </router-link>

                  </td>
                  <td class="fw-bold">
                    {{ row.item.position.name }}
                  </td>
                  <td v-if="$route.name === 'myRequests'">
                    <div class="badge p-1 font-size-xs" :class="getStatusClass(row.item.status)">
                      <span class="status">{{ $t(row.item.status) }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="fw-bold">
                      <span>{{ row.item.cost.totalCost == null ? '€' : row.item.cost.totalCost / 100 + '€' }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="name mb-0 text-md-left">
                      {{ row.item.deadline }}
                    </div>
                  </td>

                  <td>
                    <div class="name mb-0 text-md-left">
                      {{ row.item.startDate }} - {{ row.item.mEndDate.format("HH:mm") }}
                    </div>
                  </td>
                  <td>

                    <label v-if="row.item.status === 'CLAIMED' && $route.name !== 'myRequests'"
                           class="text-center text-danger">{{ $t("CLAIMED") }}*</label>
                    <router-link
                        v-else
                        :to="'/requests/' + row.item.id"
                        class="btn bg-gradient-green border-0 me-2 text-white text-end"
                    >
                      {{ $t("View") }}
                    </router-link>
                  </td>
                </template>
              </base-table>
            </div>

            <!--    <div-->
            <!--      class="card-footer d-flex justify-content-end"-->
            <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
            <!--    >-->
            <!--      <base-pagination total="30"></base-pagination>-->
            <!--    </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getStatusClass} from "@/helpers/common"
import {getAnalytics, logEvent} from "firebase/analytics"

export default {
  name: "requests-search-table",
  props: {
    columns: {
      type: Array,
      default: () => [
        {
          key: "Ref",
          label: "Ref"
        },
        {
          key: "Client",
          label: "Client"
        },
        {
          key: "Position",
          label: "Position"
        },
        {
          key: "cost",
          label: "Cost"
        },
        {
          key: "deadline",
          label: "Deadline",
        },
        {
          key: "startDate",
          label: "Start Date",
          sorting: true
        },
        {
          key: "",
          label: "",
        },
      ],
    }
  },
  data() {
    return {
      title: "Available shift list",
      lastSnapshot: null,
      loading: true,
      clients: [],
      loadingProcess: "",
      listFull: false,
      errorMessage: "",
      positions: [],
      sortDirection: "asc",
      sortBy: "startDate",
      model: {
        dates: [],
        clients: [],
        positions: [],
        statuses: [],
        employees: []
      },
      limitedStatuses: [
        {key: "VERIFIED", label: this.$t("Verified")},
        {key: "CLAIMED", label: this.$t("Claimed")},
      ],
      statuses: [
        {key: "VERIFIED", label: this.$t("Verified")},
        {key: "CLAIMED", label: this.$t("Claimed")},
        {key: "CANCELED", label: this.$t("Canceled")},
        {key: "FINISHED", label: this.$t("Finished")},
      ],

      tableData: [],
    }
  },
  computed: {
    userRole() {
      return this.$store.state.user.claims.role ?? null
    },
    hasUser() {
      return ["clientRequests", "employeeRequests"].includes(this.$route.name)
    },
    isEmployee() {
      return ["employeeRequests"].includes(this.$route.name) || this.$store.state.user.claims.role === "EMPLOYEE"
    },
    isClient() {
      return ["clientRequests"].includes(this.$route.name)
    }
  },
  async mounted() {

    if (this.$store.state.showDownloadMobileBanner) {
      this.showDownloadAppsBanner()
    }

    const analytics = getAnalytics()
    logEvent(analytics, "page_view", {
      page_title: this.$route.name
    })

    if (this.$store.state.user.claims.role === "SYSTEM_USER") {
      await this.getClients()
    }
    await this.getPositions()
    await this.getRequests()
  },
  methods: {
    async showDownloadAppsBanner() {
      let self = this
      const {value: checkbox} = await this.$swal.fire({
        title: "<div class='primaryColor'>" + this.$t("Exciting News!") + "</div><strong> </strong>",
        icon: "",
        input: "checkbox",
        inputPlaceholder: this.$t("Don't show this again"),
        inputValue: 0,
        html: `
<label>
    ` + this.$t("Our new app is here, bringing you all the services you love, plus new features to explore!") + ` <br><br>
    ` + this.$t("Download now and enjoy a smoother experience and exclusive perks.") + `</label>
  <div class="pt-3">
  <a href='https://play.google.com/store/apps/details?id=com.pinenative.allcare&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' style="float: left;width: 50%;"><img alt='Get it on Google Play' src='` +require("../../assets/images/play_store_en_badge.png") +`' style="border-radius: 13px; width: 100%;max-width: 230px;"/></a>

  <a href="https://apps.apple.com/cy/app/allcare-cyprus/id6498791883?itsct=apps_box_badge&amp;itscg=30200" style=" float: right;width: 50%;   padding: 10px;"><img src='` +require("../../assets/images/app-store-en-us.svg") +`' alt="Download on the App Store" style="border-radius: 13px; width: 100%;max-width: 200px;"></a>
  </div>`,
        inputAutoFocus: false,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: true,
        focusConfirm: false,
        customClass: {
          confirmButton: "btn btn-link bg-clear text-black"
        },
        confirmButtonText: this.$t("Not now"),
        confirmButtonAriaLabel: "No"
      })

      if (checkbox) {
        self.$store.dispatch("hideBanner", false)
      }
    },
    getStatusClass,
    async getPositions() {
      this.positionsLoading = true
      await this.$store.dispatch("fetchAllPositions").then((data) => {
        this.positionsLoading = false

        this.positions = data.flatMap(p => {
          if (this.$store.state.user.claims.role === "EMPLOYEE") {
            if (this.$store.state.user.data.positionIds.includes(p.id)) {
              return {key: p.id, label: p.name}
            } else {
              return []
            }
          } else {
            return {key: p.id, label: p.name}
          }
        })

      })
    },
    async getClients() {
      await this.$store.dispatch("fetchClients").then((data) => {
        // console.log("fetchClients", data)
        this.clients = data
      })
    },
    async getRequests(sorter = null, clear = false) {
      this.loading = true
      if (sorter) {
        this.sortBy = sorter.field
        this.sortDirection = sorter.order
      }

      if (clear) {
        this.tableData = []
        this.lastSnapshot = null
      }

      // if (
      //   this.$store.state.system.companies.length > 0 &&
      //   this.$store.getters.isCacheExpired
      // ) {
      //   this.tableData = this.$store.state.system.companies
      //   return
      // }

      const filters = {
        positions: this.model.positions.length > 0 ? this.model.positions.map(c => c.key) : this.positions.map(c => c.key),
        statuses: this.$route.name === "myRequests" || this.model.statuses.length > 0 ? this.model.statuses.map(c => c.key) : ["VERIFIED", "CLAIMED"],
        employees: this.$route.name === "myRequests" ? [this.$store.state.user.claims.user_id] : [],
        dates: this.model.dates ?? null,
      }

      let sorting
      if (this.model.dates != null && this.model.dates.length > 0) {
        sorting = {
          sortBy: "startDate",
          sortDirection: "desc"
        }
      } else {
        sorting = {
          sortBy: this.sortBy,
          sortDirection: this.sortDirection
        }
      }


      console.log(filters)

      await this.$store.dispatch("fetchRequests", {
        filters: filters,
        sorting: sorting,
        lastSnapshot: this.lastSnapshot
      }).then((result) => {
        // console.log("fetchRequestss", result)
        this.loading = false

        if (result.lastSnapshot !== null) {
          this.lastSnapshot = result.lastSnapshot
        }

        result.data.forEach(record => {
          // console.log("record", record)
          let index = this.tableData.findIndex(r => r.id === record.id)
          if (index > -1) {
            this.tableData[index] = record
          } else {
            this.tableData.push(record)
          }
        })

        this.listFull = result.data === null || result.data.length < 10
      })
      // .catch((error) => {
      //   alert(error)
      //   this.$swal.fire({
      //     icon: "error",
      //     html: error,
      //     title: this.$t("Something went wrong!"),
      //   })
      // })
    },
  },
}
</script>
<style>
.bannerHeader {
  text-transform: uppercase;
  color: var(--bs-primary);
  font-family: monospace;
  font-size: 47px;
}

.primaryColor {
  color: var(--bs-primary);

}

.bg-clear {
  background-color: transparent !important;
}
</style>
