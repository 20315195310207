<template>
  <div class="row mb-5">

    <div class="col-xl-8 order-xl-1">

      <client-details-card v-if="clientData != null" :client-data="clientData"></client-details-card>
      <card shadow type="secondary" class="mt-3">
        <template #header>
          <div class="bg-white border-0">
            <div class="row align-items-center">
              <div class="col-8 text-start">
                <h5 class="mb-0">{{ $t("Client Address") }}</h5>
              </div>
            </div>
          </div>
        </template>
        <client-address :client-data="clientData"></client-address>
      </card>
      <br />
    </div>
    <div v-if="userRole === 'SYSTEM_USER'" class="col-xl-4 order-xl-1">
      <client-requests-analytics-card v-if="clientData != null" :client-data="clientData"></client-requests-analytics-card>
      <br />
    </div>
  </div>
</template>

<script>
import ClientDetailsCard from "@/views/Cards/ClientDetailsCard"
import ClientRequestsAnalyticsCard from "@/views/Cards/ClientRequestsAnalyticsCard"
import ClientAddress from "@/views/Cards/ClientAddressCard"

export default {
  name: "clientGeneral",
  components: {ClientAddress, ClientRequestsAnalyticsCard, ClientDetailsCard},
  props:{
    clientData: {
      type: Object
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  mounted() {
    // console.log("clientData general", this.clientData)
  }
}
</script>

<style scoped>

</style>