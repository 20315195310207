import {createStore} from "vuex"
import VuexPersistence from "vuex-persist"
import {getAuth, sendPasswordResetEmail, signInWithEmailAndPassword} from "firebase/auth"
import {fireApp} from "@/main"
import moment from "moment"
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage"
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  writeBatch
} from "firebase/firestore"
import {fetchAndActivate, getRemoteConfig, getValue} from "firebase/remote-config"

function getDocReference(path, id) {
  let fireS = getFirestore(fireApp)
  return doc(collection(fireS, path), id)
}

// function getDocEl(path, id) {
//   let fireS = getFirestore(fireApp);
//   return doc(fireS, path, id);
// }
//
// function getCollectionGroupReference(path) {
//   let fireS = getFirestore(fireApp);
//   return collectionGroup(fireS, path);
// }

export const store = createStore({
  plugins: [new VuexPersistence().plugin],
  state: {
    showDownloadMobileBanner: true,
    expiryDate: new Date(),
    positionsExpiryDate: null,
    positions: [],
    positionsLast: null,
    locale: "en",
    settings: {},
    showLogin: false,
    clients: [],
    requestStatistics: {},
    user: {
      claims: null,
      loggedIn: false,
      data: null,
    },
  },
  mutations: {
    setExpiryDate: (state) => {
      // Create a date
      // Set the state

      state.expiryDate = moment(new Date()).add(30, "m").toDate()
    },
    SET_COOKIE: (state, data) => {
      state[data.key] = {createdAt: new Date(), expirationDate: data.expires ?? moment(new Date()).add(30, "m").toDate() , value: data.value}
    },
    SET_LOCALE (state, lang) {
      state.locale = lang
    },
    SET_SHOW_DOWNLOAD_MOBILE (state, val) {
      state.showDownloadMobileBanner = val
    },
    RESET_STATE(state) {
      state.user = {
        claims: null,
        loggedIn: false,
        data: null,
      }
      state.clients = []
      state.locale = "en"
    },
    SET_CLIENTS(state, data) {
      state.clients = data
    },
    SET_LOGGED_IN(state, data) {
      state.user.loggedIn = data
    },
    CLEAR_POSITIONS(state, data){
      state.positions = []
    },
    SET_EXPIRE_POSITIONS(state){
      state.positions.expired = true
    },
    SET_ALL_POSITIONS(state, data){
      // todo set up cache
      state.positions = data
    },
    INIT_CACHE_POSITIONS(state){
      state.positionsExpiryDate =  moment(new Date()).add(1, "hour").toDate()
    },
    SET_POSITIONS(state, data){
      // data.forEach( item => {
      //   let index = state.positions.findIndex(r => r.id === item.id)
      //   if (index === -1){
      //     state.positions.push(item)
      //   } else {
      //     state.positions[index] = item
      //   }
      // })
    },
    SET_POSITION_LAST_VISIBLE(state, data){
      state.positionsLast = data
    },
    SET_USER(state, data) {
      state.user.data = data
    },
    SET_SETTINGS(state,data) {
      state.settings = data
    },
    SET_USER_PRIVATE(state, data) {
      state.user.private = data
    },
    SET_USER_CLAIMS(state, data) {
      state.user.claims = data
    },
    SET_ROUTES(state, data) {
      state.routes = data
    },
    SET_STATISTICS(state, data) {
      state.system.statistics = data
    },
    GET_COMPANY(state, data) {
      state.company = data
    },
    GET_COMPANY_STATISTICS(state, data) {
      state.companyStatistics = data
    },
    SET_SYSTEM_USERS(state, data) {
      state.system.systemUsers = data
    },
    SET_COMPANIES(state, data) {
      state.system.companies = data
    },
  },
  getters: {
    isCacheExpired: (state) => new Date(state.expiryDate) < new Date(),
    isPositionsCacheExpired: (state) => new Date(state.positionsExpiryDate) < new Date(),
  },
  actions: {
    setCookie ({ commit }, data) {
      commit("SET_COOKIE", data)
    },
    getCookie ({ commit }, key) {
      let val = store.state[key]
      if (new Date(val.expirationDate) < new Date()){
        return null
      }
      return val
    },
    setLocale ({ commit }, lang) {
      commit("SET_LOCALE", lang)
    },
    async fetchAllPositions({ dispatch, commit, getters, state }, fromCached = false) {
      if (!getters.isPositionsCacheExpired &&
          store.state.positions !== null && fromCached){
          return store.state.positions
      }

      let fireS = getFirestore(fireApp)

      let collectionRef = collection(fireS, "positions")
      let docsQuery = query(
          collectionRef,
          orderBy("name", "asc"),
          limit(200)
      )

      return await getDocs(docsQuery).then((docs) => {
        let docsData = []
        docs.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id

          if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
            data["createdAt"] = moment(data["createdAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
          docsData.push(data)
        })

        // console.log("docsData", docsData)
        commit("SET_ALL_POSITIONS", docsData)
        if (fromCached) {
          commit("INIT_CACHE_POSITIONS")
        }
        return docsData
      })
    },
    hideBanner({ dispatch, commit, state }, value){
      commit("SET_SHOW_DOWNLOAD_MOBILE", value)
    },
    async fetchPaginatedDocs({ dispatch, commit, state }, params) {

    },
    // async fetchPositions({ dispatch, commit, state }, params) {
    //
    //   if (!params.reset){
    //     if (!params.loadMore && state.positions.length > 0){
    //       return state.positions
    //     }
    //   }
    //
    //   if (params.reset){
    //     commit("SET_POSITION_LAST_VISIBLE", null)
    //     commit("CLEAR_POSITIONS", [])
    //   }
    //
    //   let fireS = getFirestore(fireApp)
    //
    //   let collectionRef = collection(fireS, "positions")
    //   let docsQuery = query(
    //       collectionRef,
    //       orderBy("name", "asc"),
    //       limit(params.limit ?? 20)
    //   )
    //
    //   if (state.positionsLast != null){
    //     docsQuery = query(docsQuery, startAfter(state.positionsLast))
    //   }
    //
    //   await getDocs(docsQuery).then((docs) => {
    //     let docsData = []
    //     docs.forEach((doc) => {
    //       let data = doc.data()
    //       data["id"] = doc.id
    //
    //       if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
    //         data["createdAt"] = moment(data["createdAt"].toDate()).format(
    //             "DD/MM/YYYY HH:mm"
    //         )
    //       }
    //       docsData.push(data)
    //     })
    //
    //     commit("SET_POSITIONS", docsData)
    //     if(docs.docs.length > 0) {
    //       commit("SET_POSITION_LAST_VISIBLE", docs.docs[docs.docs.length - 1])
    //     }
    //
    //   })
    // },
    async fetchUser({ dispatch, commit }, user) {

      if (user) {
        const claims = await dispatch("getClaims")
        // console.log("claims", claims)
        if (claims === null){
          dispatch("signOut")
        }

        const role = claims.role
        if (role === null){
          return true
        }

        let path
        switch (role) {
          case "EMPLOYEE":
            path = "employees"
            break
          case "CLIENT":
            path = "clients/" + claims.clientId + "/users"
            break
          case "SYSTEM_USER":
            path = "systemUsers"
            break
          default:
        }


        // console.log("sho me user", path)
        if (path) {
          let userQuery = getDocReference(
              path,
              user.uid
          )

          let doc = await getDoc(userQuery)

          if (doc.exists()) {
            let userD = doc.data()
            commit("SET_USER", userD)
          }
        }

        commit("SET_USER_CLAIMS", claims)

        return true

      } else {
        commit("SET_USER", null)
        return false
      }
    },

    async fetchRemoteConfig({ commit }, data) {
      let remoteConfig = getRemoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 60000
      return await fetchAndActivate(remoteConfig)
          .then(() => {
            // console.log(remoteConfig)
            let value = getValue(remoteConfig, data.value)
            return JSON.parse(value.asString())
          })
          .catch((err) => {
            console.log("fetchAndActivate", err)
          })
    },

    async fetchSystemUsers({ commit }) {
      let fireS = getFirestore(fireApp)

      let collectionRef = collection(fireS, "systemUsers")
      const docsQuery = query(
          collectionRef,
          where("status", "in", ["ACTIVE", "INACTIVE"]),
          limit(20)
      )
      return await getDocs(docsQuery).then((docs) => {
        let docsData = []
        docs.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          data["name"] = data["firstName"] + " " + data["lastName"]
          if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
            data["createdAt"] = moment(data["createdAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
          docsData.push(data)
        })
        // commit("SET_SYSTEM_USERS", docsData)
        return docsData
      })
    },
    async fetchSystemUser({ dispatch, commit }, id) {
      return await dispatch("fetchDoc", {
        path: "systemUsers",
        id: id
      })
    },
    async getClaims() {
      return await getAuth(fireApp)
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => {

          return idTokenResult.claims
        })
        .catch((error) => {
          console.log(error)
          return null
        })
    },
    isLoggedIn({ commit }) {
      if (this.state.user.loggedIn){
        return true
      }
      let currUser = getAuth(fireApp).currentUser
      // console.log("isLoggedIn currUser", currUser)
      commit("SET_LOGGED_IN", currUser != null)
      return currUser != null
    },
    async signOut({commit}) {
      return await getAuth(fireApp)
          .signOut()
          .then(() => {
            // console.log("logged out");
            commit("RESET_STATE")
            return true
          })
    },
    async logIn(context, { email, password }){
      const response = await signInWithEmailAndPassword(getAuth(fireApp), email, password)
          if (response){
           // context.commit("SET_USER", response.user)
            // this.$router.push({path: "/clients"})
            return true
          } else {
            throw new Error("login failed")
          }
    },
    async fetchClientUser({commit, dispatch, getters}, data) {
      return await dispatch("fetchDoc", {
        path: "/clients/"+data.clientId+"/users",
        id: data.id
      })
    },
    async fetchClients({commit, dispatch}) {
      if (store.state.user.claims.role !== "SYSTEM_USER"){
        return []
      }

      // if (!getters.isCacheExpired &&
      //     store.state.clients !== null){
      //   console.log("CLIENTS_FROM_CACHE")
      //   return store.state.clients
      // }
      const clients = await dispatch("fetchDocs", {path: "clients"})

      commit("SET_CLIENTS", clients)
      commit("setExpiryDate")
      return clients
      // for (let i = 0; i < docs.length; i++) {
      //   docs[i]["clientName"] = docs[i].firstName + " " + docs[i].lastName
      // }

    },
    async fetchInvoice({commit, dispatch}, data) {
      return await dispatch("fetchDoc", {
        path: "employees/"+data.employeeId+"/invoices",
        id: data.invoiceId
      })

    },
    async fetchInvoices({commit, dispatch}, data) {
      let fireS = getFirestore(fireApp)
      let filters = data.filters

      let collectionRef
      if (filters.employee != null){
        collectionRef = collection(fireS, "employees/"+filters.employee+"/invoices")
      } else {
        collectionRef = collectionGroup(fireS, "invoices")
      }
      let docsQuery = query(
          collectionRef,
          limit(10)
      )

      if (filters.statuses.length > 0){
        docsQuery = query(docsQuery, where("status", "in", filters.statuses))
      }

      if (data.hasOwnProperty("sorting")){
        let sorting = data.sorting
        docsQuery = query(docsQuery, orderBy(sorting.sortBy, sorting.sortDirection))
      }

      if (data.lastSnapshot != null){
        // console.log("startAfter", data.lastSnapshot)
        docsQuery = query(docsQuery, startAfter(data.lastSnapshot))
      }



      let results = await getDocs(docsQuery)


      let docsData = []
      results.forEach((doc) => {
        let data = doc.data()
        data["employeeId"] = doc.ref.parent.parent.id
        data["id"] = doc.id
        if (data.hasOwnProperty("createdAt") && data.createdAt !== null) {
          data["createdAt"] = moment(data["createdAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
        docsData.push(data)
      })

      return {
        data: docsData,
        lastSnapshot: results.docs[results.docs.length - 1]
      }
    },
    async fetchClientRequest({commit, dispatch}, id) {

      let docQuery = getDocReference(
          "clients/"+ this.state.user.claims.clientId +"/requests",
          id
      )

      // console.log("filters data", id)

      let result = await getDoc(docQuery)

      let request = result.data()
      request["id"] = result.id

      if(request.hasOwnProperty("startDate") && request.startDate !== null){
        request["mStartDate"] = moment(request["startDate"].toDate())
      }
      if(request.hasOwnProperty("endDate") && request.endDate !== null){
        request["mEndDate"] = moment(request["endDate"].toDate())
      }
      if(request.hasOwnProperty("deadline") && request.deadline !== null){
        request["mDeadline"] = moment(request["deadline"].toDate())
      }

      if(request.hasOwnProperty("createdAt") && request.createdAt !== null){
        request["createdAt"] = moment(request["createdAt"].toDate()).format(
            "DD/MM/YYYY HH:mm"
        )
      }
      if(request.hasOwnProperty("updatedAt") && request.createdAt !== null){
        request["updatedAt"] = moment(request["updatedAt"].toDate()).format(
            "DD/MM/YYYY HH:mm"
        )
      }

      return request

    },
    async fetchClientRequests({commit, dispatch}, data) {
      let fireS = getFirestore(fireApp)

      let collectionRef = collection(fireS, "clients/"+ data.clientId +"/requests")

      let docsQuery = query(
          collectionRef,
          limit(10)
      )

      // console.log("filters data", data)

      let filters = data.filters
      if (filters.statuses.length > 0){
        docsQuery = query(docsQuery, where("status", "in", filters.statuses))
      }
      if (filters.hasOwnProperty("employees") && filters.employees.length > 0) {
        docsQuery = query(docsQuery, where("claimedBy", "in", filters.employees))
      }
      if (filters.hasOwnProperty("positions") && filters.positions.length > 0){
        docsQuery = query(docsQuery, where("position.id", "in", filters.positions))
      }
      if (filters.hasOwnProperty("dates") && filters.dates != null && filters.dates.length > 0){
        const fromDate = moment(filters.dates[0]).utcOffset(0).set({hour:0,minute:0,second:0}).toDate()
        const toDate = moment(filters.dates[1]).utcOffset(0).set({hour:23,minute:59,second:59}).toDate()
        docsQuery = query(docsQuery, where("startDate", ">", fromDate))
        docsQuery = query(docsQuery, where("startDate", "<", toDate))
      }

      if (data.hasOwnProperty("sorting")){
        let sorting = data.sorting
        docsQuery = query(docsQuery, orderBy(sorting.sortBy, sorting.sortDirection))
      }

      if (data.lastSnapshot != null){
        console.log("startAfter", data.lastSnapshot)
        docsQuery = query(docsQuery, startAfter(data.lastSnapshot))
      }

      let results = await getDocs(docsQuery)


      let docsData = []
      results.forEach((doc) => {
        let data = doc.data()
        data["id"] = doc.id
        if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
          data["createdAt"] = moment(data["createdAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
        if(data.hasOwnProperty("startDate") && data.startDate !== null){
          data["mStartDate"] = moment(data["startDate"].toDate())
          data["startDate"] = moment(data["startDate"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
        if(data.hasOwnProperty("deadline") && data.startDate !== null){
          data["deadline"] = moment(data["deadline"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
        if(data.hasOwnProperty("endDate") && data.endDate !== null){
          data["mEndDate"] = moment(data["endDate"].toDate())
          data["endDate"] = moment(data["endDate"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
        docsData.push(data)
      })



      return {
        data: docsData,
        lastSnapshot: results.docs[results.docs.length - 1]
      }

      // for (let i = 0; i < docs.length; i++) {
      //   docs[i]["clientName"] = docs[i].firstName + " " + docs[i].lastName
      // }

    },
    async fetchRequests({commit, dispatch, state}, data) {
      let fireS = getFirestore(fireApp)

      let collectionRef = collectionGroup(fireS, "requests")
      let docsQuery = query(
          collectionRef,
          limit(10)
      )

      let filters = data.filters
      if (filters.statuses.length > 0){
        docsQuery = query(docsQuery, where("status", "in", filters.statuses))
      }
      if (filters.hasOwnProperty("clients") && filters.clients.length > 0) {
        docsQuery = query(docsQuery, where("clientId", "in", filters.clients))
      }
      if (filters.hasOwnProperty("employees") && filters.employees.length > 0) {
        docsQuery = query(docsQuery, where("claimedBy", "in", filters.employees))
      }
      if (filters.hasOwnProperty("positions") && filters.positions.length > 0){
        docsQuery = query(docsQuery, where("position.id", "in", filters.positions))
      }
      if (filters.hasOwnProperty("invoiceId")){
        docsQuery = query(docsQuery, where("invoiceId", "==", filters.invoiceId))
      }
      if (filters.hasOwnProperty("dates") && filters.dates != null && filters.dates.length > 0){
        const fromDate = moment(filters.dates[0]).utcOffset(0).set({hour:0,minute:0,second:0}).toDate()
        const toDate = moment(filters.dates[1]).utcOffset(0).set({hour:23,minute:59,second:59}).toDate()
        docsQuery = query(docsQuery, where("startDate", ">", fromDate))
        docsQuery = query(docsQuery, where("startDate", "<", toDate))
        // docsQuery = query(docsQuery, orderBy("startDate", "desc"))
        if (data.hasOwnProperty("sorting") &&  data.sorting.sortBy !== "startDate") {
          docsQuery = query(docsQuery, orderBy("startDate", "desc"))
        }
      }

      console.log("data.sorting", data.sorting)
      if (data.hasOwnProperty("sorting")) {
        let sorting = data.sorting
        docsQuery = query(docsQuery, orderBy(sorting.sortBy, sorting.sortDirection))
      }
      if (data.lastSnapshot != null){
        console.log("startAfter", data.lastSnapshot)
        docsQuery = query(docsQuery, startAfter(data.lastSnapshot))
      }

      let results = await getDocs(docsQuery)


      let docsData = []
      results.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
            data["createdAt"] = moment(data["createdAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
          if(data.hasOwnProperty("startDate") && data.startDate !== null){
            data["mStartDate"] = moment(data["startDate"].toDate())
            data["startDate"] = moment(data["startDate"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
          if(data.hasOwnProperty("deadline") && data.startDate !== null){
            data["deadline"] = moment(data["deadline"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
        if(data.hasOwnProperty("endDate") && data.endDate !== null){
          data["mEndDate"] = moment(data["endDate"].toDate())
          data["endDate"] = moment(data["endDate"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )

        }
          docsData.push(data)
      })



      return {
        data: docsData,
        lastSnapshot: results.docs[results.docs.length - 1]
      }

      // for (let i = 0; i < docs.length; i++) {
      //   docs[i]["clientName"] = docs[i].firstName + " " + docs[i].lastName
      // }

    },
    async fetchRequest({commit, dispatch}, id) {
      let request = await dispatch("fetchGroupDoc", {
        path: "requests",
        id: id
      })

      console.log("request", request)
      if(request.hasOwnProperty("startDate") && request.startDate !== null){
        request["mStartDate"] = moment(request["startDate"].toDate())
      }
      if(request.hasOwnProperty("endDate") && request.endDate !== null){
        request["mEndDate"] = moment(request["endDate"].toDate())
      }
      if(request.hasOwnProperty("deadline") && request.deadline !== null){
        request["mDeadline"] = moment(request["deadline"].toDate())
      }
      return request
    },
    async fetchEmployeeReviews({commit, dispatch}, data) {
      let fireS = getFirestore(fireApp)

      console.log("dataaaaaaaaa", data)
      let collectionRef = collection(fireS, "/employees/" + data.employeeId + "/reviews")
      let docsQuery = query(
          collectionRef,
          limit(10)
      )

      let filters = data.filters
      if (filters.hasOwnProperty("clients") && filters.clients.length > 0) {
        docsQuery = query(docsQuery, where("clientId", "in", filters.clients))
      }

      // if (data.hasOwnProperty("sorting")) {
      //   let sorting = data.sorting
      //   docsQuery = query(docsQuery, orderBy(sorting.sortBy, sorting.sortDirection))
      // }
      if (data.lastSnapshot != null){
        console.log("startAfter", data.lastSnapshot)
        docsQuery = query(docsQuery, startAfter(data.lastSnapshot))
      }

      let results = await getDocs(docsQuery)

      let docsData = []
      results.forEach((doc) => {
        let data = doc.data()
        data["id"] = doc.id
        if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
          data["createdAt"] = moment(data["createdAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }

        docsData.push(data)
      })

      return {
        data: docsData,
        lastSnapshot: results.docs[results.docs.length - 1]
      }
    },
    async fetchClientRequestReview({commit, dispatch}, params){

      let doc = await dispatch("fetchDoc", {
        path: "employees/" + params.employeeId + "/reviews",
        id: params.reviewId,
      })
      return doc
    },
    async fetchEmployees({commit, dispatch}, data) {

      let fireS = getFirestore(fireApp)
      let collectionRef = collection(fireS, "employees")

      let queryLimit = data.limit ?? 10

      let docsQuery = query(
          collectionRef,
          limit(queryLimit),
      )

      docsQuery = query(docsQuery, orderBy("firstName", "asc"))

      if (data.hasOwnProperty("position") && data.position != null){
        docsQuery = query(docsQuery, where("positionIds", "array-contains", data.position))
      }
      if (data.hasOwnProperty("status") && data.status != null){
        docsQuery = query(docsQuery, where("status", "==", data.status))
      }

      if (data.hasOwnProperty("name") && data.name != null && data.name !== ""){
        docsQuery = query(docsQuery, where("queries", "array-contains", data.name.toLowerCase()))
      }

      if (data.lastSnapshot != null){
        console.log("startAfter", data.lastSnapshot)
        docsQuery = query(docsQuery, startAfter(data.lastSnapshot))
      }

      let results = await getDocs(docsQuery)

      let docsData = []
      results.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
            data["createdAt"] = moment(data["createdAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
        if(data.hasOwnProperty("updatedAt") && data.createdAt !== null){
          data["updatedAt"] = moment(data["updatedAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
          data.name = data.firstName +" "+ data.lastName
          docsData.push(data)
        })

      return {
        data: docsData,
        lastSnapshot: docsData.length > 0 ? results.docs[results.docs.length - 1] : null
      }
    },
    async fetchClientSettings({commit, dispatch}, id) {
      // commit("SET_SETTINGS", settings)
      return await dispatch("fetchDoc", {
        path: `clients/${id}/settings`,
        id: "configs"
      })
    },
    async fetchSettings({commit, dispatch}) {
      let settings = await dispatch("fetchDoc", {
        path: "settings",
        id: "configs"
      })

      commit("SET_SETTINGS", settings)
      return settings
    },
    async fetchClient({commit, dispatch}, id) {
      return await dispatch("fetchDoc", {
        path: "clients",
        id: id
      })
      // for (let i = 0; i < docs.length; i++) {
      //   docs[i]["clientName"] = docs[i].firstName + " " + docs[i].lastName
      // }

    },
    async fetchEmployee({commit, dispatch}, id) {
      return await dispatch("fetchDoc", {
        path: "employees",
        id: id
      })
      // for (let i = 0; i < docs.length; i++) {
      //   docs[i]["clientName"] = docs[i].firstName + " " + docs[i].lastName
      // }

    },
    async fetchEmployeePrivateInfo({commit, dispatch}, id) {
      return await dispatch("fetchDoc", {
        path: "employees/"+id+"/private",
        id: "info"
      })
    },
    async fetchEmployeePaymentDetails({commit, dispatch}, id) {
      return await dispatch("fetchDoc", {
        path: "employees/"+id+"/private",
        id: "bankDetails"
      })
    },
    async fetchGroupDoc({commit, dispatch}, data) {
      let fireS = getFirestore(fireApp)
      let collectionRef = collectionGroup(fireS, data.path)
      let docsQuery = query(
          collectionRef,
          where("id", "==", data.id),
          limit(1)
      )

      return await getDocs(docsQuery).then((docs) => {


        if (docs.empty){
          return null
        }
        let docData = {}
        docs.forEach((doc) => {
          docData = doc.data()
          docData["id"] = doc.id
          console.log("docsData", docData)
          if(docData.hasOwnProperty("createdAt") && docData.createdAt !== null) {
            docData["createdAt"] = moment(docData["createdAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
        if(docData.hasOwnProperty("updatedAt") && docData.updatedAt !== null) {
            docData["updatedAt"] = moment(docData["updatedAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }

        })

        return docData
      })

    },
    async fetchDoc({commit}, data) {
      let docQuery = getDocReference(
          data.path,
          data.id
      )
      let doc = await getDoc(docQuery)
      if (doc.exists()){
        let docData = doc.data()
        docData.id = doc.id

        if(docData.hasOwnProperty("createdAt") && docData.createdAt !== null){
          docData["createdAt"] = moment(docData["createdAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }
        if(docData.hasOwnProperty("updatedAt") && docData.createdAt !== null){
          docData["updatedAt"] = moment(docData["updatedAt"].toDate()).format(
              "DD/MM/YYYY HH:mm"
          )
        }

        return docData
      } else {
        return null
      }
    },
    async sendPasswordResetEmail({commit}, email) {
      let auth = getAuth(fireApp)
      return await sendPasswordResetEmail(auth,  email)
    },
    async fetchDocs({commit}, data) {
      let fireS = getFirestore(fireApp)
      let collectionRef = collection(fireS, data.path)
      let docsQuery = query(
          collectionRef,
          limit(20),
      )

      if (data.orderBy != null) {
        docsQuery = query(docsQuery, orderBy(data.orderBy, "asc"))
      }
      if (data.lastSnapshot != null){
        console.log("startAfter", data.lastSnapshot)
        docsQuery = query(docsQuery, startAfter(data.lastSnapshot))
      }

      return await getDocs(docsQuery).then((docs) => {
        let docsData = []
        docs.forEach((doc) => {
          let data = doc.data()
          data["id"] = doc.id
          if(data.hasOwnProperty("createdAt") && data.createdAt !== null){
            data["createdAt"] = moment(data["createdAt"].toDate()).format(
                "DD/MM/YYYY HH:mm"
            )
          }
          docsData.push(data)
        })

        return docsData
      })
    },
    // eslint-disable-next-line no-unused-vars
    async deleteDocument({commit}, data) {
      let fireS = getFirestore(fireApp)
      await deleteDoc(doc(fireS, data.path, data.id))

    },
    // eslint-disable-next-line no-unused-vars
    async saveDocs({commit, dispatch}, data) {


      let fireS = getFirestore(fireApp)
      const batch = writeBatch(fireS)

      for (const row of data.items) {

        let docRef
        if(row.id) {
          docRef = doc(fireS, data.path+"/"+ row.id)
        } else {
          docRef = doc(collection(fireS, data.path))
        }

        // eslint-disable-next-line no-prototype-builtins
        if(data.path === "vehicles" && row.hasOwnProperty("file") && row.file !== null){
          console.log("im in")
          const storage = getStorage()
          const vehiclesRef = ref(storage, "vehicles/"+docRef.id + "_" + row.file.name)
          let result = await uploadBytes(vehiclesRef, row.file)
          delete row.file
          let downloadURL = await getDownloadURL(result.ref)
          console.log("uploadBytes result", result)
          row.image = downloadURL
        }



        batch.set(docRef, row)
      }

      if(data.path === "routes") {
          let availableRoutes = data.items.map(val => val.from)
        availableRoutes = availableRoutes.concat(data.items.map(val => val.to))
        availableRoutes = availableRoutes.reduce(function(a,b){if(a.indexOf(b)<0)a.push(b);return a},[])
        await dispatch("setAvailableRoutes",availableRoutes)
      }

      await batch.commit()
    },
    async countCollectionGroupDocs({},data){
      console.log("data", data)
      let fireS = getFirestore(fireApp)
      let collectionRef = collectionGroup(fireS, data.path)
      let docsQuery = query(collectionRef)

      if (data.statuses.length > 0){
        docsQuery = query(docsQuery, where("status", "in", data.statuses))
      }

      if (data.hasOwnProperty("sorting")){
        let sorting = data.sorting
        docsQuery = query(docsQuery, orderBy(sorting.sortBy, sorting.sortDirection))
      }

      return await getCountFromServer(docsQuery).then( res => {
        return res.data().count
      }).catch( error => {
        console.log("getCountFromServer error", error)
        return null
      })

    },
    async countDocs({},data){
      console.log("data", data)
      let fireS = getFirestore(fireApp)
      let collectionRef = collection(fireS, data.path)
      let docsQuery = query(collectionRef)

      return await getCountFromServer(docsQuery).then( res => {
        return res.data().count
      }).catch( error => {
        console.log("getCountFromServer error", error)
        return null
      })

    },

  },
  modules: {},
})

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ")
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}
