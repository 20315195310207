import BaseAlert from "../components/BaseAlert"
import BaseButton from "../components/BaseButton"
import BaseDropdown from "../components/BaseDropdown"
import SubmitButton from "../components/SubmitButton"
import BaseInput from "../components/BaseInput"
import BaseHeader from "../components/BaseHeader"
import BaseTable from "../components/BaseTable"
import BaseNav from "../components/BaseNav"
import Card from "@/components/Card"

const GlobalComponents = {
  install(app) {
    app.component("base-alert", BaseAlert)
    app.component("card", Card)
    app.component("base-button", BaseButton)
    app.component("base-table", BaseTable)
    app.component("base-dropdown", BaseDropdown)
    app.component("base-header", BaseHeader)
    app.component("submit-button", SubmitButton)
    app.component("base-input", BaseInput)
    app.component("base-nav", BaseNav)
  },
}

export default GlobalComponents
