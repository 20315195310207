<template>

  <div>
    <base-header type="gradient-primary"  class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <requests-table v-if="userRole === 'SYSTEM_USER'" :title="$t('Requests List')"></requests-table>
          <client-requests-table v-else-if="userRole === 'CLIENT'"></client-requests-table>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import RequestsTable from "@/views/Tables/RequestsTable"
import BaseHeader from "@/components/BaseHeader"
import ClientRequestsTable from "@/views/Tables/ClientRequestsTable"
export default {
  name: "requests-list",
  components: {
    ClientRequestsTable,
    BaseHeader,
    RequestsTable,
  },
  data() {
    return {
    }
  },
  computed: {
    userRole() {
      return this.$store.state.user.claims.role ?? null
    },
  }

}
</script>
<style></style>
