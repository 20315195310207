<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <h5 v-if="!isUpdate" class="mb-0">New Client</h5>
                      <h5 v-else class="mb-0">Update Client</h5>
                    </div>
                  </div>
                </div>
              </template>
              <form>
                <div class="text-start fw-bolder text-uppercase text-muted">Company details </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.name"
                          alternative=""
                          label="Name"
                          placeholder="Name"
                          input-classes="form-control-alternative"
                          :error="v$.model.name.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label float-start">{{ $t("Contact phone (optional)") }}</label>
                        <base-input
                            v-model="model.phoneNumber"
                            type="number"
                            form-classes="input-group-alternative mb-3 phone-input"
                            style="display: flex;border-radius: 0;"
                            :placeholder="$t('Contact Phone:')"
                            :error="v$.model.phoneNumber.$errors.map( err => { return err.$message })[0]"
                        >
                          <template #addonLeft>
                            <select v-model="model.phoneNumberCode" class="form-control form-control-alternative" style="padding: 0;height: 23px;border: none !important;">
                              <option v-for="country in countryCodes" :key="country.code" :value="country.code"> {{ isWindows ? '' : isoCountryCodeToFlagEmoji(country.iso) }} {{ country.iso }} {{ country.code }}</option>
                            </select>
                          </template>
                        </base-input>
                    </div>
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.legalName"
                          alternative=""
                          :label="$t('Legal name')"
                          :placeholder="$t('Legal name')"
                          input-classes="form-control-alternative"
                      />
                    </div>
                    <div v-if="userRole === 'SYSTEM_USER'" class="col-lg-6">
                      <div class="form-group has-label">
                        <label class="form-control-label">{{ $t("Status") }} </label>
                        <multiselect
                            v-model="model.status"
                            :options="['VERIFIED', 'PENDING', 'DISABLED']"
                            :placeholder="$t('Select status')"
                            :show-labels="false"
                            required
                            class=""
                        >
                          <template #singleLabel="{ option }">{{ $t(option) }}</template>
                          <template #option="props" >
                            {{ $t(props.option) }}
                          </template>
</multiselect>
                      </div>
                    </div>
                  </div>
                  </div>
<!--                <div class="text-start fw-bolder text-uppercase text-muted">Contact details </div>-->
<!--                <hr class="mt-0">-->
<!--                <div class="ps-lg-4">-->
<!--                  <div class="row">-->
<!--&lt;!&ndash;                    <base-input&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="model.contactEmail"&ndash;&gt;-->
<!--&lt;!&ndash;                        alternative=""&ndash;&gt;-->
<!--&lt;!&ndash;                        label="Contact email"&ndash;&gt;-->
<!--&lt;!&ndash;                        placeholder="Contact email"&ndash;&gt;-->
<!--&lt;!&ndash;                        input-classes="form-control-alternative"&ndash;&gt;-->
<!--&lt;!&ndash;                        :error="v$.model.contactEmail.$errors.map( err => { return err.$message })[0]"&ndash;&gt;-->
<!--&lt;!&ndash;                    />&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                      <base-input&ndash;&gt;-->
<!--&lt;!&ndash;                          v-model="model.contactPhone"&ndash;&gt;-->
<!--&lt;!&ndash;                          alternative=""&ndash;&gt;-->
<!--&lt;!&ndash;                          label="Contact phone"&ndash;&gt;-->
<!--&lt;!&ndash;                          placeholder="+357 99887766"&ndash;&gt;-->
<!--&lt;!&ndash;                          input-classes="form-control-alternative"&ndash;&gt;-->
<!--&lt;!&ndash;                      />&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                    <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                      <base-input&ndash;&gt;-->
<!--&lt;!&ndash;                          v-model="model.contactPhone2"&ndash;&gt;-->
<!--&lt;!&ndash;                          alternative=""&ndash;&gt;-->
<!--&lt;!&ndash;                          label="Secondary Contact phone"&ndash;&gt;-->
<!--&lt;!&ndash;                          placeholder="+357 99887766"&ndash;&gt;-->
<!--&lt;!&ndash;                          input-classes="form-control-alternative"&ndash;&gt;-->
<!--&lt;!&ndash;                      />&ndash;&gt;-->
<!--&lt;!&ndash;                    </div>&ndash;&gt;-->

<!--                  </div>-->
<!--                </div>-->
                <div class="text-start fw-bolder text-uppercase text-muted">Address details </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
<div class="row">
                    <div class="col-md-6">
                      <base-input
                          v-model="model.address.street"
                          alternative=""
                          label="Street"
                          placeholder="Home Address"
                          input-classes="form-control-alternative"
                          :error="v$.model.address.street.$errors.map( err => { return err.$message })[0]"
                      />
</div>
                    <div class="col-md-6">
                      <base-input
                          v-model="model.address.street2"
                          alternative=""
                          label="Street 2"
                          placeholder="Home Address 2"
                          input-classes="form-control-alternative"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.address.city.$error }">
                        <label class="form-control-label">{{ $t('City') }}</label>

                        <multiselect
                            v-model="model.address.city"
                            :show-labels="false"
                            deselect-label="Can't remove this value"
                            placeholder="Select city"
                            :options="cities"
                            :searchable="false"
                            :custom-label="customCityLabel"
                        >
                        </multiselect>

                        <div v-if="v$.model.address.city.$error" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.address.city.$errors.map( err => { return err.$message })[0] }}
                        </div>
</div>
                    </div>
                    <div class="col-lg-4">
                      <base-input
                          v-model="country"
                          disabled="true"
                          alternative=""
                          :label="$t('Country')"
                          :placeholder="$t('Country')"
                          input-classes="form-control-alternative"
/>
                    </div>
                    <div class="col-lg-4">
                      <base-input
                          v-model="model.address.postalCode"
                          alternative=""
                          label="Postal code"
                          placeholder="Postal code"
                          input-classes="form-control-alternative"
                          :error="v$.model.address.postalCode.$errors.map( err => { return err.$message })[0]"
/>
                    </div>
                  </div>
                </div>
                <div class="text-start fw-bolder text-uppercase text-muted">Job Positions </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="mb-3 text-start" :class="{'has-danger': v$.model.positionIds.$error }">
                      <multiselect
                          v-model="model.positionIds"
                          :show-labels="false"
                          label="value"
                          :close-on-select="false"
                          track-by="key"
                          :placeholder="$t('Select related positions')"
                          :options="positions" :multiple="true"
                          :loading="positionsLoading"
                          ></multiselect>
<!--                      <multiselect-->
<!--                          v-model="model.destination"-->
<!--                          :options="destinationOptions"-->
<!--                          :placeholder="$t('Select Destination')"-->
<!--                          :show-labels="false"-->
<!--                          label="value"-->
<!--                          track-by="key"-->
<!--                      ></multiselect>-->
                      <div v-if="v$.model.positionIds.$invalid" class="text-danger invalid-feedback" style="display: block;">
                        {{ v$.model.positionIds.$errors.map( err => { return err.$message })[0] }}
                      </div>
                    </div>
</div>
                </div>
                <div class="text-start fw-bolder text-uppercase text-muted">Description </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <base-input alternative="">
                        <textarea
                            v-model="model.description"
                            rows="4"
                            class="form-control form-control-alternative"
                            placeholder="A few words about your work place..."
                        >
                          </textarea>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div v-if="!isUpdate" class="text-start fw-bolder text-uppercase text-muted">Account Owner </div>
                <hr v-if="!isUpdate" class="mt-0">
                <p v-if="!isUpdate" class="text-start text-muted">Here is the details of the administrator. This user will have full access to the company</p>
                <div v-if="!isUpdate" class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.user.firstName"
                          alternative=""
                          label="First name"
                          placeholder="First name"
                          input-classes="form-control-alternative"
                          :error="v$.model.user.firstName.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.user.lastName"
                          alternative=""
                          label="Last name"
                          placeholder="Last name"
                          input-classes="form-control-alternative"
                          :error="v$.model.user.lastName.$errors.map( err => { return err.$message })[0]"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <label class="form-control-label float-start">{{ $t("Contact phone (optional)") }}</label>
                      <base-input
                          v-model="model.user.contactPhone"
                          type="number"
                          form-classes="input-group-alternative mb-3 phone-input"
                          style="display: flex;border-radius: 0;"
                          :placeholder="$t('Contact Phone:')"
                          :error="v$.model.user.contactPhone.$errors.map( err => { return err.$message })[0]"
                      >
                        <template #addonLeft>
                          <select v-model="model.user.contactPhoneCode" class="form-control form-control-alternative" style="padding: 0;height: 23px;border: none !important;">
                            <option v-for="country in countryCodes" :key="country.code" :value="country.code"> {{ isWindows ? '' : isoCountryCodeToFlagEmoji(country.iso) }} {{ country.iso }} {{ country.code }}</option>
                          </select>
                        </template>
                      </base-input>
                    </div>
                    <div class="col-lg-6 text-start">
                        <label class="form-control-label ">{{ $t("Preferred language") }} </label>
                        <multiselect
                            v-model="model.user.lang"
                            :options="['en','el']"
                            :placeholder="$t('Select language')"
                            :show-labels="false"
                            required
                            class=""
                        >
                          <template #singleLabel="{ option }">{{ $t(option) }}</template>
                          <template #option="props" >
                            {{ $t(props.option) }}
                          </template>
                        </multiselect>

                    </div>
</div>
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.user.email"
                          alternative=""
                          label="Email address"
                          placeholder="Email address"
                          input-classes="form-control-alternative"
                          :error="v$.model.user.email.$errors.map( err => { return err.$message })[0]"
                      >
                        <template #infoBlock>
                          <span class="text-sm text-muted">Will be used for authentication.</span>
                        </template>
                      </base-input>
</div>
                    <div class="col-lg-6">
                      <base-input
                          v-model="model.user.password"
                          alternative=""
                          :label="$t('Password')"
                          :placeholder="$t('Password')"
                          input-classes="form-control-alternative"
                          :error="v$.model.user.password.$errors.map( err => { return err.$message })[0]"
                      >
                      </base-input>
</div>
                  </div>
                </div>
                <div class="col-12 text-end">
                  <base-button v-if="!isUpdate" class="me-2" outline type="info" @click="clearForm">Reset</base-button>
                  <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submitClient">{{ isUpdate ? 'Update' : 'Submit' }}</submit-button>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {helpers, required, email, requiredIf, minLength, maxLength} from "@vuelidate/validators"
import {isValidNumber, getCountries, getCountryCallingCode, parsePhoneNumber} from "libphonenumber-js"
import {isoCountryCodeToFlagEmoji, isWindows} from "@/helpers/common"
const isValidPhone = (param) => helpers.withParams(
    { type: "isValidPhone", value: param }, (value) => {

  if (value == null || value === ""){
    return true
  }
  console.log("param+value", param+value)
  return isValidNumber(param+value)
})

export default {
  name: "newClient",
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isWindows,
      countryCodes: [],
      positions: [],
      isUpdate: false,
      positionsLoading: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      cities: ["LIMASSOL", "NICOSIA", "LARNACA", "PAPHOS", "FAMAGUSTA"],
      country: "Cyprus",
      model: {
        name: "",
        phoneNumber: null,
        phoneNumberCode: "+357",
        status: "VERIFIED",
        positionIds: [],
        description: "",
        // registrationNumber: "",
        user: {
          lang: "en",
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          contactPhoneCode: "+357",
          contactPhone: null,
        },
        address: {
          street: "",
          city: "",
          lat: 0,
          long: 0 ,
          postalCode: "",
          street2: "",
        },
      },
    }
  },
  validations () {
    return {
      model: {
        address: {
          street: {
            required: helpers.withMessage(this.$t("Value is required"), required)
          },
          city: {
            required: helpers.withMessage(this.$t("Value is required"), required)
          },
          postalCode: {
            required: helpers.withMessage(this.$t("Value is required"), required),
            minLength: minLength(4),
            maxLength: maxLength(4),
          },
        },
        phoneNumber: {
          validated: helpers.withMessage(this.$t("Must be valid phone number."), isValidPhone(this.model.phoneNumberCode))
        },
        positionIds: {
          requiredAtLeastOne: helpers.withMessage(this.$t("You need to select at least one."), required)
        },
        name: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        user: {
          firstName: {
            requiredIdNotUpdate: requiredIf(!this.isUpdate)
          },
          lastName: {
            requiredIdNotUpdate: requiredIf(!this.isUpdate)
          },
          contactPhone: {
            validated: helpers.withMessage(this.$t("Must be valid phone number."), isValidPhone(this.getValueOrDefault(this.model.user ?? {}, "contactPhoneCode", null )))
          },
          email: {
            email: email,
            requiredIdNotUpdate: requiredIf(!this.isUpdate)
          },
          password: {
            requiredIdNotUpdate: requiredIf(!this.isUpdate),
            minLength: minLength(6)
          }
        }

      },
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    }
  },
  async mounted() {
    await this.getPositions()
    let clientId
    if (this.userRole === "SYSTEM_USER" && this.$route.params.hasOwnProperty("id")) {
      clientId = this.$route.params.id
    } else if (this.userRole === "CLIENT") {
      clientId = this.$store.state.user.claims.clientId
    }
    if (clientId) {
      this.isUpdate = true
      await this.getClient(clientId)
    }
    let countries = getCountries()

    countries.forEach(country => {
      let code = getCountryCallingCode(country)
      this.countryCodes.push({code: "+" + code, text: country + " +" + code, iso: country})
    })
  },
  methods: {
    isoCountryCodeToFlagEmoji,
    async getPositions() {
      this.positionsLoading = true
      await this.$store.dispatch("fetchAllPositions").then((data) => {
        this.positionsLoading = false
        this.positions = data.map( p => { return {key: p.id, value: p.name}})

      })
    },
    customCityLabel (option) {
      return this.$t(option)
    },
    async getClient(id) {
      this.model = await this.$store.dispatch("fetchClient", id)

      if (this.model.phoneNumber !== null) {
        let phoneDetails = parsePhoneNumber(this.model.phoneNumber)
        this.model.phoneNumber = phoneDetails.nationalNumber ?? null
        this.model.phoneNumberCode = "+" + (phoneDetails.countryCallingCode ?? "357")
      }

      let selectedPositions = []
      this.model.positionIds.forEach( positionsId => {
        let selected = this.positions.find(pos => pos.key === positionsId )
        if (selected) {
          selectedPositions.push(selected)
        }
      })


      this.model.positionIds = selectedPositions
      console.log("model", this.model)
    },
    async submitClient() {
      let self = this

      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      let positionsArray = []
      for (let i = 0; i < this.model.positionIds.length; i++) {
        positionsArray.push(this.model.positionIds[i].key)
      }

      let payload = {}
      if (this.isUpdate) {
        payload = {
          legalName: this.model.legalName,
          clientId: this.model.id,
          name: this.model.name,
          status: this.model.status,
          positionIds: positionsArray,
          description: this.model.description,
          address: {
            countryCode: "CY",
            city: this.model.address.city,
            street: this.model.address.street,
            street2: this.model.address.street2,
            postalCode: this.model.address.postalCode
          },
        }
      } else {
        payload = {
          name: this.model.name,
          legalName: this.model.legalName,
          status: this.model.status,
          positionIds: positionsArray,
          description: this.model.description,
          address: {
            countryCode: "CY",
            city: this.model.address.city,
            street: this.model.address.street,
            street2: this.model.address.street2,
            postalCode: this.model.address.postalCode
          },
          user: {
            lang: this.model.user.lang,
            firstName: this.model.user.firstName,
            lastName: this.model.user.lastName,
            email: this.model.user.email,
            password: this.model.user.password,
          }
        }
        if (this.model.user.contactPhone != null){
          payload.user.contactPhone  = this.model.phoneNumberCode + this.model.phoneNumber
        }
      }

      if (this.model.phoneNumber != null && this.model.phoneNumber !== ""){
        console.log("this.model.phoneNumber INNN")
        payload.phoneNumber = this.model.phoneNumberCode + this.model.phoneNumber
      }
      console.log("payload", payload)

      this.loadingProcess = "processing"

      FirebaseFunctionsHelper.callFunction(
          this.isUpdate ? "client-update" : "client-create",
          // eslint-disable-next-line no-undef
         payload
      )
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              self.loadingProcess = "failed"
              self.errorMessage = result.message
              return
            }
            console.log("submitUser result", result)
            this.loadingProcess = "done"

            if (!this.isUpdate) {
              this.$router.push({ path: "/clients/"+result+"/info" })
            } else {
              if (this.userRole === "CLIENT"){
                this.$router.push({ path: "/account/info"})
                return
              }
              this.$router.push({ path: "/clients/"+this.$route.params.id + "/info"})
            }

          })
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      this.loadingProcess = ""
      if (this.dbUser){
        this.model = this.dbUser
      } else {
        this.model = {
          name: "",
          phoneNumber: null,
          phoneNumberCode: "+357",
          status: "VERIFIED",
          positionIds: [],
          description: "",
          // registrationNumber: "",
          user: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            contactPhoneCode: "+357",
            contactPhone: null,
          },
          address: {
            street: "",
            country: "Cyprus",
            city: "",
            lat: 0,
            long: 0 ,
            postalCode: "",
            street2: "",
          }
        }
      }
    },
    getValueOrDefault(obj, prop, defaultValue){
      return Object.prototype.hasOwnProperty.call(obj, prop) ? (obj[prop] || defaultValue) : defaultValue
    }
  },
}
</script>
<style scoped>
.form-group.has-label {
  padding-top: 0.5rem !important;
}
</style>
