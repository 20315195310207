<template>
  <requests-search-table :columns="columns"></requests-search-table>
</template>

<script>
import RequestsSearchTable from "@/views/RequestSearch/RequestsSearchTable"
export default {
  name: "my-requests",
  components: {RequestsSearchTable},
  data() {
    return {
      columns: [
        {
          key:"Ref",
          label:"Ref"
        },
        {
          key:"Client",
          label:"Client"
        },
        {
          key:"Position",
          label:"Position"
        },
        {
          key:"status",
          label:"Status"
        },
        {
          key: "cost",
          label: "Cost"
        },
        {
          key: "deadline",
          label:"Deadline",
        },
        {
          key: "startDate",
          label:"Start Date",
          sorting: true
        },
        // {
        //   key: "createdAt",
        //   label:"Created at",
        // },
        {
          key: "",
          label:"",
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>