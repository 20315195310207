<template>
  <div
    v-if="visible"
    class="alert mt-3"
    :class="[`alert-${type}`, { 'alert-dismissible': dismissible }, { 'w-100': fullWidth}]"
    role="alert"
    aria-label="alert"
  >
    <slot v-if="!dismissible">
      <span v-if="icon" class="alert-inner--icon">
        <i :class="icon"></i>
      </span>
      <span v-if="$slots.text" class="alert-inner--text">
        <slot name="text"></slot>
      </span>
    </slot>
    <template v-else>
      <slot>
        <span v-if="icon" class="alert-inner--icon">
          <i :class="icon"></i>
        </span>
        <span v-if="$slots.text" class="alert-inner--text">
          <slot name="text"></slot>
        </span>
      </slot>
      <slot name="dismiss-icon">
        <button
          type="button"
          data-dismiss="alert"
          aria-label="Close"
          class="btn close"
          @click="dismissAlert"
        >
          <span aria-hidden="true"><i class="fa fa-times"></i></span>
        </button>
      </slot>
    </template>
  </div>
</template>
<script>
export default {
  name: "base-alert",
  props: {
    type: {
      type: String,
      default: "default",
      description: "Alert type",
    },
    fullWidth: {
      type: Boolean,
      default: false,
      description: "if it will be full width",
    },
    icon: {
      type: String,
      default: "fa fa-times",
      description: "Alert icon. Will be overwritten by default slot",
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: "Whether alert is closes when clicking",
    },
  },
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    dismissAlert() {
      this.visible = false
    },
  },
}
</script>
