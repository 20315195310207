<template>
<div>
  <requests-table :title="$t('Requests')"></requests-table>
</div>
</template>

<script>
import RequestsTable from "@/views/Tables/RequestsTable"
export default {
  name: "Requests",
  components: {RequestsTable}
}
</script>

<style scoped>

</style>