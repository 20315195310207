<template>
  <div>
    <section id="whatWeDo" class="section section-lg section-shaped my-0">
      <div class="shape shape-style-3 bg-primary shape-skew"><span></span><span></span><span></span><span></span></div>
      <div class="container pt-lg pb-300">
        <div class="row text-center justify-content-center">
          <div class="col-lg-10">
            <h2 class="display-3 text-white">{{ $t("Benefits of joining us?") }}</h2>
            <p class="lead text-white">
            </p>
          </div>
        </div>
        <div class="row row-grid mt-5 justify-content-center">
          <div class="col-lg-4 p-3 box">
            <div class="icon icon-shape icon-lg bg-white shadow text-white"><i
                class="fa fa-money-bill-trend-up text-primary"></i></div>
            <h5 class="text-white mt-3">{{ $t("High pay rates") }}</h5>
            <p class="text-white mt-3">{{ $t("You improve your income substantially.") }}
              <br>
              <a href="#shift-rates" class="text-white text-decoration-underline">
                {{ $t("See the rates") }}
              </a>
            </p>
          </div>
          <div class="col-lg-4 p-3 box">
            <div class="icon icon-shape icon-lg bg-white shadow text-white">
              <i class="fa fa-money-bill text-primary"></i>
            </div>
            <h5 class="text-white mt-3">{{ $t("Easy payments") }}</h5>
            <p class="text-white mt-3">{{ $t("You don’t have to wait for the salary day to cover unexpected costs.") }}</p>
          </div>
          <div class="col-lg-4 p-3 box">
            <div class="icon icon-shape icon-lg bg-white shadow text-white" ><i
                class="fa fa-user-clock text-primary"></i></div>
            <h5 class="text-white mt-3">{{ $t("Flexible working hours") }}</h5>
            <p class="text-white mt-3">{{ $t("You have the flexibility to choose when a shift suits you.") }} </p>
          </div>
          <div class="col-lg-4 p-3 box">
            <div class="icon icon-shape icon-lg bg-white shadow text-white"><i
                class="fa fa-network-wired text-primary"></i></div>
            <h5 class="text-white mt-3">{{ $t("Book and manage your shifts online") }}</h5>
            <p class="text-white mt-3">{{ $t("You can travel and work at the same time.") }}</p>
          </div>
          <div class="col-lg-4 p-3 box">
            <div class="icon icon-shape icon-lg bg-white shadow text-white">
              <i class="fa fa-atom text-primary"></i>
            </div>
            <h5 class="text-white mt-3">{{ $t("Access to new challenges") }}</h5>
            <p class="text-white mt-3">{{ $t("You can explore possible professional job destinations.") }}</p>
          </div>
        </div>
      </div>
    </section>
    <section id="contact-form" class="section section-lg pt-lg-0 section-contact-us">
      <div class="container">
        <div class="row justify-content-center mt--300">
          <div class="col-lg-8">
            <div class="card shadow bg-gradient-secondary">
              <div class="card-body p-lg-5"><h4 class="mb-1">{{ $t("Want to work with us?") }}</h4>
                <p class="mt-2">{{ $t("We are here to talk.") }} <br> {{ $t("Let us know and we will contact you as soon as possible") }}</p>
                <div class="form-group mt-5 input-group input-group-alternative" :class="[{ 'has-danger': v$.model.name.$error }]">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="fa fa-user"></i></span>
                  </div>
                  <input v-model="model.name" aria-describedby="addon-right addon-left" :placeholder="$t('Your name')" class="form-control">
                  <div v-if="v$.model.name.$invalid" class="text-danger invalid-feedback" style="display: block;">
                    {{ v$.model.name.$errors.map( err => { return err.$message })[0] }}
                  </div>
                </div>

                  <div class="form-group input-group input-group-alternative">

                    <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-phone"></i>
                    </span>
                    </div>
                    <base-input
                        aria-describedby="addon-right addon-left"
                        v-model="model.phoneNumber"
                        type="number"
                        :form-classes="[{ 'has-danger': v$.model.phoneNumber.$error, 'form-control phone-input m-0 p-0': true }]"
                        style="display: flex;border-radius: 0;"
                        :placeholder="$t('Phone Number')"
                    >
                      <template #addonLeft>
                        <select v-model="model.phoneNumberCode" class="form-control form-control-alternative" style="padding: 0;height: 23px;border: none !important;">
                          <option v-for="country in countryCodes" :key="country.code" :value="country.code"> {{ isWindows ? '' : isoCountryCodeToFlagEmoji(country.iso) }} {{ country.iso }} {{ country.code }}</option>
                        </select>
                      </template>
                    </base-input>
                    <div v-if="v$.model.phoneNumber.$invalid" class="text-danger invalid-feedback" style="display: block;">
                      {{ v$.model.phoneNumber.$errors.map( err => { return err.$message })[0] }}
                    </div>
                  </div>

                <div class="form-group input-group input-group-alternative" :class="[{ 'has-danger': v$.model.email.$error }]">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="fa fa-envelope"></i>
                    </span>
                  </div>
                  <input v-model="model.email" aria-describedby="addon-right addon-left" :placeholder="$t('Email address')" class="form-control">
                  <div v-if="v$.model.email.$invalid" class="text-danger invalid-feedback" style="display: block;">
                    {{ v$.model.email.$errors.map( err => { return err.$message })[0] }}
                  </div>
                </div>

                <div class="form-group input-group input-group-alternative" :class="[{ 'has-danger': v$.model.type.$error }]">
                  <div class="input-group-prepend custom-transition-form">
                    <span class="input-group-text">
                      <i class="fa fa-user-doctor"></i>
                    </span>
                  </div>
                  <div  class="form-control p-0">
                  <multiselect v-model="model.type" class="custom-transition-form" :placeholder="$t('What are you looking for?')" :options="[ $t('Looking to hire Nurses'), $t('I am a certified nurse and I want to get more shifts')]"></multiselect>
                  </div>
                  <div v-if="v$.model.type.$invalid" class="text-danger invalid-feedback" style="display: block;">
                    {{ v$.model.type.$errors.map( err => { return err.$message })[0] }}
                  </div>
                </div>
                <div class="form-group mb-4">
                  <textarea v-model="model.description" name="description" rows="4" cols="80" :placeholder="$t('Type a message...')"
                            class="form-control form-control-alternative">

                  </textarea>
                  </div>
                <submit-button :alert-message="errorMessage"
                :state="loadingProcess" type="button" class="btn btn-primary btn-lg text-white" @clicked="submitForm">
                  {{ $t("Send message") }}
                </submit-button>
              </div></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import {email, helpers, required} from "@vuelidate/validators"


import SubmitButton from "@/components/SubmitButton"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import {isWindows} from "@/helpers/common"
import {getCountries, getCountryCallingCode, isValidNumber} from "libphonenumber-js"
import BaseInput from "@/components/BaseInput"
const isValidPhone = (param) => helpers.withParams(
    { type: "isValidPhone", value: param }, (value) => {

      if (value == null || value === ""){
        return true
      }
      console.log("param+value", param+value)
      return isValidNumber(param+value)
    })
export default {
  name: "ContactUs",
  components: {BaseInput, SubmitButton},
  setup: () => ({ v$: useVuelidate() }),
  data: () => {
    return {
      countryCodes: [],
      isWindows,
      loadingProcess: "",
      errorMessage: "",
      model: {
        name: null,
        email: null,
        type: null,
        description: "",
        phoneNumber: "",
        phoneNumberCode: "+357",
      }
    }
  },
  validations () {
    return {
      model: {
        name: {
          required
        },
        email: {
          required, email
        },
        type: {
          required
        },
        phoneNumber: {
          required,
          validated: helpers.withMessage(this.$t("Must be valid phone number."), isValidPhone(this.model.phoneNumberCode))
        },
      }
    }
  },
  mounted() {
    let countries = getCountries()

    countries.forEach( country => {
      let code = getCountryCallingCode(country)
      this.countryCodes.push({code: "+" + code, text: country + " +" + code, iso: country})
    })
  },
  methods: {
    isoCountryCodeToFlagEmoji(country) {
      return String.fromCodePoint(...[...country.toUpperCase()].map(c => c.charCodeAt() + 0x1F1A5))
    },
    submitForm() {
      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }
      this.loadingProcess = "processing"

      // const mail = {
      //   data: {
      //     sendFromName: "All Care",
      //     content: "<b>User:</b> " +
      //         this.model.name +
      //         "<br>" +
      //         "<b>Email:</b> " +
      //         this.model.email +
      //         "<br>" +
      //         "<b>Type:</b> " +
      //         this.model.type +
      //         "<br>" +
      //         "<b>Description:</b> " +
      //         this.model.description,
      //     title: "All Care - Contact form ",
      //     sendTo: "m.yiannakou@allcare.com.cy"
      //   }
      // }

      // axios.post("https://europe-west3-appoint-me-233515.cloudfunctions.net/sendEmail", mail)
      //     .then(response => {
      //       console.log("resppp", response.data.id)
      //           this.loadingProcess = "done"
      //           this.model.name = null
      //           this.model.email = null
      //           this.model.type = null
      //           this.model.description = null
      //           this.v$.$reset()
      //           this.$swal.fire({
      //             toast: true,
      //             position: "top-end",
      //             showConfirmButton: false,
      //             timer: 3000,
      //             timerProgressBar: true,
      //             icon: "success",
      //             title: this.$t("Thank you for contacting us!"),
      //             didOpen: (toast) => {
      //               toast.addEventListener("mouseenter", this.$swal.stopTimer)
      //               toast.addEventListener("mouseleave", this.$swal.resumeTimer)
      //             }
      //           })
      //     })
      // .catch(error => {
      //         this.loadingProcess = "failed"
      //         this.errorMessage = error.message
      //         console.error(error)
      // })

      // MailService.instance.sendMail(mail)

      // sendMail
      FirebaseFunctionsHelper.callFunction("settings-contactForm", {
        name: this.model.name,
        subject: this.model.type,
        message: this.model.description == "" ? "No message" : this.model.description,
        email: this.model.email,
        phoneNumber: this.model.phoneNumberCode + this.model.phoneNumber
      }).then((data) => {
        // console.log("dataa", data)
        if (data === null) {
          this.loadingProcess = "done"
          this.model.name = null
          this.model.email = null
          this.model.type = null
          this.model.description = ""
          this.v$.$reset()
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: this.$t("Thank you for contacting us!"),
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer)
              toast.addEventListener("mouseleave", this.$swal.resumeTimer)
            }
          })
        } else {
          this.loadingProcess = "failed"
          this.errorMessage = this.$t(data.message)
        }
      }).catch((error) => {
            this.loadingProcess = "failed"
            this.errorMessage = error.message
            console.error(error)
          })
    }
  }
}
</script>

<style>
@media (min-width: 992px) {
  .pb-300 {
    padding-bottom: 300px !important;
  }
}

  @media (min-width: 992px) {
    .mt--300 {
      margin-top: -300px !important;
    }
  }

.custom-transition-form {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow 0.15s ease;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.custom-transition-form .multiselect__tags {
  border: none !important;
}

.col-lg-4.box {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.col-lg-4.box:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  /*transition: box-shadow,transform, background 0.3s ease-in-out;*/
  background: #0e799a;
  -webkit-transform: scale(1.15, 1.15);
  transform: scale(1.15, 1.15);
}
.box .icon i {
  font-size: 2.3rem;
}
.form-group.input-group.form-control.phone-input.m-0.p-0 .input-group-prepend {
  border-radius: 0;
}
</style>


