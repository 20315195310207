<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <h5 v-if="!isUpdate" class="mb-0">{{ $t("New Request") }}</h5>
                      <h5 v-else class="mb-0">Update Request</h5>
                    </div>
                  </div>
                </div>
              </template>
              <form>
<!--                <div class="text-start fw-bolder text-uppercase text-muted">Personal Details </div>-->
<!--                <hr class="mt-0">-->
                <div class="ps-lg-4">
                  <div class="row">
                    <div v-if="userRole === 'SYSTEM_USER'" class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.clientId.$error }">
                        <label class="form-label">{{ $t("Select Client") }}</label>
                        <multiselect
                            v-model="model.clientId"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            :placeholder="$t('Select Client')"
                            :options="clients" :multiple="false"
                            :error="v$.model.clientId.$errors.map( err => { return err.$message })[0]"

                        ></multiselect>
                        <div v-if="v$.model.clientId.$invalid" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.clientId.$errors.map( err => { return err.$message })[0] }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.position.$error }">
                        <label class="form-label">{{ $t("Job position") }}</label>
                        <multiselect
                            v-model="model.position"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            :placeholder="$t('Select positions')"
                            :options="positions" :multiple="false"
                            :error="v$.model.position.$errors.map( err => { return err.$message })[0]"

                        ></multiselect>
                        <div v-if="v$.model.position.$invalid" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.position.$errors.map( err => { return err.$message })[0] }}
                        </div>
                      </div>
                    </div>

                    </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.startDate.$error }">
                        <label class="form-label">{{ $t("Shift start") }}</label>
                      <VueDatePicker
                          v-model="model.startDate"
                          :start-time="{ hours: 10, minutes: 0 }"
                          minutes-increment="10"
                          minutes-grid-increment="10"
                          format="dd/MM/yyyy HH:mm"
                          :flow="['calendar', 'time']"
                          :placeholder="$t('Shift start')"
                          :min-date="userRole === 'SYSTEM_USER' ? null : $moment().add(1, 'day').format()"
                          :min-time="userRole === 'SYSTEM_USER' ? null : $moment().add(1, 'day').format()"
                      ></VueDatePicker>
                        <div v-if="v$.model.startDate.$invalid" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.startDate.$errors.map( err => { return err.$message })[0] }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.endDate.$error }">
                        <label class="form-label">{{ $t("Shift end") }}</label>
                      <VueDatePicker
                          v-model="model.endDate"
                          :start-time="{ hours: 10, minutes: 0 }"
                          minutes-increment="10"
                          minutes-grid-increment="10"
                          format="dd/MM/yyyy HH:mm"
                          :flow="['calendar', 'time']"
                          :placeholder="$t('Shift end')"
                          :min-date="$moment(model.startDate).format()"
                      ></VueDatePicker>
                        <div v-if="v$.model.endDate.$invalid" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.endDate.$errors.map( err => { return err.$message })[0] }}
                        </div>
                        </div>
                    </div>
                    <div v-if="model.startDate > new Date()" class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.deadline.$error }">
                        <label class="form-label">{{ $t("Claim deadline") }}*</label>
                        <VueDatePicker
                            v-model="model.deadline"
                            :start-time="{ hours: 10, minutes: 0 }"
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            format="dd/MM/yyyy HH:mm"
                            :flow="['calendar', 'time']"
                            :placeholder="$t('Shift deadline')"
                            :min-date="$moment().format()"
                            :max-date="$moment(model.startDate).format()"
                        ></VueDatePicker>

                        <div v-if="v$.model.deadline.$invalid" class="text-danger invalid-feedback" style="display: block;">
                          {{ v$.model.deadline.$errors.map( err => { return err.$message })[0] }}
                        </div>
                      </div>
                    </div>
                    <div v-if="model.startDate > new Date()" class="col-md-12">
                      <div class="text-start pt-0 pb-4 text-black-50">
                        <label><b>* {{ $t("Claim deadline") }}</b> {{ $t("is the latest time by which a request can be claimed.") }}</label>
                      </div>
                    </div>
                  <div v-if="model.startDate < new Date() && userRole === 'SYSTEM_USER'" class="col-md-12">
                    <div class="form-group has-label" :class="{'has-danger': v$.model.claimedBy.$error }">
                      <label class="form-label">{{ $t("Partner to assign shift") }}</label>
                      <multiselect
                          v-model="model.claimedBy"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                          :placeholder="$t('Select Partner')"
                          :options="employees" :multiple="false"
                      ></multiselect>
                      <div v-if="v$.model.claimedBy.$invalid" class="text-danger invalid-feedback" style="display: block;">
                        {{ v$.model.claimedBy.$errors.map( err => { return err.$message })[0] }}
                      </div>
                    </div>
                  </div>
<!--                    <div class="col-lg-6">-->
<!--                      <div class="form-group has-label">-->
<!--                        <label class="form-label">{{ $t("Status") }}</label>-->
<!--                        <multiselect-->
<!--                            v-model="model.requestStatus"-->
<!--                            :show-labels="false"-->
<!--                            label="name"-->
<!--                            track-by="id"-->
<!--                            :placeholder="$t('Select Status')"-->
<!--                            :options="[{id: 'PENDING', name: $t('Pending')}, {id: 'VERIFIED', name: $t('Verified')}]" :multiple="false"-->
<!--                        ></multiselect>-->

<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>

                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Request Cost") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <label v-if="!requestCost" class="text-start float-start">{{ $t("Cost cannot be calculated yet.") }}</label>
                    <label v-if="userRole === 'CLIENT' && requestCost" class="text-start float-start fw-bold">
                      {{ $t("Total need to be paid directly to the Person who will fulfill this shift.") }}
                    </label>
                    <div class="col-md-4 pt-2">
                      <display-label
                          label="Total cost:"
                          :value="model.cost.totalCost == null ? '€' : model.cost.totalCost/100+'€'"
                      ></display-label>
                    </div>
                    <div v-if="userRole !== 'CLIENT'" class="col-md-4 pt-2">
                      <display-label
                          label="Employee earnings:"
                          :value="model.cost.employeeEarnings == null ? '€' : model.cost.employeeEarnings/100+'€'"
                      ></display-label>
                    </div>
                    <div v-if="userRole !== 'CLIENT'" class="col-md-4 pt-2">
                      <display-label
                          label="Company commission:"
                          :value="model.cost.companyCommission == null ? '€' : model.cost.companyCommission/100+'€'"
                      ></display-label>
                    </div>
                  </div>
                </div>
<!--                <div class="text-start fw-bolder text-uppercase text-muted">Documents </div>-->
<!--                <hr class="mt-0">-->
<!--                <div class="ps-lg-4">-->
<!--                  <div class="row"></div>-->
<!--                </div>-->
                <div class="col-12 text-end">
                  <base-button v-if="!isUpdate" class="me-2" outline type="info" @click="clearForm">{{ $t("Reset") }}</base-button>
                  <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submit">{{ $t(isUpdate ? 'Update' : 'Submit') }}</submit-button>
                </div>
              </form>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {helpers, maxValue, minValue, required, requiredIf} from "@vuelidate/validators"
import DisplayLabel from "@/components/DisplayLabel"
import {calculateShiftCost} from "@/helpers/shiftCostCalculator"
import {toCents} from "@/helpers/common"
//
// const maxIfNotEmpty = (param) => helpers.withParams(
//     { type: "maxIfNotEmpty", value: param }, (value) => {
//
//       if (param == null || param === "" || value == null || value === ""){
//         return true
//       }
//
//       return param < value
//     })
export default {
  name: "newRequest",
  components: {DisplayLabel},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      clientConfigs: null,
      refreshKey: 1,
      positions: [],
      clients: [],
      employees: [],
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      model: {
        // requestStatus: {id: "PENDING", name: this.$t("Pending")},
        clientId: null,
        claimedBy: null,
        deadline: null,
        startDate: null,
        endDate: null,
        position: null,
        cost: {
          totalCost: null,
          employeeEarnings: null,
          companyCommission: null,
        }
      }

    }
  },
  validations () {
    return {
      model: {
        clientId: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        deadline: {
          required: helpers.withMessage(this.$t("Value is required"), required),
          maxValue: helpers.withMessage(this.$t("Deadline must be equal or before Shift start time."), maxValue(this.model.startDate))
        },
        startDate: {
          required: helpers.withMessage(this.$t("Value is required"), required),
         // maxValue: maxValue(this.model.endDate),
         // maxIfNotEmpty: helpers.withMessage("The maximum value allowed is "+ this.$moment(this.model.endDate).format(), maxIfNotEmpty(this.model.endDate))
        },
        endDate: {
          required: helpers.withMessage(this.$t("Value is required"), required),
          minValue: minValue(this.model.startDate),
        },
        position: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        claimedBy: {
          requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.startDate < new Date()))
        }
      },
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
    requestCost(){
      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return false
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.model.cost = calculateShiftCost(this.model.position, this.clientConfigs, this.model.startDate, this.model.endDate)
      return true
    }
  },
  watch: {
    "model.startDate": function (newValue){
      console.log("newValue", newValue)

      if (newValue !== null && newValue < new Date()){
        this.getEmployees()
      }

      if (this.model.endDate == null){
        this.model.endDate = this.$moment(newValue).add("8", "hours").toDate()
      }
      if (this.model.deadline == null){
        if (this.$moment(newValue).diff(this.$moment(), "days") > 1){
          this.model.deadline = this.$moment(newValue).add(-1, "day").toDate()
        } else if (this.$moment(newValue).diff(this.$moment(), "hours") > 1){
          this.model.deadline = this.$moment(newValue).add(-1, "hour").toDate()
        } else {
          this.model.deadline = this.$moment(newValue).add(-1, "hour").toDate()
        }

      }
    },
    "model.clientId": async function (client) {
      console.log("model.clientId", client)
      if (client) {
        await this.getSettings(client.id)
      }
    },
  },
  async mounted() {
    console.log("this.$store.state.user.claims", this.$store.state.user.claims)
    await this.getPositions()
    if (this.userRole === "SYSTEM_USER"){
      await this.getClients()
    } else {
      await this.getSettings(this.$store.state.user.claims.clientId)
    }
    if (this.$route.params.hasOwnProperty("id")) {
      this.isUpdate = true
      await this.getRequest()
    }
  },
  methods:{
    async getEmployees(){
      if (this.employees.length === 0) {
        await this.$store.dispatch("fetchEmployees", {limit: 200}).then((data) => {
          console.log("fetchEmployees", data)
          this.employees = data.data
        })
      }
    },
    async getSettings(id) {
      const clientSettings = await this.$store.dispatch("fetchClient", id)
      console.log("clientSettings id", id)
      console.log("clientSettings", clientSettings)
      if (!clientSettings.hasOwnProperty("configs") || clientSettings.configs == null) {
        this.clientConfigs = await this.$store.dispatch("fetchSettings")
      } else {
        this.clientConfigs = clientSettings.configs
      }
    },
    async getClients() {
      await this.$store.dispatch("fetchClients").then((data) => {
        // console.log("fetchClients", data)
        this.clients = data
      })
    },
    async getPositions() {
      this.positions = []
      let allPositions = await this.$store.dispatch("fetchAllPositions")
      if (this.userRole === "SYSTEM_USER") {
          this.positions = allPositions
      } else {
        await this.$store.dispatch("fetchClient", this.$store.state.user.claims.clientId).then((data) => {
          console.log("fetchClient fetchClient", data)
          if (data.hasOwnProperty("name")) {
            this.model.clientId = {id: this.$store.state.user.claims.clientId, name: data.name}
          }
          if (data.hasOwnProperty("positionIds")){
            data.positionIds.forEach( p => {

              let selected = allPositions.find(pos => pos.id === p)

              console.log("allPositions selected", selected)
              if (selected ?? false) {
                console.log("allPositions selected got it")
                this.positions.push(selected)
              }
            })
          }
        })
      }
    },
    customCityLabel (option) {
      return this.$t(option)
    },
    async getRequest() {
      const data = await this.$store.dispatch("fetchRequest", this.$route.params.id)

      let selectedPositions = []
      data.forEach( positionsId => {
        let selected = this.positions.find(pos => pos.key === positionsId )
        if (selected) {
          selectedPositions.push(selected)
        }
      })

      this.model = data
      this.model.positionIds = selectedPositions
    },
    async submit() {
      let self = this

      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      let request = JSON.parse(JSON.stringify(this.model))

      request.positionId = request.position.id
      if (this.model.startDate < new Date() && this.userRole === "SYSTEM_USER") {
        request.claimedBy = request.claimedBy.id
      }

      delete request.position
      request.clientId = request.clientId.id
      const payload = {
        clientId: request.clientId,
        requests: [request]
      }

      // payload.requestStatus = payload.requestStatus.id

      console.log("payload", payload)

      this.loadingProcess = "processing"
      FirebaseFunctionsHelper.callFunction(
          "request-create",
          // eslint-disable-next-line no-undef
          payload
      )
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              self.loadingProcess = "failed"
              self.errorMessage = result.message
              return
            }
            console.log("submitUser result", result)
            this.loadingProcess = "done"
            this.$router.push({path: "/requests/"+result})

          })
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      this.loadingProcess = ""
      this.model = {
        clientId: null,
        deadline: null,
        startDate: null,
        endDate: null,
        position: null,
        cost: {
          totalCost: null,
          employeeEarnings: null,
          companyCommission: null,
        }
      }
    },
  },
}
</script>
<style scoped>
.form-group.has-label {
  padding-top: 0.5rem !important;
}
</style>
