<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center bg-gradient-primary"
        style="
        min-height: 200px;
        background-size: cover;
        background-position: center top;
      "
    >
      <!-- Mask -->
      <span class="mask bg-gradient-blue opacity-8"></span>
      <!-- Header container -->
      <div class="">
        <div class="row">
          <div class="col-12 text-start">
            <h3 class="text-white float-start pe-3 text-uppercase">
              {{ requestData.position.name }}
            </h3>
<!--            <div class="badge p-1 font-size-xs float-start" :class="getStatusClass(requestData.status)">-->
<!--              <span class="status">{{ requestData.status }}</span>-->
<!--            </div>-->
            <br>
          </div>
<!--          <div class="col-12 text-start">-->
<!--            <h3 class="text-white float-start pe-3">-->
<!--              <span v-if="requestData.hasOwnProperty('mStartDate')">{{ requestData.mStartDate.format("dddd, DD MMM YYYY") }}</span>-->
<!--              <br><span v-if="requestData.hasOwnProperty('mEndDate')">{{ requestData.mStartDate.format("HH:mm") }} - {{ requestData.mEndDate.format("HH:mm") }}</span>-->
<!--            </h3>-->
<!--          </div>-->
          <!--            <p class="text-white mt-0 mb-5">-->
          <!--              This is your profile page. You can see the progress you've made-->
          <!--              with your work and manage your projects or assigned tasks-->
          <!--            </p>-->
          <!--            <a href="#!" class="btn btn-info">Edit profile</a>-->

        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!--      <div class="text-start p-2 ps-0">-->
      <!--        <div class="btn-group me-2" role="group" aria-label="First group">-->
      <!--          <router-link :to="'/requests/'+$route.params.id+'/info'" class="btn btn-secondary mt-1 mb-1">-->
      <!--            <span class="nav-link-text">{{ $t("Info") }}</span>-->
      <!--          </router-link>-->
      <!--          <router-link :to="'/requests/'+$route.params.id+'/documents'" class="btn btn-secondary mt-1 mb-1">-->
      <!--            <span class="nav-link-text">{{ $t("Documents") }}</span>-->
      <!--          </router-link>-->
      <!--          <router-link :to="'/requests/'+$route.params.id+'/paymentDetails'" class="btn btn-secondary mt-1 mb-1">-->
      <!--            <span class="nav-link-text">{{ $t("Payment Details") }}</span>-->
      <!--          </router-link>-->
      <!--          <router-link :to="'/requests/'+$route.params.id+'/requests'" class="btn btn-secondary mt-1 mb-1">-->
      <!--            <span class="nav-link-text">{{ $t("Requests") }}</span>-->
      <!--          </router-link>-->
      <!--        </div>-->
      <!--      </div>-->
      <request-details-card :request-data="requestData" @get-request="getRequest"></request-details-card>
      <div class="row">
        <div v-if="employeeData.firstName !== null" class="col-md-8 mt-3" >
          <employee-details-card :employee-data="employeeData"></employee-details-card>
<!--          <employee-documents-for-client v-if="employeeData.id" :employee-id="employeeData.id"></employee-documents-for-client>-->
        </div>
        <div :class="{ 'col-md-4': requestData.hasOwnProperty('claimedBy'), 'col-md-12': !requestData.hasOwnProperty('claimedBy') || requestData.claimedBy == null}">
          <card shadow type="secondary" class="mt-3">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12 text-start">
                    <h5 class="mb-0">{{ $t("Price overview") }}</h5>
                  </div>

                </div>
              </div>
            </template>
            <request-pricing-card v-if="requestData.hasOwnProperty('cost')" :cost="requestData.cost"></request-pricing-card>
          </card>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import RequestPricingCard from "@/views/Cards/RequestPricingCard"
import RequestDetailsCard from "@/views/Requests/RequestDetailsCard"
import {getStatusClass} from "@/helpers/common"
import EmployeeDetailsCard from "@/views/Cards/EmployeeDetailsCard"
import {getAnalytics, logEvent} from "firebase/analytics"

export default {
  name: "ClientRequestView",
  components: {EmployeeDetailsCard, RequestPricingCard, RequestDetailsCard},
  data() {
    return {
      nav: null,
      employeeData: {
        firstName: null
      },
      clientData: {},
      requestData: {
        mStartTime: null,
        position: { name: ""}},
      address: {}
    }
  },
  async mounted() {
    const analytics = getAnalytics()
    logEvent(analytics, "page_view", {
      page_title: this.$route.name,
    })
    // var self = this;
    await this.getRequest()

    if (this.requestData.hasOwnProperty("claimedBy") && this.requestData.claimedBy !== null){
      await this.getEmployee()
      await this.getEmployeePrivateInfo()
    }
    // this.getEmployeePrivateInfo()
  },
  methods: {
    getStatusClass,
    async getEmployee() {
      let self = this
      await this.$store
          .dispatch("fetchEmployee", this.requestData.claimedBy)
          .then((data) => {
            // console.log("data", data)
            self.employeeData = data
          })
    },
    async getEmployeePrivateInfo(){
      let res = await this.$store.dispatch("fetchEmployeePrivateInfo", this.requestData.claimedBy)
      console.log("res", res )
      this.address = res.address
      this.employeeData.lang = res.hasOwnProperty("lang") ? res.lang : ""
      this.employeeData.email = res.email
      this.employeeData.iban = res.iban
      this.employeeData.bankName = res.bankName
      this.employeeData.tiNumber = res.tiNumber
      this.employeeData.registrationNumber = res.hasOwnProperty("registrationNumber") ? res.registrationNumber : ""
      this.employeeData.idCard = res.hasOwnProperty("idCard") ? res.idCard : ""

      this.employeeData.phoneNumber = res.phoneNumber
      if (res.hasOwnProperty("address")){
        this.employeeData.address = res.address
      }
    },
    async getRequest() {
      let self = this
      await this.$store
          .dispatch("fetchClientRequest", this.$route.params.id)
          .then((data) => {
            console.log("data", data)
            self.requestData = data
          })
    },
  }
}
</script>

<style scoped>

</style>