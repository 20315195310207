<template>
  <system-request-view v-if="userRole === 'SYSTEM_USER'"></system-request-view>
  <normal-user-request-view v-if="userRole === 'EMPLOYEE'"></normal-user-request-view>
  <client-request-view v-if="userRole === 'CLIENT'"></client-request-view>
</template>
<script>

import SystemRequestView from "@/views/Requests/SystemRequestView"
import NormalUserRequestView from "@/views/Requests/NormalUserRequestView"
import ClientRequestView from "@/views/Requests/ClientRequestView"

export default {
  name: "Request",
  components: {ClientRequestView, NormalUserRequestView, SystemRequestView},
  head() {
    return {
      title: "Shift Overview | AllCare Cyprus",
      meta: [
        {
          name: "description",
          content: "Explore details about the shift.",
        },
      ]
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    }
  }
}
</script>
<style></style>
