<template>
  <div class="pl-lg-4 text-start">
<!--    <div class="fw-bolder text-uppercase text-muted mt-4 text-start mt-4">{{ userRole !== 'SYSTEM_USER' ? "Price overview" : "Financial details" }}</div>-->
<!--    <hr class="m-0">-->
    <div class="row">
      <div v-if="userRole !== 'CLIENT'" class="col-md-4 pt-2">
        <display-label
            class="mb-0"
            :label="userRole === 'EMPLOYEE' ? 'Earnings' : 'Employee earnings'"
            :value="getValueOrDefault(cost ?? {}, 'employeeEarnings', '' )/100+'€' ?? ''"
        ></display-label>
        <!--            <span class="text-sm fst-italic">Earnings is the amount that you will earn by this shift </span>-->
      </div>
      <div v-if="userRole !== 'CLIENT'" class="col-md-4 pt-2">
        <display-label
            class="mb-0"
            :label="userRole !== 'SYSTEM_USER' ? 'Commission' : 'Company commission'"
            :value="getValueOrDefault(cost ?? {}, 'companyCommission', '' )/100+'€' ?? ''"
        ></display-label>
        <!--            <span class="text-sm fst-italic">Commission will be paid by you in an Invoice by AllCare</span>-->
      </div>
      <div :class="{ 'col-md-12' : userRole === 'CLIENT'}" class="col-md-4 pt-2">
        <display-label
            class="mb-0"
            :label="userRole === 'EMPLOYEE' ? 'Shift Price' : 'Total cost'"
            :value="getValueOrDefault(cost ?? {}, 'totalCost', '' )/100+'€' ?? ''"
        ></display-label>
        <!--            <span v-if="userRole === 'EMPLOYEE'" class="text-sm fst-italic">Shift price will be paid by the Client directly to you</span>-->
      </div>
    </div>
  </div>
</template>

<script>
import {getStatusClass} from "@/helpers/common"
import DisplayLabel from "@/components/DisplayLabel"

export default {
  name: "RequestPricingCard",
  components: {DisplayLabel},
  props: {
    cost: {
      type: Object,
      description: "request cost data",
    },
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  methods: {
    getStatusClass,
    getValueOrDefault(obj, prop, defaultValue){
      return Object.prototype.hasOwnProperty.call(obj, prop) ? (obj[prop] || defaultValue) : defaultValue
    }

  },
}
</script>

<style scoped>

</style>