<template>
  <div>
    <base-header type="gradient-primary"  class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <invoices-table v-if="userRole === 'EMPLOYEE'" :title="$t('Invoices List')" :columns="columns"></invoices-table>
          <invoices-table v-else :title="$t('Invoices List')"></invoices-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseHeader from "@/components/BaseHeader"
import InvoicesTable from "@/views/Tables/InvoicesTable"
export default {
  name: "invoices",
  components: {
    InvoicesTable,
    BaseHeader,
  },
  data() {
    return {
      columns: [
        {
          key:"invoiceRef",
          label:"Ref"
        },
        {
          key:"products",
          label:"Shift"
        },
        {
          key:"status",
          label:"Status",
          sorting: true
        },
        {
          key: "amount",
          label:"Total"
        },
        {
          key: "createdAt",
          label: "Date Created",
          sorting: true
        },
        {
          key: "",
          label:"Actions"
        },
      ]
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
}
</script>
<style></style>
