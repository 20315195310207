<template>
  <nav
    id="sidenav-main"
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
<!--        <img :src="logo" class="navbar-brand-img" alt="..." />-->
        <h2 class="text-white rounded shadow bg-gradient-primary ps-2 pe-2">{{title}}</h2>
      </router-link>

      <slot name="mobile-right">

<!--        <ul class="nav align-items-center d-md-none">-->
<!--          <base-dropdown class="nav-item" position="right">-->
<!--            <template #title>-->
<!--              <a class="nav-link" href="#" role="button">-->
<!--                <div class="media align-items-center">-->
<!--                  <span class="avatar avatar-sm rounded-circle">-->
<!--                    <i class="fa fa-user"></i>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </a>-->
<!--            </template>-->

<!--            <div class="dropdown-header noti-title">-->
<!--              <h6 class="text-overflow m-0">Welcome!</h6>-->
<!--            </div>-->
<!--            <router-link to="/profile" class="dropdown-item">-->
<!--              <i class="ni ni-single-02"></i>-->
<!--              <span>My profile</span>-->
<!--            </router-link>-->
<!--            <router-link to="/profile" class="dropdown-item">-->
<!--              <i class="ni ni-settings-gear-65"></i>-->
<!--              <span>Settings</span>-->
<!--            </router-link>-->
<!--            <router-link to="/profile" class="dropdown-item">-->
<!--              <i class="ni ni-calendar-grid-58"></i>-->
<!--              <span>Activity</span>-->
<!--            </router-link>-->
<!--            <router-link to="/profile" class="dropdown-item">-->
<!--              <i class="ni ni-support-16"></i>-->
<!--              <span>Support</span>-->
<!--            </router-link>-->
<!--            <div class="dropdown-divider"></div>-->
<!--            <a href="#!" class="dropdown-item">-->
<!--              <i class="ni ni-user-run"></i>-->
<!--              <span>Logout</span>-->
<!--            </a>-->
<!--          </base-dropdown>-->
<!--        </ul>-->
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        id="sidenav-collapse-main"
        class="navbar-collapse collapse show"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <hr class="my-3" />
        <!--Heading-->
        <h6 class="navbar-heading text-muted">Help Center</h6>
        <!--Navigation-->
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
                class="nav-link"
                href="/feature-ideas"
            >
              <i class="fa fa-rocket"></i> Feature Ideas
            </a>
          </li>
          <li class="nav-item">
            <a
                class="nav-link"
                href="/faq"
            >
              <i class="fa fa-question-circle"></i> Frequently asked questions
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="/contact-us"
            >
              <i class="fas fa-hands-helping"></i> {{$t("Contact us") }}
            </a>
          </li>
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link"-->
<!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/foundation/colors.html"-->
<!--            >-->
<!--              <i class="ni ni-palette"></i> Foundation-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a-->
<!--              class="nav-link"-->
<!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/components/alerts.html"-->
<!--            >-->
<!--              <i class="ni ni-ui-04"></i> Components-->
<!--            </a>-->
<!--          </li>-->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton"

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  props: {
    title: {
      type: String,
      default: "The title",
      description: "Sidebar title",
    },logo: {
      type: String,
      default: "/logo.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true)
    },
  },
}
</script>
