<template>
  <card shadow type="secondary">
    <template #header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-md-4 text-start">
            <h5 class="mb-0">{{ $t("Request Details") }}</h5>
          </div>
          <div class="col-md-8 text-end">
            <action-buttons :request-data="requestData" @get-request="getRequest"></action-buttons>
          </div>
        </div>
      </div>
    </template>


<!--      <div class="fw-bolder text-uppercase text-muted mt-4 text-start">Details</div>-->
<!--      <hr class="m-0">-->
      <div class="pl-lg-4 text-start">
        <div class="row">
          <div class="col-md-6">
             <display-label
                label="Shift time:"
               :value="requestData.hasOwnProperty('mStartDate') && requestData.hasOwnProperty('mEndDate') ? requestData.mStartDate.format('dddd, DD MMM YYYY HH:mm') + requestData.mEndDate.format(' - HH:mm') : ''"
              ></display-label>
          </div>
          <div class="col-md-6 pt-md-2">
            <label class="form-label fw-bold m-0">{{ $t("Request Status") }}:</label> <br>
            <div class="badge p-1 font-size-sm" :class="getStatusClass(requestData.status)">
              <span class="status">{{ $t(requestData.status ?? "") }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pt-md-2">
            <div class="form-group has-label">
              <label class="form-label fw-bold m-0">{{ $t("Client") }}:</label><br>
              <router-link v-if="userRole === 'CLIENT'" class="pb-2" to="/account/info">{{ requestData.clientName }}</router-link>
              <router-link v-else class="pb-2" :to="'/clients/'+requestData.clientId+'/info'">{{ requestData.clientName }}</router-link>
            </div>
          </div>
          <div v-if="requestData.claimedBy != null" class="col-md-6 ">
            <label class="form-label fw-bold m-0">{{ $t("Employee") }}:</label> <br>
            <a v-if="userRole === 'SYSTEM_USER'" :href="'/employees/'+requestData.claimedBy">{{ requestData.employeeName }}</a>
            <span v-else>{{ requestData.employeeName }}</span>
          </div>
        </div>
<!--        <div class="row">-->
<!--          <div class="col-md-6 pt-md-2">-->
<!--            <label class="form-label fw-bold m-0">Payment status:</label> <br>-->
<!--            <div class="badge p-1 font-size-sm" :class="getStatusClass(requestData.paymentStatus)">-->
<!--              <span class="status">{{ $t(requestData.paymentStatus ?? "") }}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-6 pt-md-2">-->
<!--            <display-label-->
<!--                label="Position:"-->
<!--                :value="requestData.position.name"-->
<!--            ></display-label>-->
<!--          </div>-->
<!--          {{requestData}}-->
<!--</div>-->
        <div class="row">
          <div v-if="requestData.status === 'CANCELED' && requestData.cancellationReason != null" class="col-md-6 pt-md-2">
            <display-label
                :label="$t('Cancellation Reason:')"
                :value="requestData.cancellationReason"
            ></display-label>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 pt-md-2">
            <display-label
                :label="$t('Created at:')"
                :value="requestData.createdAt"
            ></display-label>
          </div>
          <div v-if="requestData.hasOwnProperty('mDeadline')" class="col-md-6 pt-md-2">
            <display-label
                :label="$t('Deadline:')"
                :value="requestData.mDeadline.format('DD/MM/YYYY HH:mm')"
            ></display-label>
          </div>
<!--          <div v-if="requestData.hasOwnProperty('updatedAt')" class="col-md-6 pt-md-2">-->
<!--            <display-label-->
<!--                label="Updated at:"-->
<!--                :value="requestData.updatedAt"-->
<!--            ></display-label>-->
<!--          </div>-->
      </div>
      </div>


<!--      <div class="fw-bolder text-uppercase text-muted mt-4 text-start">Contact Details</div>-->
<!--      <hr class="m-0">-->



  </card>
</template>
<script>
import DisplayLabel from "@/components/DisplayLabel"
import {getStatusClass} from "@/helpers/common"
import ActionButtons from "@/views/Requests/ActionButtons"
export default {
  name: "request-details-card",
  components: {ActionButtons, DisplayLabel},
  props: {
    requestData: {
      type: Object,
      description: "Client data",
    },
  },
  emits: ["getRequest"],
  data() {
    return {
      today: new Date(),
      isDisabled: true,
      alertMessage: "",
      loadingProcess: "",
      model: {
        requestStatus: null,
        clientId: null,
        deadline: null,
        startDate: null,
        endDate: null,
        position: null,
        cost: {
          totalCost: null,
          employeeEarnings: null,
          companyCommission: null,
        }
      }
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  watch: {
    requestData(newVal) {
      console.log("newVal", newVal)
      this.model = newVal
    },
  },
  mounted() {
    // var self = this;
    console.log(this.$route.params)
    console.log("requestData card", this.requestData)
    // this.mapCompany();
  },
  methods: {
    getStatusClass,
    getRequest(){
      this.$emit("getRequest")
    },
    getValueOrDefault(obj, prop, defaultValue){
      return Object.prototype.hasOwnProperty.call(obj, prop) ? (obj[prop] || defaultValue) : defaultValue
    }

  },
}
</script>
<style></style>
