<template>
  <div class="pl-lg-4 text-start">
    <!--    <div class="fw-bolder text-uppercase text-muted mt-4 text-start mt-4">{{ userRole !== 'SYSTEM_USER' ? "Price overview" : "Financial details" }}</div>-->
    <!--    <hr class="m-0">-->
    <div class="row">
      <div class="col-md-4 pt-2">
        {{ invoiceData }}
        <display-label
            class="mb-0"
            :label="$t('Invoice status')"
            :value="getValueOrDefault(invoiceData, 'status')"
        ></display-label>
        <!--            <span class="text-sm fst-italic">Earnings is the amount that you will earn by this shift </span>-->
      </div>
    </div>
  </div>
</template>

<script>
import {getStatusClass} from "@/helpers/common"
import DisplayLabel from "@/components/DisplayLabel"

export default {
  name: "InvoiceView",
  components: {DisplayLabel},
  props:{
    invoiceId: {
      type: String
    },
    employeeId: {
      type: String
    },
  },
  data() {
    return {
      invoiceData: {},
    }
  },
  async mounted() {
    // var self = this;
    await this.getInvoice()
  },
  methods: {
    getValueOrDefault(obj, prop, defaultValue){
      return Object.prototype.hasOwnProperty.call(obj, prop) ? (obj[prop] || defaultValue) : defaultValue
    },
    getStatusClass,
    async getInvoice() {
      let self = this
      await this.$store
          .dispatch("fetchInvoice", {
            invoiceId: this.invoiceId,
            employeeId: this.employeeId
          })
          .then((data) => {
            console.log("invoiceData data", data)
            self.invoiceData = data
          })
    },
  }
}
</script>

<style scoped>

</style>