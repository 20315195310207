<template>
  <keep-alive>
  <div>

  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-6">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ $t(title) }}
          </h5>
        </div>

<!--        <div class="col text-end">-->
<!--          <base-button tag="a" href="/requests/new" type="primary" size="sm">Add New</base-button>-->
<!--        </div>-->
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        v-model:loading="loading"
        :columns="columns"
        class="table align-items-center table-flush text-start"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
        :list-full="listFull"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        :paginated="true"
        @sort="getRequests"
        @fetch-more="getRequests"
      >
        <template #default="row">
          <td>
            <router-link :to="'/requests/' + row.item.requestId">
              <span class="name mb-0 text-sm">{{ row.item.requestRef ?? "Shift Ref." }}</span>
            </router-link>
          </td>
          <td>
            <router-link :to="'/clients/' + row.item.clientId">
              <span class="name mb-0 text-sm">{{ row.item.clientName ?? "Client" }}</span>
            </router-link>
          </td>
          <td>
            <div class="badge p-1 font-size-xs">
              <span class="text-black">{{ parseFloat(row.item.score).toFixed(2) }}</span>
              <i class="fa fa-star" style="color: #F79426;"></i>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-end">
            <base-button
              tag="a"
              type="primary"
              class="border-0 me-2 text-white"
              size="md"
              @click="showReview(row.item)"
              >
              <i class="fa fa-eye"></i>
            </base-button>

            <!--            <base-button-->
<!--              type="success"-->
<!--              class="bg-red border-0"-->
<!--              size="md"-->
<!--              >-->
<!--Delete-->
<!--</base-button>-->
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
    <review-modal
        :has-review="true"
        loading-process="asas"
        error-message="asas"
        :show-review-request="showReviewRequest"
        :review-model="reviewModel"
        @close-modal="closeReviewModal"
        @submit-review="{}"
    ></review-modal>
  </div>
  </keep-alive>
</template>
<script>
import {getDuration, getStatusClass} from "@/helpers/common"
import {auth} from "@/main"
import ReviewModal from "@/views/Modals/ReviewModal.vue"
import BaseButton from "@/components/BaseButton.vue"

export default {
  name: "employee-reviews-table",
  components: {BaseButton, ReviewModal},
  props: {
    type: {
      type: String,
    },
    title: String,
    columns: {
      type: Array,
      default: () => [
        {
          key: "requestId",
          label: "Request"
        },
        {
          key: "clientId",
          label: "Client",
        },
        {
          key: "score",
          label: "Score",
        },
        {
          key: "createdAt",
          label: "Date Created",
          sorting: true
        },
        {
          key: "Actions",
          label: "Actions"
        }
      ],
    }
  },
  data() {
    return {
      showReviewRequest: false,
      reviewModel: {},
      today: new Date(),
      lastSnapshot: null,
      loading: true,
      clients: [],
      loadingProcess: "",
      listFull: false,
      errorMessage: "",
      positions: [],
      sortDirection: "desc",
      sortBy: "createdAt",
      model: {
        dates: [],
        clients: [],
        positions: [],
        statuses: [],
        employees: []
      },
      statuses: [
        {key: "PENDING", label: this.$t("PENDING")},
        {key: "VERIFIED", label: this.$t("VERIFIED")},
        {key: "CLAIMED", label: this.$t("CLAIMED")},
        {key: "CANCELED", label: this.$t("CANCELED")},
        {key: "ABANDONED", label: this.$t("ABANDONED")},
        {key: "FINISHED", label: this.$t("FINISHED")},
      ],

      tableData: [],
    }
  },
  computed: {
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
    hasUser() {
      console.log("this", this.$route)
      return ["clientRequests", "employeeRequests"].includes(this.$route.name)
    },
    isEmployee() {
      return ["employeeRequests"].includes(this.$route.name)
    },
    isClient() {
      return ["clientRequests"].includes(this.$route.name)
    }
  },
  mounted() {
    if (this.$store.state.user.claims.role === "SYSTEM_USER") {
      this.getClients()
      this.getPositions()
    }
    this.getRequests()
  },
  methods: {
    closeReviewModal() {
      console.log("closingReviewModal")
      this.showReviewRequest = false
      this.reviewModel = {}
    },
    showReview(data){
      this.reviewModel.comment = data.comment
      this.reviewModel.score = parseFloat(data.score).toFixed(2)
      this.reviewModel.questions = data.results.map(item => {
        return {
          ...item,        // Spread the existing properties of the object
          value: item.rating // Add the new property `score` with the value from `value`
        }
      })
      console.log("this.reviewModel", this.reviewModel)
      this.showReviewRequest = true
    },
    getDuration,
    getStatusClass,
    async getPositions() {
      this.positionsLoading = true
      await this.$store.dispatch("fetchAllPositions", true).then((data) => {
        this.positionsLoading = false
        this.positions = data.map( p => { return {key: p.id, label: p.name}})

      })
    },
    async getClients() {
      await this.$store.dispatch("fetchClients").then((data) => {
        // console.log("fetchClients", data)
        this.clients = data
      })
    },
    async getRequests(sorter = null, clear = false) {

      this.loading = true
      console.log("sorter ====", sorter)
      console.log("sorter ====", clear)

      if (sorter){
        this.sortBy = sorter.field
        this.sortDirection = sorter.order
      }

      if (clear){
        this.tableData = []
        this.lastSnapshot = null
        this.listFull = false
      }

      const filters = {
        // clients: this.isClient && this.hasUser ? [this.$route.params.id] : this.model.clients.map(c => c.id),
        // positions: this.model.positions.map(c => c.key) ?? null,
        // statuses: this.model.statuses.map(c => c.key) ?? null,
        // employees: this.isEmployee && this.hasUser ? [this.$route.params.id] : this.model.employees.map(c => c.key),
        // dates: this.model.dates ?? null,
      }

      let sorting
        sorting = {
          sortBy: this.sortBy,
          sortDirection: this.sortDirection
        }
      // }
      console.log(filters)

      await this.$store.dispatch("fetchEmployeeReviews", {employeeId: this.$route.params.id, filters: filters, sorting: sorting, lastSnapshot: this.lastSnapshot}).then((result) => {
        this.loading = false
        console.log("fetchEmployeeReviews", result)
        if (result.data === null){
          this.listFull = true
          return
        }

      if (result.lastSnapshot !== null){
        this.lastSnapshot = result.lastSnapshot
      }

        result.data.forEach ( record => {
          // console.log("record", record)
          let index = this.tableData.findIndex(r => r.id === record.id)
          if (index > -1){
            this.tableData[index] = record
          } else {
            this.tableData.push(record)
          }
        })

        this.listFull = result.data === null || result.data.length < 10

      })
    },
  },
}
</script>
<style></style>
