<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="col-xl-8 order-xl-1">
            <config v-if="!loading" v-model="model" @submit="submitConfig">
              <template #buttons>
                <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submitConfig">{{ $t('Submit') }}</submit-button>
              </template>
            </config>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import BaseInput from "@/components/BaseInput"
import Config from "@/components/Config.vue"
import SubmitButton from "@/components/SubmitButton.vue"
export default {
  name: "system-config",
  components: {SubmitButton, Config},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      model: {
        companyCommissionRatePercent: null,
        employeeRatePercent: null,
        nightShiftStartHour: null,
        nightShiftEndHour: null,
        dueInvoiceDays: null,
        mandatoryRestHours: null
      },
    }
  },
  validations () {
    return {
      model: {
        companyCommissionRatePercent: {
          required
        },
        employeeRatePercent: {
          required
        },
        nightShiftStartHour: {
          required
        },
        nightShiftEndHour: {
          required
        },
      }
    }
  },
  async mounted() {
    await this.getSettings()
  },
  methods: {
    async getSettings() {
      await this.$store.dispatch("fetchSettings").then((data) => {
        console.log("fetchSettings", data)
        this.model = data
        this.loading = false
      })
    },
    async submitConfig() {
      let self = this

      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      const payload = {
        companyCommissionRatePercent: this.model.companyCommissionRatePercent,
        employeeRatePercent: this.model.employeeRatePercent,
        nightShiftStartHour: this.model.nightShiftStartHour.id,
        nightShiftEndHour: this.model.nightShiftEndHour.id,
        dueInvoiceDays: this.model.dueInvoiceDays,
        // clientPenaltyPercent: parseInt(this.model.clientPenaltyPercent),
        // employeePenaltyPercent: parseInt(this.model.employeePenaltyPercent),
        // clientPenaltyBeforeDeadlinePercent: parseInt(this.model.clientPenaltyBeforeDeadlinePercent),
        // clientPenaltyAfterDeadlinePercent: parseInt(this.model.clientPenaltyAfterDeadlinePercent),
        mandatoryRestHours: parseInt(this.model.mandatoryRestHours),
      }

      this.loadingProcess = "processing"
      FirebaseFunctionsHelper.callFunction(
          "settings-updateConfigs",
          // eslint-disable-next-line no-undef
          payload
      )
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              self.loadingProcess = "failed"
              self.errorMessage = result.message
              return
            }
            console.log("submitUser result", result)
            this.loadingProcess = "done"

            if (!this.isUpdate) {
              this.clearForm()
            }

            setTimeout(function () {
              self.loadingProcess = ""
            }, 1000)
          })
          .catch((error) => {
            self.loadingProcess = "failed"
            self.errorMessage = error.message
            console.log("submitUser error", error)
          })

      // setTimeout(() => {
      //   this.loadingProcess = "failed";
      //   this.loadingMessage = "There was an error.";
      //   //Add Tokens
      // }, 2000);
    },
    clearForm() {
      this.loadingProcess = ""
    },
  },
}
</script>
<style scoped>
</style>
