<template>
  <div class="wrapper">
<!--    <side-bar-->
<!--      :background-color="sidebarBackground"-->
<!--      short-title="Agency"-->
<!--      title="All Care"-->
<!--    >-->
<!--      <template v-slot:links>-->
<!--        <sidebar-item-->
<!--          v-if="['SYSTEM_USER'].includes($store.state.user.claims.role)"-->
<!--          :link="{-->
<!--            name: 'Clients',-->
<!--            icon: 'fa fa-building text-primary',-->
<!--            path: '/clients',-->
<!--          }"-->
<!--        >-->
<!--        </sidebar-item>-->
<!--          <sidebar-item-->
<!--            v-if="['SYSTEM_USER'].includes($store.state.user.claims.role)"-->
<!--            :link="{-->
<!--            name: 'System Users',-->
<!--            icon: 'fa fa-users text-primary',-->
<!--            path: '/system-users',-->
<!--          }"-->
<!--          ></sidebar-item>-->
<!--        <sidebar-item-->
<!--          v-if="['SYSTEM_USER'].includes($store.state.user.claims.role)"-->
<!--          :link="{-->
<!--            name: 'Manage Claims',-->
<!--            icon: 'fa fa-box text-primary',-->
<!--            path: '/manage-claims',-->
<!--          }"-->
<!--        ></sidebar-item>-->
<!--        <sidebar-item-->
<!--          v-if="['SYSTEM_USER'].includes($store.state.user.claims.role)"-->
<!--          :link="{-->
<!--            name: 'Invoices',-->
<!--            icon: 'fa fa-money-check-alt text-primary',-->
<!--            path: '/system-invoices',-->
<!--          }"-->
<!--        ></sidebar-item>-->

<!--        <sidebar-item-->
<!--          v-if="['OWNER'].includes($store.state.user.claims.role)"-->
<!--          :link="{-->
<!--            name: 'Dashboard',-->
<!--            icon: 'fa fa-tv text-primary',-->
<!--            path: '/dashboard',-->
<!--          }"-->
<!--        >-->
<!--        </sidebar-item>-->
<!--        &lt;!&ndash;Divider&ndash;&gt;-->
<!--        <hr class="my-1" />-->
<!--        <sidebar-item-->
<!--          v-if="['OWNER'].includes($store.state.user.claims.role)"-->
<!--          :link="{-->
<!--            name: 'Profile',-->
<!--            icon: 'ni ni-single-02 text-success',-->
<!--            path: '/profile',-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          v-if="['OWNER'].includes($store.state.user.claims.role)"-->
<!--            :link="{-->
<!--            name: 'Invoices',-->
<!--            icon: 'ni ni-money-coins text-success',-->
<!--            path: '/invoices',-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          v-if="['OWNER'].includes($store.state.user.claims.role)"-->
<!--          :link="{-->
<!--            name: 'Company',-->
<!--            icon: 'ni ni-building text-blue',-->
<!--            path: '/company',-->
<!--          }"-->
<!--        />-->
<!--&lt;!&ndash;        <sidebar-item&ndash;&gt;-->
<!--&lt;!&ndash;          :link="{&ndash;&gt;-->
<!--&lt;!&ndash;            name: 'Clients',&ndash;&gt;-->
<!--&lt;!&ndash;            icon: 'ni ni-planet text-blue',&ndash;&gt;-->
<!--&lt;!&ndash;            path: '/icons',&ndash;&gt;-->
<!--&lt;!&ndash;          }"&ndash;&gt;-->
<!--&lt;!&ndash;        />&ndash;&gt;-->
<!--&lt;!&ndash;        <sidebar-item&ndash;&gt;-->
<!--&lt;!&ndash;          :link="{&ndash;&gt;-->
<!--&lt;!&ndash;            name: 'Working Hours',&ndash;&gt;-->
<!--&lt;!&ndash;            icon: 'ni ni-bullet-list-67 text-red',&ndash;&gt;-->
<!--&lt;!&ndash;            path: '/tables',&ndash;&gt;-->
<!--&lt;!&ndash;          }"&ndash;&gt;-->
<!--&lt;!&ndash;        />&ndash;&gt;-->
<!--      </template>-->
<!--    </side-bar>-->
    <sidebar-menu
        v-model:collapsed="collapsed"
        width="230px"
        theme="white-theme"
        :menu="menu"
        :show-one-child="true"
        :hide-toggle="true"
        @update:collapsed="onToggleCollapse"
        @item-click="onItemClick"
    >
      <div
          v-if="isOnMobile && !collapsed"
          class="sidebar-overlay"
          @click="collapsed = true"
      />
      <template v-slot:header>
        <h3 v-if="!collapsed" class="text-white rounded text-center pt-3">
          <img src="@/assets/logo_name_black.png" width="200" alt="Logo"/>
        </h3>
        <h3 v-if="collapsed" class="text-white   text-center pt-3">
          <img src="@/assets/logo_icon.png" width="40" alt="Logo"/>
        </h3>
      </template>

      <template v-slot:toggle-icon>
        <i class="fa fa-bars"></i>
      </template>
    </sidebar-menu>
    <div class="main-content">
      <dashboard-navbar></dashboard-navbar>

      <div>
        <!-- your content here -->
        <router-view v-slot="{ Component }">
          <keep-alive include="requests-list,employees-list,client-requests,requests-search-table">
            <component :is="Component" :key="$route.name"></component>
          </keep-alive>
        </router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue"
import ContentFooter from "./ContentFooter.vue"
import { SidebarMenu } from "vue-sidebar-menu"
import "vue-sidebar-menu/dist/vue-sidebar-menu.css"
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarMenu
  },
  data() {
    return {
      collapsed: false,
      isOnMobile: false,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      menu: [
        {
          href: "/profile",
          title: this.$t("My Profile"),
          icon: "fa fa-user",
          hidden: !["EMPLOYEE"].includes(this.$store.state.user.claims.role)
        },
        {
          href: "/account",
          title: this.$t("Account"),
          icon: "fa fa-building-user",
          hidden: !["CLIENT"].includes(this.$store.state.user.claims.role)
        },
        {
          href: "/find-a-shift",
          title: this.$t("Find a shift"),
          icon: "fa fa-magnifying-glass-chart",
          hidden: !["EMPLOYEE"].includes(this.$store.state.user.claims.role)
        },
        {
          href: "/my-requests",
          title: this.$t("My Shifts"),
          icon: "fa fa-chart-simple",
          hidden: !["EMPLOYEE"].includes(this.$store.state.user.claims.role)
        },
        {
          href: "/invoices",
          title: this.$t("Invoices"),
          icon: "fa fa-file-invoice",
          hidden: !["EMPLOYEE"].includes(this.$store.state.user.claims.role)
        },
        {
          href: "/dashboard",
          title: this.$t("Dashboard"),
          icon: "fa fa-chart-simple",
          hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role)
        },
        {
          hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role),
          component: "hr"
        },
        {
          href: "/requests",
          title: this.$t("Requests"),
          icon: "fa fa-briefcase",
          hidden: !["SYSTEM_USER", "CLIENT"].includes(this.$store.state.user.claims.role)
        },
        {
          title: this.$t("Clients"),
          icon: "fa fa-building-user",
          hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role),
          child: [
            {
              icon: "fa fa-list",
              href: "/clients",
              title: this.$t("Client List")
            },
            {
              icon: "fa fa-user-plus",
              href: "/clients/new",
              title: this.$t("New Client")
            }
          ]
        },

        {
          title: this.$t("Partners"),
          icon: "fa fa-user-doctor",
          hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role),
          child: [
            {
              icon: "fa fa-list",
              href: "/employees",
              title: this.$t("Partners List")
            },
            {
              icon: "fa fa-user-plus",
              href: "/employees/new",
              title: this.$t("New Partner")
            }
          ]
        },
        {
          title: this.$t("Invoices"),
          icon: "fa fa-file-invoice",
          hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role),
          child: [
            {
              icon: "fa fa-file-invoice",
              href: "/invoices",
              title: this.$t("Invoice List")
            },
            {
              icon: "fa fa-file-circle-plus",
              href: "/invoices/new",
              title: this.$t("New Invoice")
            }
          ]
        },
        {
          component: "hr"
        },
        {
          title: this.$t("Settings"),
          icon: "fa fa-cog",
          hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role),
          child: [
            {
              icon: "fa fa-cog",
              href: "/configuration",
              title: this.$t("Configuration")
            },
            {
              icon: "fa fa-users",
              href: "/systemUsers",
              title: this.$t("System Users")
            },
            {
              icon: "fa fa-briefcase",
              href: "/positions",
              title: this.$t("Positions")
            }
          ]
        },
        // {
        //   title: this.$store.state.user.data == null ? "" : this.$store.state.user.data.firstName + " " + this.$store.state.user.data.lastName ,
        //   icon: "fa fa-user-tie",
        //   // hidden: !["SYSTEM_USER"].includes(this.$store.state.user.claims.role),
        //   child: [
        //     {
        //       icon: "fa fa-user",
        //       href: "/profile",
        //       title: "Profile"
        //     },
        //     {
        //       icon: "fa fa-sign-out-alt",
        //       href: "",
        //       class: "logOut",
        //       title: "Log Out"
        //     }
        //   ]
        // }
      ]
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener("resize", this.onResize)

  },
  methods: {
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    },
    onToggleCollapse(collapsed) {
      this.collapsed = collapsed
    },
    onItemClick(event, item) {
      if (item.class === "logOut"){
        let res = this.$store.dispatch("signOut")
        this.$router.push("/login")
      }
    }
  },
}
</script>
<style lang="scss"></style>
