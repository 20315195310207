<template>
<div>
  <requests-table :title="$t('Requests')" :columns="columns"></requests-table>
</div>
</template>

<script>
import RequestsTable from "@/views/Tables/RequestsTable"
export default {
  name: "client-requests",
  components: {RequestsTable},
  data() {
    return {
      columns: [
        {
          key: "Ref",
          label: "Ref"
        },
        {
          key: "Position",
          label: "Position"
        },
        {
          key: "status",
          label: "Status"
        },
        {
          key: "claimedBy",
          label: "Claimed By"
        },{
          key: "cost",
          label: "Cost"
        },
        {
          key: "startDate",
          label: "Start Date",
          sorting: true
        },
        {
          key: "createdAt",
          label: "Date Created",
          sorting: true
        },
        {
          key: "Actions",
          label: "Actions"
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>