<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0 text-start fw-bold" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h5>
        </div>
        <div class="col text-end">
          <base-button tag="a" href="/systemUsers/new" type="primary" size="sm">{{ $t("Add New") }}</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class=" align-items-center table-flush text-start"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template #columns>
          <th>{{ $t("Name") }}</th>
          <th>{{ $t("Email") }}</th>
          <th>{{ $t("Status") }}</th>
          <th>{{ $t("Date Created") }}</th>
          <th>{{ $t("Actions") }}</th>
        </template>

        <template #default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 font-size-sm fw-bolder">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td>
            <div class="name mb-0 font-size-sm">{{ row.item.email }}</div>
          </td>
          <td>
            <div class="badge p-1 font-size-sm" :class="row.item.status === 'ACTIVE' ? 'bg-gradient-green' : 'bg-danger'">
              <span class="status">{{ $t(row.item.status) }}</span>
            </div>
          </td>
          <td>
              <div class="name mb-0 font-size-sm">{{ row.item.createdAt }}</div>
          </td>

          <td class="">
            <base-button tag="a" :href="'/systemUser/'+row.item.id" class="bg-gradient-green text-white border-0 me-2" size="md">
              {{ $t("Edit") }}
            </base-button>
            <base-button @click="deleteUser(row.item.id)" type="success" class="bg-danger border-0" size="md">
              {{ $t("Delete") }}
            </base-button>
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"

export default {
  name: "system-users-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      tableData: [],
    }
  },
  mounted() {
    this.getSystemAdmins()
  },
  methods: {
    async getSystemAdmins() {
      await this.$store.dispatch("fetchSystemUsers").then((data) => {
        console.log("fetchSystemUsers", data)
        this.tableData = data
      })
    },
    deleteUser(id){
      this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#dc3545",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          FirebaseFunctionsHelper.callFunction("system-removeUser", {systemUserId: id})
              .then((result) => {
                console.log("res", result)
                // eslint-disable-next-line no-prototype-builtins
                if (result != null && result.hasOwnProperty("message")) {
                  console.log("resmessage", result.message)
                  this.loadingProcess = "failed"
                  this.errorMessage = result.message
                  this.$swal.fire({
                    icon: "error",
                    html: result.message,
                    title: "Something went wrong!",
                  })
                  return
                }

                this.$swal.fire({
                  title: this.$t("Deleted!"),
                  html: this.$t("User removed."),
                  icon:"success",
                  timer: 1500,
                  timerProgressBar: true
                })
                this.getSystemAdmins()

              }, this)
              .catch((error) => {
                console.error("submitUser error", error)
                this.$swal.fire({
                  icon: "error",
                  html: result.message,
                  title: this.$t("Something went wrong!"),
                })
              })

        }
      })
    },
  },
}
</script>
<style></style>
