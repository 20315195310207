<template>
  <modal id="showReviewRequest" class="modal-w80" header-button-classes="text-white" header-classes="bg-primary" :show="showReviewRequest" @close="closeModal()">
    <template v-slot:header>
      <h5 class="modal-title text-white">
        {{ $t("Nurse shift review form") }}
        <span v-if="model.score != null">
            <i class="fa fa-star" style="  color: #F79426;"></i>
            {{ model.score }}
          </span>
      </h5>
    </template>
    <div>
      <div class="pl-lg-6">
        <div class="row">
          <label v-if="!hasReview" class="text-start">{{ $t("Please review and rate the following statements.") }}</label>

          <div v-for="(question, index) in model.questions" :key="index" class="col-lg-12 q-block">
            <div class="row">
              <div class="col-lg-8 text-start">
                <div class="q-title fw-bold text-primary">{{ question.order }}. {{ $t(question.question) }}</div>
                <div class="q-details font-size-xs">
                  {{ $t(question.question+'_DESC') }}
                </div>
              </div>
              <div class="col-lg-4">
                <base-rating v-model="question.value" :required="true" :is-invalid="question.isInvalid" :disabled="hasReview" @update-state="(n) => question.isInvalid = n" v-on="listeners"></base-rating>
              </div>
            </div>
          </div>
          <div class="col-lg-12 pt-4 text-start">
            <div class="q-title fw-bold text-primary">{{ $t('Reviewer comments') }}</div>
            <div v-if="!hasReview" class="q-details font-size-xs pb-2">
              {{ $t("Please add any additional comments or observations you have regarding the nurse's performance during the shift.") }}
            </div>
            <base-input
                v-if="!hasReview"
                alternative=""
            >
                <textarea
                    v-model="model.comment"
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder=""
                    v-on="listeners"
                >
                </textarea>
            </base-input>
            <div v-else>
              {{ model.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer>
      <submit-button
          v-if="!hasReview"
          :alert-message="errorMessage"
          :state="loadingProcess"
          @clicked="submitReview()"
      >
        {{ $t("Submit") }}
      </submit-button>
    </template>
  </modal>
</template>

<script>

import Modal from "@/components/Modal.vue"
import SubmitButton from "@/components/SubmitButton.vue"
import BaseRating from "@/components/BaseRating.vue"
import BaseInput from "@/components/BaseInput.vue"

export default {
  name: "review-modal",
  components: {BaseInput, BaseRating, Modal, SubmitButton},
  inheritAttrs: false,
  props: {
    showReviewRequest: {
      type: Boolean,
      description: "weather the modal is shown",
    },
    reviewModel: {
      type: Object,
      description: "",
    },
    hasReview: {
      type: Boolean,
      description: "",
    },
    loadingProcess: {
      type: String,
      description: "",
    },
    errorMessage: {
      type: String,
      description: "",
    }
  },
  emits: ["submitReview", "update:reviewModel", "closeModal"],
  data() {
    return {
      showModal: false,
      model: {},
    }
  },
  computed: {
    listeners() {
      return {
        input: this.updateModel,
      }
    },
  },
  watch: {
    // showReviewRequest(val) {
    //   console.log("is coming up", val)
    //   this.showModal = val
    // },
    reviewModel(newVal) {
      console.log("reviewModel", newVal)
      this.model = newVal
    },
  },
  mounted() {
    console.log("reviewModel", this.reviewModel)
    this.model = this.reviewModel
  },
  methods: {
    closeModal(){
      // this.showModal = false
      this.$emit("closeModal", false)
    },
    updateModel(evt) {
      this.$emit("update:reviewModel", this.model)
    },
    submitReview() {
      this.$emit("submitReview")
    }
  }
}

</script>

<style scoped>
.col-lg-12.q-block:hover {
  box-shadow: 0 0.425rem 0.45rem rgba(0, 0, 0, 0.065);
}
.col-lg-12.q-block {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 12px;
  width: 100%;
}
</style>