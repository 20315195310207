<template>
    <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-12 text-start">
                      <h5 class="mb-0 float-start">{{ $t(name) }}</h5>
                      <i v-if="$route.name === 'clientConfig'" class="float-end">Client configuration overwrites system configuration </i>
                    </div>
                  </div>
                </div>
              </template>
              <form>
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Payment Rate") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-lg-6">
                          <label for="customRange" class="form-label">{{ $t("Company commission:") }} {{ model.companyCommissionRatePercent }}%</label>
                        </div>
                        <div class="col-lg-6">
                          <label for="customRange" class="form-label">{{ $t("Employee payment") }}: {{ model.employeeRatePercent }} %</label>
                        </div>
                      </div>
                      <input id="customRange" v-model="percentageVal" type="range" class="form-range" min="0" max="100" step="1">
                    </div>
                    <div class="col-12 text-start pt-4 pb-4 text-black-50">
                      <label>{{ $t("Adjust the scroll bar in order to set the percentage of the money that Company will take as commission and the employee payment percentage that will get paid from the request.") }}</label>
                    </div>
                  </div>
                </div>
                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Request Settings") }} </div>
                <hr class="mt-0">
                <div class="ps-lg-4">

                  <div class="row">

                    <div class="col-lg-6 text-start">
                      <label>{{ $t("Night shift start time") }}</label>
                      <multiselect
                          v-model="model.nightShiftStartHour"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                          :placeholder="$t('Select night shift start time')"
                          :options="hoursList"
                          :searchable="false"
                          :multiple="false">
                      </multiselect>
                    </div>
                    <div class="col-lg-6 text-start">
                      <label>{{ $t("Night shift end time") }}</label>
                      <multiselect
                          v-model="model.nightShiftEndHour"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                          :placeholder="$t('Select night shift end time')"
                          :options="hoursList"
                          :searchable="false"
                          :multiple="false">
                      </multiselect>
                    </div>
                    <div class="col-12 text-start pt-4 pb-4 text-black-50">
                      <label>{{ $t("This values are used to calculate the night time. In order to change the correct rate for every hour of any shift") }}</label>
                    </div>
                    <div v-if="!forClient" class="col-lg-6 text-start">
                      <base-input
                          v-model="model.mandatoryRestHours"
                          type="number"
                          alternative=""
                          :label="$t('Mandatory rest hours')"
                          :placeholder="$t('Mandatory rest hours')"
                          input-classes="form-control-alternative"
                      >
                        <template #infoBlock>
                          <div class="text-black-50 w-100">{{ $t("The hours an employee must rest in order to claim another request.") }}</div>

                        </template>
                      </base-input>
                    </div>
                    <div v-if="!forClient" class="col-lg-6 text-start">
                      <base-input
                          v-model="model.dueInvoiceDays"
                          type="number"
                          alternative=""
                          :label="$t('Invoice due in days')"
                          :placeholder="$t('Number of days')"
                          input-classes="form-control-alternative"
                      >
                        <template #infoBlock>
                          <div class="text-black-50 w-100">{{ $t("The number of days each partner has in order to pay the invoice after he receive it.") }}</div>

                        </template>
                      </base-input>
                    </div>
                  </div>
                </div>

                <!--                <div class="text-start fw-bolder text-uppercase text-muted">Penalties </div>-->
                <!--                <hr class="mt-0">-->
                <!--                <div class="ps-lg-4">-->

                <!--                  <div class="row">-->

                <!--                    <div class="col-lg-6 text-start">-->
                <!--                      <label>{{ $t("Employee penalty") }}: {{ model.employeePenaltyPercent }}%</label>-->
                <!--                      <input v-model="model.employeePenaltyPercent" type="range" class="form-range" min="0" max="100" step="5" id="customRange4">-->
                <!--                      <div class="text-black-50">Employee cancels a request he already claimed.</div>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6  text-start">-->
                <!--                      <label>{{ $t("Client penalty") }}: {{ model.clientPenaltyPercent }}%</label>-->
                <!--                      <input v-model="model.clientPenaltyPercent" type="range" class="form-range" min="0" max="100" step="5" id="customRange1">-->
                <!--                      <div class="text-black-50">Client cancels a request that is verified and not claimed.</div>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6 pt-md-4 text-start">-->
                <!--                      <label>{{ $t("Client penalty before deadline") }}: {{ model.clientPenaltyBeforeDeadlinePercent }}%</label>-->
                <!--                      <input v-model="model.clientPenaltyBeforeDeadlinePercent" type="range" class="form-range" min="0" max="100" step="5" id="customRange2">-->
                <!--                      <div class="text-black-50">Client cancels a claimed request before request deadline.</div>-->
                <!--                    </div>-->
                <!--                    <div class="col-lg-6 pt-md-4 text-start">-->
                <!--                      <label>{{ $t("Client penalty after deadline") }}: {{ model.clientPenaltyAfterDeadlinePercent }}%</label>-->
                <!--                      <input v-model="model.clientPenaltyAfterDeadlinePercent" type="range" class="form-range" min="0" max="100" step="5" id="customRange3">-->
                <!--                      <div class="text-black-50">Client cancels a claimed request after request deadline.</div>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="col-12 text-end">
                  <slot name="buttons"></slot>
                </div>
              </form>
            </card>
</template>
<script>
import useVuelidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import BaseInput from "@/components/BaseInput"
import Card from "@/components/Card.vue"
export default {
  name: "config",
  components: {Card, BaseInput},
  props: {
    name:{
      type: String,
      default: "System Configuration"
    },
    modelValue:{
      type: Object,
      required: true
    },
    forClient: {
      type: Boolean,
      default: false
    },
  },
emits: ["update:modelValue", "submit"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      model: {
        companyCommissionRatePercent: null,
        employeeRatePercent: null,
        nightShiftStartHour: null,
        nightShiftEndHour: null,
        dueInvoiceDays: null,
        mandatoryRestHours: null
      },
      percentageVal: 0,
    }
  },
  computed: {
    hoursList(){
      let times = []
      for(let i = 1; i <= 23; i++) {
        times.push({id: i, name: i + ":00"})
      }
      return times
    }
  },
  watch: {
    percentageVal(val){
      this.model.companyCommissionRatePercent = val
      this.model.employeeRatePercent = 100-val
    },
    "model":{
      handler: function (newVal) {
        if (newVal !== this.modelValue) {
          this.$emit("update:modelValue", newVal)
        }
      },
      deep: true
    },
  },
  async mounted() {
    this.model = this.modelValue
    this.mapValues()
  },
  methods: {
    mapValues(){
      this.percentageVal = this.model.companyCommissionRatePercent
      // this.model.clientPenaltyBeforeDeadlinePercent = this.model.companyCommissionRatePercent
      // this.model.clientPenaltyAfterDeadlinePercent = this.model.companyCommissionRatePercent
      // this.model.clientPenaltyPercent = this.model.companyCommissionRatePercent
      // this.model.employeePenaltyPercent = this.model.companyCommissionRatePercent

      this.model.nightShiftStartHour = {id: this.model.nightShiftStartHour, name: this.model.nightShiftStartHour+":00"}
      this.model.nightShiftEndHour = {id: this.model.nightShiftEndHour, name: this.model.nightShiftEndHour+":00"}
    },
    clearForm() {
      this.loadingProcess = ""
    },
  },
}
</script>
<style scoped>
.form-group.has-label {
  padding-top: 0.5rem !important;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#e3e3e3, #e3e3e3);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
}

input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
</style>
