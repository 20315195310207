<template>
  <card shadow type="secondary" class="text-start" body-classes="w-100">
    <template #header>
      {{ $t("Partner's Documents") }}
    </template>

    <div class="row row-cols-3 display-flex">

      <div v-for="item in documents" :key="item">
        <div class="card m-2" style="height: calc(100% - 16px);">
          <div class="card-header">
            {{ $t(item.name) }}
          </div>

<!--        <div class="card-body text-center">-->
<!--          <img src="../../../assets/logo.png" width="50" alt="">-->
<!--        </div>-->
        <div class="card-body text-end">
          <button @click="showEditDoc(item)" class="btn btn-outline-primary btn-sm"><i class="fa fa-pencil"></i></button>
          <button @click="downloadDoc(item.fullPath)" class="btn btn-outline-primary btn-sm ms-2"><i class="fa fa-download"></i></button>
          <button @click="removeDoc(item.fullPath)" class="btn btn-outline-danger btn-sm ms-2 "><i class="fa fa-trash"></i></button>
        </div>
        </div>
      </div>
      <div style="min-height: 100px;">
        <label @click="showUploadDocModal = true" class="card mt-2 text-center center-div p-3" style="height: calc(100% - 16px);cursor: pointer;">

          <i class="fa fa-upload" style="font-size:60px"></i> <br>
          <label>{{ $t("Upload file") }}</label>

          <div v-if="uploading" class="progress mt-2" style="height: 20px; width: 90%" >
            <div class="progress-bar bg-info" role="progressbar"  aria-label="uploading document" :style="'width: '+uploadProgress+'%'" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
          </div>

        </label>
      </div>
    </div>
    <modal id="editDoc" type="mini" :show="showEditDocModal" @close="resetForm">
      <template #header>
        <h5 class="modal-title">
          {{ $t("Edit document") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-12 text-start">
              <div class="form-group has-label">
                <label class="form-control-label">{{ $t('Document type') }}</label>

                <multiselect
                    v-model="editDoc.type"
                    :show-labels="false"
                    track-by="value"
                    label="label"
                    :deselect-label="$t('Cannot remove this value')"
                    :placeholder="$t('Select document type')"
                    :options="docTypes"
                    :searchable="false"
                >
                  <template #option="props">
                    <div class="option__desc">
                      <span class="option__title">{{ $t(props.option.label) }}</span>
                      <span
                          class="option__small float-end">{{ props.option.desc }}</span>
                    </div>
                  </template>
                </multiselect>
                <div v-if="v$.editDoc.type.$error" class="text-danger invalid-feedback" style="display: block;">
                  {{ v$.editDoc.type.$errors.map( err => { return err.$message })[0] }}
                </div>
              </div>
              <!--              <base-input v-model="email" type="mobile" :placeholder="$t('Email address')"></base-input>-->

              <label>{{ $t("Primary documents can only exist once. If an option is disabled remove the existing document to upload a new one.") }}</label>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <base-button @click="submitEdit()">
          {{ $t("Submit") }}
        </base-button>
      </template>
    </modal>
    <modal id="uploadDocs" type="mini" :show="showUploadDocModal" @close="resetForm">
      <template #header>
        <h5 class="modal-title">
          {{ $t("Upload document") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-12 text-start">
              <div class="form-group has-label">
                <label class="form-control-label">{{ $t('Document type') }}</label>

                <multiselect
                    v-model="docType"
                    :show-labels="false"
                    track-by="value"
                    label="label"
                    :deselect-label="$t('Cannot remove this value')"
                    :placeholder="$t('Select document type')"
                    :options="docTypes"
                    :searchable="false"
                >
                  <template #option="props">
                    <div class="option__desc">
                      <span class="option__title">{{ $t(props.option.label) }}</span>
                      <span
                        class="option__small float-end">{{ props.option.desc }}</span>
                    </div>
                  </template>
                </multiselect>
                <div v-if="v$.docType.$error" class="text-danger invalid-feedback" style="display: block;">
                  {{ v$.docType.$errors.map( err => { return err.$message })[0] }}
                </div>
              </div>
              <div class="form-group has-label">
                <label v-if="fileToUpload == null" class="form-group has-label upload-input m-0">
                  <input id="uploadFile" type="file" @change="handleFileUpload($event)" />
                  <i class="fa fa-upload" style="font-size:60px"></i> <br>
                  <span>{{ $t("Upload file") }}</span>
                </label>
                <label v-else>
                  {{ fileToUpload.name }}
                  <base-button class="text-danger" type="link" @click="fileToUpload = null">Remove</base-button>
                </label>
                <div v-if="fileToUploadError != null" class="text-danger invalid-feedback" style="display: block;">
                  {{ $t(fileToUploadError) }}
                </div>
              </div>
<!--              <base-input v-model="email" type="mobile" :placeholder="$t('Email address')"></base-input>-->

              <label>{{ $t("Primary documents can only exist once. If an option is disabled remove the existing document to upload a new one.") }}</label>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <base-button @click="initUploadDoc()">
          {{ $t("Submit") }}
        </base-button>
      </template>
    </modal>
  </card>
</template>

<script>
import {
  deleteObject,
  getStorage,
  ref,
  uploadBytesResumable,
  listAll,
  getDownloadURL,
  uploadBytes,
    getBytes,
    getBlob
} from "firebase/storage"
import Modal from "@/components/Modal.vue"
import useVuelidate from "@vuelidate/core"
import {required} from "@vuelidate/validators"
import BaseButton from "@/components/BaseButton.vue"

export default {
  name: "EmployeeDocuments",
  components: {BaseButton, Modal},
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      showEditDocModal: false,
      editDoc: {type: null},
      showUploadDocModal: false,
      // docTypes: [
      //   { label: "Identity front", value: "identity-front" },
      //   { label: "Identity back", value: "identity-back" },
      //   { label: "Identity front and back", value: "identity-front" },
      //   { label: "Registration", value: "registration" },
      //   { label: "Other", value: "other" },
      // ],
      uploadProgress: null,
      uploading: false,
      docType: null,
      fileToUpload: null,
      fileToUploadError: null,
      documents: []
    }
  },
  computed: {
    docTypes() {
      const docTypes = [
        { label: "Identity front", value: "identity-front" },
        { label: "Identity back", value: "identity-back" },
        { label: "Identity front and back", value: "identity-front" },
        { label: "registration", value: "registration" },
        { label: "registry", value: "registry" },
        { label: "Other", value: "other" },
      ]
      const names = this.documents.map(d => d.name)

      names.forEach(name => {
        const index = docTypes.findIndex(doc => doc.value === name)
        if (index > -1) {
          docTypes[index]["$isDisabled"] = true
          docTypes[index]["desc"] = this.$t("Doc already exists")
        }
      })

      return docTypes
    },
  },
  validations () {
    return {
      docType: {required},
      editDoc: {
        type: {required}
      }
    }
  },
  mounted() {
    this.getFiles()
  },
  methods:{
    getFiles(){
      const storage = getStorage()
      const listRef = ref(storage, "employees/" + this.$route.params.id + "/")

      listAll(listRef)
          .then((res) => {
            res.items.forEach((itemRef) => {
              this.documents.push(itemRef)
            })
          }).catch((error) => {
        console.log("error listing", error)
      })
    },
    removeDoc(path){
      console.log("pathrref", ref)
      this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        confirmButtonColor: "#dc3545",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const storage = getStorage()
          const storageRef = ref(storage, path)
// Delete the file
          deleteObject(storageRef).then(() => {
            // File deleted successfully
            let ind = this.documents.findIndex(d => d.fullPath === path)
            if (ind !== -1){
              this.documents.splice(ind, 1)
            }
            this.$swal.fire({
              title: this.$t("Deleted!"),
              html: this.$t("Document removed."),
              icon:"success",
              timer: 1500,
              timerProgressBar: true
            })
          }).catch((error) => {
            console.error("submitUser error", error)
            this.$swal.fire({
              icon: "error",
              html: result.message,
              title: this.$t("Something went wrong!"),
            })
          })

        }
      })
    },
    async handleFileUpload(e) {
      this.fileToUploadError = null
      console.log(e)
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0]
        // Get file size
        let fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100
        // Get file extension
        let fileExtention = file.name.split(".").pop()
        // Get file name
        let fileName = file.name.split(".").shift()
        // Check if file is an image
        let isImage = ["jpg", "jpeg", "png", "pdg", "pdf", "svg", "webp", "doc", "docx", "csv", "xlsx", "xls", "ods", "pages"].includes(fileExtention)
        // Print to console
        console.log(fileSize, fileExtention, fileName, isImage)
        if (!isImage){
          this.$swal.fire({
            icon: "error",
            html: "If you need to upload this file contact support.",
            title: fileExtention + " is not supported",
          })
          document.getElementById("uploadFile").value = ""
          return
        }

        if (fileSize > 5){

          this.$swal.fire({
            icon: "error",
            html: "File selected is more than 5MB",
            title: "File too big!",
          })
          document.getElementById("uploadFile").value = ""
          return
        }

        this.fileToUpload = file
      } else {
        this.fileToUpload = null
        this.fileToUploadError = "No file selected"
      }
    },
    showEditDoc(doc){
      this.showEditDocModal = true
      console.log(doc)
      doc["type"] = this.docTypes.find(v => v.value === doc.name ) ?? { label: "Other", value: "other" }
      this.editDoc = doc
    },
    async submitEdit() {
      console.log("doc", this.editDoc)
      this.v$.$touch()

      if (this.v$.editDoc.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      if (this.editDoc.name === this.editDoc.type.value) {
        this.showEditDocModal = false
        return
      }

      const storage = getStorage()
      // const storageRef = ref(storage, path)
      //
      // uploadBytes(storageRef, file).then(async (snapshot) => {
      //   console.log('Uploaded a blob or file!')
      //   await deleteObject(storageRef)
      // });
      this.$swal.showLoading()
      try {
        // Reference to the old file
        const oldFileRef = ref(storage, this.editDoc.fullPath)

        // Get the download URL of the old file
        const fileData = await getBlob(oldFileRef)

        // Reference to the new file
        const newFileRef = ref(storage, "employees/" + this.$route.params.id + "/" + this.editDoc.type.value)

        // Upload the file blob to the new location
        await uploadBytes(newFileRef, fileData)

        // Delete the old file
        await deleteObject(oldFileRef)

        console.log(`File renamed from ${this.editDoc.fullPath} to ${newFileRef.fullPath}`)

        const fileIndex = this.documents.findIndex(d => d.fullPath === oldFileRef.fullPath)
        if (fileIndex !== -1) {
          console.log("fileIndex", this.documents[fileIndex])
          this.documents[fileIndex] = newFileRef
        }

      } catch (error) {
        console.error("Error renaming file:", error)
        this.$swal.fire({
          icon: "error",
          html: e.message + " <br> <b>Please contact support.</b>",
          title: "Error renaming file!",
        })
        return
      }
      this.showEditDocModal = false
      this.$swal.fire({
        title: "Updated!",
        showCloseButton: false,
        showConfirmButton: false,
        icon:"success",
        timer: 500,
        timerProgressBar: true
      })
    },
    downloadDoc(path) {
      const storage = getStorage()
      // check if the same name exists
      let storageRef = ref(storage, path)

      getDownloadURL(storageRef).then((downloadURL) => {
        console.log("storageRef", storageRef)
        var downloadLink = document.createElement("a")
        downloadLink.href = downloadURL
        downloadLink.download = storageRef.name
        downloadLink.setAttribute("target", "_blank")
        // Trigger the download
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      }).catch( error => {
        return false
      })
    },
    async genRefDoc(fileName) {
      const storage = getStorage()
      // check if the same name exists
      let storageRef = ref(storage, "employees/" + this.$route.params.id + "/" + fileName)

      let exists = await getDownloadURL(storageRef).then( ref => {
        return true
      }).catch( error => {
        return false
      })
      console.log("exists", exists)
      // if (exists){
      //   return this.genRefDoc(fileName + "_1")
      // } else {
        return storageRef
      // }
    },
    resetForm(){
      this.docType = null
      this.fileToUpload = null
      this.fileToUploadError = null
      this.showUploadDocModal = false
      this.editDoc = {type:null}
      this.showEditDocModal = false
    },
    async initUploadDoc() {

      this.v$.$touch()

      if (this.v$.docType.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      if (this.fileToUpload == null) {
        this.fileToUploadError = "No file selected"
        return
      }
      this.showUploadDocModal = false

      let storageRef = await this.genRefDoc(this.docType.value === "other" ? this.fileToUpload.name : this.docType.value)

      this.uploadDoc(storageRef, this.fileToUpload)
    },
    uploadDoc(storageRef, file) {
      this.uploading = true

      // Upload the file and metadata
      const uploadTask = uploadBytesResumable(storageRef, file)
      uploadTask.on("state_changed",
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            this.uploadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log("Upload is " + this.uploadProgress + "% done")
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused")
                break
              case "running":
                console.log("Upload is running")
                break
            }
          },
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            this.uploading = false
            console.log("uploadTask.snapshot", uploadTask.snapshot)

            let refIndex = this.documents.findIndex( r => r.fullPath === uploadTask.snapshot.ref.fullPath)
            console.log("new doc ref", uploadTask.snapshot.ref.fullPath)
            console.log("docs refffs", this.documents)

            if (refIndex > -1){
              this.documents[refIndex] = uploadTask.snapshot.ref
            } else {
              this.documents.push(uploadTask.snapshot.ref)
            }
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            //   console.log("File available at", downloadURL)
            // })
          })
    }
  }
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

  .center-div {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .upload-input{
    cursor: pointer;
    width: 100%;
    text-align: center;
    background: white;
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
  }
</style>