<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "App",
  head() {
    return {
      title: "AllCare Cyprus"
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  /*margin-top: 60px;*/
}
</style>
<style src="../node_modules/vue-multiselect/dist/vue-multiselect.css"></style>
