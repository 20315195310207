<template>
  <div>
    <base-header type="gradient-primary"  class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <clients-table :title="$t('Client List')"></clients-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientsTable from "../../Tables/ClientsTable"
export default {
  name: "tables",
  components: {
    ClientsTable,
  },
}
</script>
<style></style>
