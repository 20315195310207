import { createRouter, createWebHistory } from "vue-router"

// no auth views
import Home from "../views/Home.vue"
import Search from "../views/Search.vue"
// import AuthLayout from "@/layout/AuthLayout";
import TopLayout from "@/layout/TopLayout"
import Lost from "../views/404.vue"
import Login from "@/views/Login.vue"
import Clients from "@/views/System/Clients/Clients"
import Positions from "@/views/System/Positions/Positions"
import Client from "@/views/System/Clients/Client"
import NewClient from "@/views/System/Clients/NewClient"
import DashboardLayout from "@/layout/DashboardLayout"
import SystemUsers from "@/views/System/SystemUsers"
import NewSystemUser from "@/views/System/NewSystemUser"
import ManageClaims from "@/views/System/ManageClaims"
import Employee from "@/views/System/Employees/Employee"
import NewEmployee from "@/views/System/Employees/NewEmployee"
import Employees from "@/views/System/Employees/Employees"
import Requests from "@/views/Requests/Requests"
import Request from "@/views/Requests/Request"
import RequestCard from "@/views/Requests/RequestDetailsCard"
import Dashboard from "@/views/Dashboard"
import General from "@/views/System/Clients/General"
import ClientUsers from "@/views/System/Clients/Users"
import PositionForm from "@/views/Positions/PositionForm"
import Config from "@/views/System/Settings/SystemConfig.vue"
import ClientRequests from "@/views/System/Clients/Requests"
import ClientConfig from "@/views/System/Clients/ClientConfig.vue"
import EmployeeRequests from "@/views/System/Employees/Requests"
import EmployeeDetailsCard from "@/views/Cards/EmployeeDetailsCard"
// import EmployeePaymentDetailsCard from "@/views/Cards/EmployeePaymentDetailsCard"
// import PaymentCardForm from "@/views/System/Employees/PaymentCardForm"
import EmployeeDocuments from "@/views/System/Employees/EmployeeDocuments"
import EmployeeReviews from "@/views/System/Employees/Reviews.vue"
import RequestForm from "@/views/Requests/RequestForm"
import RequestFormMulti from "@/views/Requests/RequestFormMulti.vue"
import UserForm from "@/views/System/Clients/UserForm"
import Invoices from "@/views/Invoices/Invoices"
import InvoiceForm from "@/views/Invoices/InvoiceForm"
import RequestsSearchTable from "@/views/RequestSearch/RequestsSearchTable"
import NormalUserRequestView from "@/views/Requests/NormalUserRequestView"
import MyRequests from "@/views/Employee/MyRequests"
import Profile from "@/views/Profile"

const routes = [
  {
    path: "/admin",
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: { role: ["SYSTEM_USER"] },
        components: { default: Dashboard },
      },
      {
        path: "/systemUsers",
        name: "System users",
        meta: { role: ["SYSTEM_USER"] },
        components: { default: SystemUsers },
      },
      {
        path: "/systemUsers/new",
        name: "new System users",
        meta: { role: ["SYSTEM_USER"] },
        components: { default: NewSystemUser },
      },
      {
        path: "/systemUser/:id",
        name: "System user",
        meta: { role: ["SYSTEM_USER"] },
        components: { default: NewSystemUser },
        props: true
      },
      {
        path: "/requests",
        name: "Requests",
        meta: { role: ["SYSTEM_USER", "CLIENT"] },
        components: { default: Requests },
      },
      {
        path: "/requests/new",
        name: "newRequest",
        meta: { role: ["SYSTEM_USER", "CLIENT"] },
        components: { default: RequestForm },
        props: true
      },
      {
        path: "/requests/multiCreate",
        name: "newMultiRequest",
        meta: { role: ["SYSTEM_USER", "CLIENT"] },
        components: { default: RequestFormMulti },
        props: true
      },
      {
        path: "/requests/:id",
        name: "Request",
        // redirect: to => {
        //   // the function receives the target route as the argument
        //   // we return a redirect path/location here.
        //   return { path: "requests/"+to.params.id+"/info"}
        // },
        meta: { role: ["SYSTEM_USER", "EMPLOYEE", "CLIENT"] },
        components: { default: Request },
        // children: [
        //     {
        //       path: "info",
        //       name: "requestInfo",
        //       meta: { role: ["SYSTEM_USER"] },
        //       components: { default: RequestCard },
        //       props: true
        //   }
        //   ]
      },
      {
        path: "/invoices",
        name: "Invoices",
        meta: { role: ["SYSTEM_USER", "EMPLOYEE"] },
        children: [
          {
            path: "",
            name: "invoiceList",
            meta: { role: ["SYSTEM_USER", "EMPLOYEE"] },
            components: { default: Invoices },
            children: [
            ]
          },
          {
            path: "new",
            name: "newInvoice",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: InvoiceForm },
            props: true
          },
          // {
          //   path: ":id",
          //   name: "invoiceRecord",
          //   meta: { role: ["SYSTEM_USER"] },
          //   components: { default: PositionForm },
          // },
          // {
          //   path: ":id/edit",
          //   name: "editInvoiceRecord",
          //   meta: { role: ["SYSTEM_USER"] },
          //   components: { default: PositionForm },
          // },
        ]
      },
      {
        path: "/positions",
        name: "Positions",
        meta: { role: ["SYSTEM_USER"] },
        children: [
          {
            path: "",
            name: "positionList",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: Positions },
            children: [
              {
                path: "new",
                name: "newPosition",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: PositionForm },
                props: true
              },
              {
                path: ":id",
                name: "positionRecord",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: PositionForm },
              },
              {
                path: ":id/edit",
                name: "editPositionRecord",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: PositionForm },
              },
            ]
          },
        ]
      },
      {
        path: "/clients",
        meta: { role: ["SYSTEM_USER"] },
        children: [
          {
            path: "",
            name: "clientList",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: Clients },
          },
          {
            path: "new",
            name: "newClient",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: NewClient },
          },
          {
            path: ":id",
            name: "ClientRecord",
            meta: { role: ["SYSTEM_USER", "EMPLOYEE"] },
            components: { default: Client },
            redirect: to => {
              // the function receives the target route as the argument
              // we return a redirect path/location here.
              return { path: "/clients/"+to.params.id+"/info"}
            },
            props: true,
            children: [
              {
                path: "info",
                name: "clientGeneral",
                meta: { role: ["SYSTEM_USER", "EMPLOYEE"] },
                components: { default: General },
                props: true
              },
              {
                path: "users",
                name: "clientUsers",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: ClientUsers },
                props: true,
                children: [
                  {
                    path: "new",
                    name: "newClientUser",
                    meta: { role: ["SYSTEM_USER"] },
                    components: { default: UserForm },
                    props: true
                  },
                    {
                      path: ":userId/edit",
                      name: "editClientUser",
                      meta: { role: ["SYSTEM_USER"] },
                      components: { default: UserForm },
                      props: true
                    }
                  ]
              },
              {
                path: "requests",
                name: "clientRequests",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: ClientRequests },
                props: true
              },
              {
                path: "config",
                name: "clientConfig",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: ClientConfig },
                props: true
              },
            ]
          },
          {
            path: ":id/edit",
            name: "editClient",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: NewClient },
          },
        ]
      },
      {
        path: "/configuration",
        name: "config",
        meta: { role: ["SYSTEM_USER"] },
        components: { default: Config },
      },
      {
        path: "/employees",
        name: "Employee",
        meta: { role: ["SYSTEM_USER"] },
        children: [
          {
            path: "",
            name: "employeesList",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: Employees },
          },
          {
            path: "new",
            name: "newEmployee",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: NewEmployee },
          },
          {
            path: ":id",
            name: "EmployeeRecord",
            meta: { role: ["SYSTEM_USER"] },
            redirect: to => {
              // the function receives the target route as the argument
              // we return a redirect path/location here.
              return { path: "/employees/"+to.params.id+"/info"}
            },
            components: { default: Employee },
            children: [
                {
                  path: "info",
                  name: "employeeGeneral",
                  meta: { role: ["SYSTEM_USER"] },
                  components: { default: EmployeeDetailsCard },
                  props: true
                },
              {
                path: "documents",
                name: "employeeDocuments",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: EmployeeDocuments },
                props: true,
                // children: [
                //   {
                //     path: "upload",
                //     name: "uploadDocs",
                //     meta: { role: ["SYSTEM_USER"] },
                //     components: { default: PaymentCardForm },
                //     props: true,
                //   },
                // ]
              },
              {
                path: "requests",
                name: "employeeRequests",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: EmployeeRequests },
                props: true
              },
              {
                path: "reviews",
                name: "employeeReviews",
                meta: { role: ["SYSTEM_USER"] },
                components: { default: EmployeeReviews },
                props: true
              },
                // {
                //   path: "paymentDetails",
                //   name: "employeePaymentDetails",
                //   meta: { role: ["SYSTEM_USER"] },
                //   components: { default: EmployeePaymentDetailsCard },
                //   props: true,
                //   children: [
                //     {
                //       path: "edit",
                //       name: "editPaymentCard",
                //       meta: { role: ["SYSTEM_USER"] },
                //       components: { default: PaymentCardForm },
                //       props: true,
                //     },
                //   ]
                // },
              // {
              //   path: "invoices",
              //   name: "employeeInvoices",
              //   meta: { role: ["SYSTEM_USER"] },
              //   components: { default: EmployeeRequests },
              //   props: true
              // }
              ]
          },
          {
            path: ":id/edit",
            name: "editEmployee",
            meta: { role: ["SYSTEM_USER"] },
            components: { default: NewEmployee },
          },
        ]
      },
      {
        path: "/manage-claims",
        name: "manage-claims",
        meta: { role: ["SYSTEM_USER"] },
        components: { default: ManageClaims },
      },

      {
        path: "/profile/edit",
        name: "editEmployeeProfile",
        meta: { role: ["EMPLOYEE"] },
        components: { default: NewEmployee },
      },
      {
        path: "/account",
        name: "clientAccount",
        meta: { role: ["CLIENT"] },
        components: { default: Client },
        redirect: to => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return { path: "/account/info"}
        },
        props: true,
        children: [
          {
            path: "info",
            name: "accountGeneral",
            meta: { role: ["CLIENT"] },
            components: { default: General },
            props: true
          },
          {
            path: "users",
            name: "clientAccountUsers",
            meta: { role: ["CLIENT"] },
            components: { default: ClientUsers },
            props: true,
            children: [
              {
                path: ":id/edit",
                name: "editClientAccountUser",
                meta: { role: ["CLIENT"] },
                components: { default: UserForm },
                props: true
              }
            ]
          }
          ]
      },
      {
        path: "/account/edit",
        name: "editClientAccount",
        meta: { role: ["CLIENT"] },
        components: { default: NewClient },
      },
      {
        path: "/profile",
        name: "userProfile",
        meta: { role: ["EMPLOYEE"] },
        redirect: to => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return { path: "/profile/info"}
        },
        components: { default: Profile },
        children: [
          {
            path: "info",
            name: "employeeProfile",
            meta: { role: ["EMPLOYEE"] },
            components: { default: EmployeeDetailsCard },
          },
          {
            path: "documents",
            name: "employeeProfileDocuments",
            meta: { role: ["EMPLOYEE"] },
            components: { default: EmployeeDocuments },
            // children: [
            //   {
            //     path: "upload",
            //     name: "uploadDocs",
            //     meta: { role: ["SYSTEM_USER"] },
            //     components: { default: PaymentCardForm },
            //     props: true,
            //   },
            // ]
          },
        ]
      },
      {
        path: "/find-a-shift",
        name: "findAShift",
        meta: { role: "EMPLOYEE" },
        components: { default: RequestsSearchTable },
        props: true
      },
      {
        path: "/my-requests",
        name: "myRequests",
        meta: { role: "EMPLOYEE" },
        components: { default: MyRequests },
        props: true
      },
      // {
      //   path: "/requests/:id",
      //   name: "Request",
      //   meta: {role: ["EMPLOYEE"]},
      //   components: {default: NormalUserRequestView}
      // }
      // {
      //   path: "/invoices",
      //   name: "Invoices",
      //   meta: { role: "OWNER" },
      //   components: { default: Invoices },
      // },
      // {
      //   path: "/invoices/new",
      //   name: "new invoice",
      //   components: { default: NewInvoice },
      // },
    ],
  },
  {
    path: "/",
    component: TopLayout,
    children: [
      {
        path: "/",
        name: "home",
        components: { default: Home },
      },
      {
        path: "/login",
        name: "login",
        components: { default: Login },
        props: true
      },
      {
        path: "/:pathMatch(.*)*",
        name: "lost",
        components: { default: Lost },
      },
      {
        path: "/error",
        name: "error",
        components: { default: Lost },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
})

export default router
