<template>
  <card shadow type="secondary">
    <template #header>
      <div class="bg-white border-0">
        <div class="row align-items-center">
          <div class="col-8 text-start">
            <h5 class="mb-0">{{ $t("Analytics") }}</h5>
          </div>
          <div class="col-4 text-end">
            <router-link :to="'/clients/'+clientData.id+'/requests'" class="btn btn-primary btn-sm mt-1 mb-1">
              <span class="nav-link-text">{{ $t("View All") }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </template>


      <div class="pl-lg-4 text-start">
        <div class="fw-bolder text-uppercase text-muted mt-4 text-start mt-4">{{ $t("Requests") }}</div>
        <hr class="m-0">
        <div class="row">
          <div class="col-md-6 pt-2">
            <display-label
                label="Open:"
                value="Coming soon"
            ></display-label>
          </div>
          <div class="col-md-6 pt-2">
            <display-label
                label="Closed:"
                value="Coming soon"
            ></display-label>
          </div>
        </div>
      </div>

  </card>
</template>
<script>
import DisplayLabel from "@/components/DisplayLabel"
export default {
  name: "client-requests-analytics-card",
  components: {DisplayLabel},
  props: {
    clientData: {
      type: Object,
      description: "Client data",
    },
  },
  data() {
    return {
      isDisabled: true,
      alertMessage: "",
      loadingProcess: "",
      model: {
        description: "asas",
        address: {
          street: "",
          countryCode: "",
          city: "",
          lat: 0,
          long: 0 ,
          postalCode: "",
          street2: "",
        },
      }
    }
  },
  watch: {
    clientData(newVal) {
      console.log("newVal", newVal)
      this.model = newVal
    },
  },
  mounted() {
    // var self = this;
    console.log(this.$route.params)
    console.log("clientData card", this.clientData)
    // this.mapCompany();
  },
  methods: {
  },
}
</script>
<style></style>
