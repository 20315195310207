<template>
  <base-nav
      class="navbar-top navbar-dark"
      :show-toggle-button="false"
      type=""
      effect="light"
      expand
  >
    <template #brand>
      <div style="float: left;">
        <button class="btn text-white btn-link text-decoration-none" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
          {{ $t("Back") }}
        </button>
      </div>
    </template>
    <div>
      <div class="btn-group language-changer" style="    display: inline-block;">
        <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa fa-globe"></i> {{ locale.label }}
        </button>
        <ul class="dropdown-menu">
          <li class="text-center"><button class="dropdown-item" :class="{'active' : locale.value === 'en'}" @click="setLocale('en')" type="button">En</button></li>
          <li><hr class="dropdown-divider" style="margin: 0px;"></li>
          <li class="text-center"><button @click="setLocale('el')" class="dropdown-item" type="button">Ελ</button></li>


        </ul>
      </div>

    <span class="dropdown" style="display: inline-block;vertical-align: middle;">
      <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" id="navbarDarkDropdownMenuLink" aria-label="expand navigation" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa fa-user-circle text-white" style="font-size: 25px"></i>
        <span class="mb-0 ps-1 text-white mr-1 font-weight-bold">{{ $store.state.user.data == null ? "" : $store.state.user.data.firstName }}</span>

      </a>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDarkDropdownMenuLink">
        <li v-if="$store.state.user.claims.role && ['EMPLOYEE'].includes($store.state.user.claims.role)">
          <a class="dropdown-item" href="/profile">
            <i class="fa fa-user pe-2" style="font-size: 15px;"></i>Profile
          </a>
        </li>
        <!--        <li><a class="dropdown-item" href="#">Another action</a></li>-->
        <li>
          <a class="dropdown-item" @click="signOut">
            <i class="fa fa-sign-out-alt pe-2" style="font-size: 15px;"></i>
            <b>Log Out</b>
          </a>
        </li>
      </ul>
      </span>
    </div>
  </base-nav>

</template>
<script>
import BaseNav from "../components/BaseNav"
import BaseDropdown from "../components/BaseDropdown"
// import CloseButton from "../components/CloseButton";
export default {
  components: {
    BaseNav,
    // CloseButton,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      locale: "",
      locales: [
        {label: "EN", value: "en"},
        {label: "Ελ", value: "el"},
        // {label: 'Russian', value: 'ru'}
      ],
    }
  },
  mounted() {
    this.setLocaleLabel()
  },
  methods: {
    signOut() {
      let res = this.$store.dispatch("signOut")
      // console.log(res)
    },
    setLocaleLabel(){
      let localeSelected = this.locales.find( l => l.value === this.$store.state.locale)

      if(localeSelected){
        this.locale = localeSelected
      }
    },
    setLocale (locale) {
      // this.$store.state.locale = value
      // console.log(locale.toLowerCase())
      this.$i18n.locale = locale.toLowerCase()
      this.$store.dispatch("setLocale", locale.toLowerCase())
      // without this line it didn't work
      this.$moment.locale(locale.toLowerCase())
      this.setLocaleLabel()
    },
  },
}
</script>


<style>
.navbar-collapse {
  flex-grow: 0 !important;
}

.dropdown-toggle::after{
  display: none !important;
}
</style>