<template>
<div class="rating" :class="{'noPointerEvents': disabled}">
    <input :id="$.uid+'5'" :disabled="disabled" :checked="innerRating === 5" type="radio" :name="$.uid+'rating'" value="5" @change="innerRating = 5" v-on="listeners" />
    <label class="star" :for="$.uid+'5'" title="Awesome" aria-hidden="true"></label>
    <input :id="$.uid+'4'" :disabled="disabled" :checked="innerRating === 4" type="radio" :name="$.uid+'rating'" value="4" @change="innerRating = 4" v-on="listeners" />
    <label class="star" :for="$.uid+'4'" title="Great" aria-hidden="true"></label>
    <input :id="$.uid+'3'" :disabled="disabled" :checked="innerRating === 3" type="radio" :name="$.uid+'rating'" value="3" @change="innerRating = 3" v-on="listeners" />
    <label class="star" :for="$.uid+'3'" title="Very good" aria-hidden="true"></label>
    <input :id="$.uid+'2'" :disabled="disabled" :checked="innerRating === 2" type="radio" :name="$.uid+'rating'" value="2" @change="innerRating = 2" v-on="listeners" />
    <label class="star" :for="$.uid+'2'" title="Good" aria-hidden="true"></label>
    <input :id="$.uid+'1'" :disabled="disabled" :checked="innerRating === 1" type="radio" :name="$.uid+'rating'" value="1" @change="innerRating = 1" v-on="listeners" />
    <label class="star" :for="$.uid+'1'" title="Bad" aria-hidden="true"></label>
    <div v-if="required && isInvalid" class="text-danger invalid-feedback" style="display: block;">
      {{ $t("This field is required.") }}
    </div>
  </div>
</template>

<script>
  export default {
    name: "base-rating",
    components: {
    },
    props: {
        modelValue: {
          type: [String, Number],
          description: "Input value",
        },
      required: {
        type: [Boolean],
        default: false,
        description: "Defines if rating is required",
      },
      isInvalid: {
        type: [Boolean,  null],
        default: null,
        description: "Defines if input is invalid",
      },
      disabled: {
        type: [Boolean],
        default: false,
        description: "Defines if input is disabled",
      }
    },
emits: ["updateState", "update:modelValue"],
    data() {
      return {
        innerRating: 0,
        id: null
      }
    },
    computed: {
      listeners() {
        return {
          input: this.updateValue,
        }
      },
    },
    watch: {
      modelValue(newVal) {
        this.innerRating = newVal
      },
    },
    mounted() {
      this.innerRating = this.modelValue
    },
    methods: {
        updateValue(evt) {
          let value = evt.target.value

          this.$emit("updateState", false)
          this.$emit("update:modelValue", value)
        },
      }
    }
</script>

<style scoped>

.rating {
  //margin-top: 40px;
  border: none;
  float: left;
}

.rating > label {
  color: #90A0A3;
  float: right;
}

.rating > label:before {
  margin: 5px;
  font-size: 2em;
  font-family: FontAwesome;
  content: "\f005";
  display: inline-block;
}

.rating > input {
  display: none;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #F79426;
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
  color: #FECE31;
}
.rating.noPointerEvents {
  pointer-events: none;
}
</style>