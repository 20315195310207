<template>
  <div>
    <div class="btn-group" role="group" aria-label="invoice actions">
<!--      <base-button v-if="true" type="button" tag="a" :href="'/invoices/' + (invoiceData.hasOwnProperty('id') ? invoiceData.id : '')" class="btn btn-primary btn-sm fw-bold">View</base-button>-->
      <button v-if="canMarkAsPaid && userRole === 'SYSTEM_USER'" type="button" class="btn bg-gradient-green text-white btn-sm" @click="markAsPaidModal = true">
        {{ $t("Mark Paid") }}</button>
      <button v-if="canMarkAsPending" type="button" class="btn bg-warning text-white btn-sm" @click="markPendingInvoice()">
        {{ $t("Mark Pending") }}</button>
      <button v-if="canMarkAsPaid && userRole === 'SYSTEM_USER'" type="button" class="btn bg-gradient-red text-white btn-sm" @click="cancelInvoice()">
        {{ $t("Cancel") }}</button>
      <button v-if="canSendInvoice" type="button" class="btn btn-primary btn-sm" @click="trySendInvoice">
        <i class="fa fa-paper-plane"></i></button>
      <button v-if="canPayInvoice" type="button" class="btn text-white bg-gradient-green btn-sm" @click="stripeCheckout()">
        {{ $t("Pay Invoice") }}</button>
      <button v-if="canDownloadInvoice" type="button" class="btn btn-info btn-sm" @click="downloadInvoice(invoiceData.id, invoiceData.invoiceRef, invoiceData.employeeId)">
        <i class="fa fa-download"></i> </button>

    </div>

    <modal id="noShowModal" type="mini" :show="markAsPaidModal" @close="markAsPaidModal = false">
      <template #header>
        <h5 class="modal-title">
          {{ $t("Mark as paid") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-12 text-start has-label" :class="{'has-danger': v$.paymentMethod.$error }">
              <label aria-label="Payment method" class="form-label fw-bold m-0">{{ $t("Payment method*") }}</label>
              <multiselect
                  v-model="paymentMethod"
                  :options="[{key:'CARD', value: 'Card'}, {key:'CASH', value: 'Cash'}, {key:'BANK_TRANSFER', value: 'Bank Transfer'}]"
                  placeholder="Select"
                  :show-labels="false"
                  label="value"
                  required
                  track-by="key"
                  class=""
                  :error="v$.paymentMethod.$errors.map( err => { return err.$message })[0]"
></multiselect>
              <div v-if="v$.paymentMethod.$invalid" class="text-danger invalid-feedback" style="display: block;">
                {{ v$.paymentMethod.$errors.map( err => { return err.$message })[0] }}
              </div>
              </div>
            <div class="col-12 text-start has-label pt-2">
              <label class="form-label fw-bold m-0">{{ $t("Payment provider") }}</label>
              <multiselect
                  v-model="paymentProvider"
                  :options="[{key:'REVOLUT', value: 'Revolut'}, {key:'STRIPE', value: 'Stripe'}]"
                  :placeholder="$t('Select')"
                  :show-labels="false"
                  label="value"
                  required
                  track-by="key"
              ></multiselect>
</div><div class="col-12 text-start has-label pt-2">
              <base-input
                  v-model="transactionId"
                  alternative=""
                  :label="$t('Transaction ID')"
                  :placeholder="$t('Transaction ID')"
                  input-classes="form-control-alternative"
                >
</base-input>
</div>
          </div>
        </div>
      </div>
      <template #footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="markAsPaid()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
    <modal id="cancellationModal" type="mini" :show="sendInvoiceModal" @close="sendInvoiceModal = false">
      <template #header>
        <h5 class="modal-title">
          {{ $t("Send Invoice") }}
        </h5>
      </template>
      <div>
        <div class="pl-lg-6">
          <div class="row">
            <div class="col-lg-12 text-start">
<label class="form-label small text-muted">{{ $t("E-mail address") }}</label>
                <base-input v-model="email" type="mobile" :placeholder="$t('Email address')"></base-input>
                <div v-if="v$.email.$invalid" class="text-danger invalid-feedback" style="display: block;">
                  {{ v$.email.$errors.map( err => { return err.$message })[0] }}
                </div>
                <label>{{ $t("Write an email address to send this invoice") }}</label>
            </div>
</div>
        </div>
      </div>
      <template #footer>
        <submit-button
            :alert-message="errorMessage"
            :state="loadingProcess"
            @clicked="sendInvoice()"
        >
          {{ $t("Submit") }}
        </submit-button>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal"
import useVuelidate from "@vuelidate/core"
import {required, email} from "@vuelidate/validators"
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import BaseInput from "@/components/BaseInput"
import Stripe from "stripe"
import {auth, stripeSk} from "@/main"
import {getAnalytics, logEvent} from "firebase/analytics"
export default {
  name: "InvoiceActions",
  components: {BaseInput, Modal},
  props: {
    invoiceData: {
      type: Object,
      description: "invoiceData",
    },
  },
  emits: ["fetch", "updateStatus"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      employeePrivate: { email: ""},
      sendInvoiceModal: false,
      markAsPaidModal: false,
      today: new Date(),
      model: {},
      errorMessage: "",
      email: "" ,
      loadingProcess: "",
      cancelModal: false,
      requester: null,
      reason: null,
      paymentMethod: "",
      transactionId: null,
      paymentProvider: null
    }
  },
  validations () {
    return {
      paymentMethod: {
        required
      },
      email: {
        required, email
      }
    }
  },
  computed: {
    canPayInvoice(){
      return this.userRole === "EMPLOYEE" && this.invoiceData.status === "PENDING"
    },
    canMarkAsPending(){
      if (this.userRole !== "SYSTEM_USER" || !this.invoiceData.hasOwnProperty("status")){
        return false
      }

      return ["PAID", "CANCELED"].includes(this.invoiceData.status)
    },
    canMarkAsPaid(){
      if (!this.invoiceData.hasOwnProperty("status")){
        return false
      }
      return this.invoiceData.status === "PENDING"
    },
    canDownloadInvoice(){
      return ["SYSTEM_USER", "EMPLOYEE"].includes(this.userRole)
    },
    canSendInvoice(){
      return this.userRole === "SYSTEM_USER"
    },
    userRole(){
      return this.$store.state.user.claims.role ?? null
    },
  },
  watch: {
    requestData(newVal) {
      // console.log("newVal", newVal)
      this.model = newVal
    },
  },
  methods: {
    async downloadInvoice(invoiceId, invoiceRef, employeeId) {
      const axios = require("axios")
      const user = auth.currentUser
      if (user == null) {
        alert("sorry cont do this right now")
        return
      }

      const analytics = getAnalytics()
      logEvent(analytics, "click", {
        item_id: "download",
        page_title: this.$route.name
      })

      const token = await user.getIdToken()
      const functionUrl = FirebaseFunctionsHelper.getFunctionsUrl("download-invoice")
      const config = {
        method: "get",
        url: functionUrl+"?employeeId="+employeeId+"&invoiceId="+invoiceId,
        responseType: "blob",
        headers: {
          "Authorization": "Bearer " + token
        }
      }
      this.$swal.showLoading()
      const self = this
      axios(config)
          .then(function (response) {
            console.log(response)
            const href = URL.createObjectURL(response.data)

            // create "a" HTML element with href to file & click
            const link = document.createElement("a")
            link.href = href
            link.setAttribute("download", "INVOICE-"+invoiceRef+".pdf") //or any other extension
            document.body.appendChild(link)
            link.click()
            self.$swal.close()
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
          })
          .catch(function (error) {
            self.$swal.close()
            console.log(error)
          })

    },
    markPendingInvoice(){
      this.$swal.fire({
        title: "Are you sure?",
        text: "Mark this invoice as pending.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#dc3545",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.showLoading()
          this.submit("invoice-pending", {
            invoiceId: this.invoiceData.id,
            employeeId: this.invoiceData.employeeId
          }, "PENDING")
        }
      })
    },
    cancelInvoice(){
      this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel invoice!",
        confirmButtonColor: "#dc3545",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.showLoading()
          this.submit("invoice-cancel", {
            invoiceId: this.invoiceData.id,
            employeeId: this.invoiceData.employeeId
          }, "CANCELED")
        }
      })
    },
    async stripeCheckout() {
      this.$swal.showLoading()
      const userPrivate = await this.$store.dispatch("fetchEmployeePrivateInfo", this.$store.state.user.claims.user_id)

      try {

        const stripe = new Stripe(stripeSk, {
          apiVersion: "2023-08-16",
        })

        let products = []
        this.invoiceData.products.forEach(product => {
          const prod = {
            price: (product.price / 100).toString(),
            quantity: 1
          }
          products.push(prod)
        })

        let price = await stripe.prices.create({
          currency: "eur",
          unit_amount: this.invoiceData.amount.total,
          product: process.env.VUE_APP_STRIPE_SHIFT_PRODUCT
        })

        const params = {
          success_url: window.location.origin + "/invoices",
          cancel_url: window.location.origin + "/invoices",
          mode: "payment",
          line_items: [{
            quantity: 1,
            price: price.id
          }],
          custom_text: {
            submit: {
              message: "The invoice will be marked as paid as soon as we confirm the payment."
            }
          },
          payment_intent_data: {
            metadata: {
              userId: this.$store.state.user.claims.user_id,
              phoneNumber: this.$store.state.user.claims.phone_number,
              invoiceId: this.invoiceData.id,
              invoiceRef: this.invoiceData.invoiceRef
            },
          }
        }

        if (userPrivate.hasOwnProperty("email") && userPrivate.email !== null) {
          params.customer_email = userPrivate.email
        }

        let checkoutSession = await stripe.checkout.sessions.create(params)
        window.location.href = checkoutSession.url
        // console.log("result", result)

      } catch (e) {
        this.$swal.hideLoading()
        this.$swal.fire({
          icon: "error",
          html: e.message + " <br> <b>Please contact support.</b>",
          title: "Something went wrong!",
        })
      }
    },
    async trySendInvoice() {
      await this.getEmployeeAddress()
      if (this.userRole === "SYSTEM_USER") {
        this.sendInvoiceModal = true
      } else if (this.userRole === "EMPLOYEE") {
        this.sendInvoice()
      }
    },
    sendInvoice(){
      this.v$.$touch()

      if (this.v$.email.$invalid) {
        console.log(this.v$.$errors)
        return
      }
      this.loadingProcess = "processing"
      FirebaseFunctionsHelper.callFunction("invoice-send", {
        email: this.email,
        employeeId: this.invoiceData.employeeId,
        invoiceId: this.invoiceData.id
      })
          .then((result) => {
            // console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              // console.log("result message", result.message)
              this.loadingProcess = "failed"
              this.errorMessage = result.message
              return
            }
            this.loadingProcess = "done"
            this.sendInvoiceModal = false
            this.$swal.fire({
              title: "Sent!",
              html: "Invoice sent.",
              icon:"success",
              timer: 1000,
              timerProgressBar: true
            })
          })
          .catch((error) => {
            this.loadingProcess = "failed"
            this.errorMessage = error.message
            console.log("submitUser error", error)
          })

    },
    markAsPaid(){
      this.v$.$touch()

      if (this.v$.paymentMethod.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      this.loadingProcess = "processing"
      let payload = {
        paymentMethod: this.paymentMethod.key,
        employeeId: this.invoiceData.employeeId,
        invoiceId: this.invoiceData.id,
        paymentProvider: this.paymentProvider != null ? this.paymentProvider.key : null,
        transactionId: this.transactionId
      }
      // console.log("payload", payload)
      this.submit("invoice-pay", payload)
    },
    submit(name, data, afterStatus = "PAID"){
      FirebaseFunctionsHelper.callFunction(name, data)
          .then((result) => {
            // console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              // console.log("result message", result.message)
              this.loadingProcess = "failed"
              this.errorMessage = result.message
              return
            }
            this.loadingProcess = "done"
            this.markAsPaidModal = false
            this.$emit("updateStatus", this.invoiceData.id, afterStatus)
            this.$swal.hideLoading()
            this.restMarkAsPaid()
          })
          .catch((error) => {
            this.loadingProcess = "failed"
            this.errorMessage = error.message
            this.$swal.hideLoading()
            console.log("submitUser error", error)
          })

    },
    restMarkAsPaid(){
      this.paymentProvider = null
      this.paymentMethod = null
      this.transactionId = null
    },
    async getEmployeeAddress() {
      await this.$store.dispatch("fetchEmployeePrivateInfo", this.invoiceData.employeeId).then((data) => {
        // console.log("fetchEmployeePrivateInfo", data)
        this.employeePrivate = data
        this.email = this.employeePrivate.email
      })
    },
  }
}
</script>

<style scoped>

</style>