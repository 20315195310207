<template>
  <div class="container">
    <div class="row justify-content-center p-5">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <h2>{{ $t(title) }}</h2>
          </div>
          <div v-if="type == null">
            <div class="d-grid gap-2">
              <base-button type="primary" @click="type='employee'">{{ $t("Partners Portal") }}</base-button>
              <base-button type="primary" @click="type='client'">{{ $t("Organizations Portal") }}</base-button>
            </div>
          </div>
          <email-password v-if="['client', 'admin'].includes(type)"></email-password>
          <phone-auth v-if="['employee'].includes(type)"></phone-auth>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import EmailPassword from "@/components/Login/EmailPassword"
import PhoneAuth from "@/components/Login/PhoneAuth"
import BaseButton from "@/components/BaseButton"
export default {
  name: "adminLogin",
  components: {BaseButton, PhoneAuth, EmailPassword},
  data() {
    return {
      type: null,
      errorMessage: "",
      loadingProcess: "",
      model: {
        email: "",
        password: "",
      },
    }
  },
  computed: {
    title(){
      switch (this.type) {
        case "employee":
        case "client":
          return this.$t("Login")
        default:
          return this.$t("Welcome")
      }
    }
  },
  mounted() {
    if (this.$route.query.hasOwnProperty("type")){
      this.type = this.$route.query.type
    }
  },
  methods: {

  },
}
</script>
<style>
  span.input-group-text {
    height: 38px !important;
    border-radius: 0 !important;
  }
</style>
