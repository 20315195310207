<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="row">
      <div class="col text-start">
        <slot>
          <div v-if="title" class="card-title text-uppercase text-muted fw-bolder mb-0 text-sm">
            {{ title }}
          </div>
          <template v-if="loading">
            <span  class="spinner-border spinner-border-sm" aria-hidden="true"></span>
          </template>
          <template v-else>
            <template v-if="subTitleIcon">
              <i
                v-for="i in 5"
                :key="i"
                :class="' fw-bolder mb-0 ' + subTitleIcon"
                :style="
                  'color:' +
                  (i <= subTitleIconCount ? subTitleIconColor : 'lightGray')
                "
              ></i>
            </template>
            <span class="h5 fw-bolder mb-0" v-if="subTitle != null">{{
              subTitle
            }}</span>
          </template>
        </slot>
      </div>

      <div class="col-auto" v-if="$slots.icon || icon">
        <slot name="icon">
          <div
            class="icon icon-shape text-white rounded-circle shadow"
            :class="[`bg-${type}`, iconClasses]"
          >
            <i :class="icon"></i>
          </div>
        </slot>
      </div>
    </div>

    <p class="mt-3 mb-0 text-sm">
      <slot name="footer"> </slot>
    </p>
  </card>
</template>
<script>
import Card from "./Card.vue"

export default {
  name: "stats-card",
  components: {
    Card,
  },
  props: {
    type: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false
    },
    icon: String,
    title: String,
    subTitle: [String, Number],
    subTitleIcon: String,
    subTitleIconColor: String,
    subTitleIconCount: Number,
    iconClasses: [String, Array],
  },
}
</script>
<style></style>
