<template>
  <div>
    <base-header type="gradient-primary"  class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
      <config-cards></config-cards>
    </base-header>

    <div class="container-fluid mt--7">

      <div class="row">
        <div class="col">
          <positions-table title="Positions List"></positions-table>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PositionsTable from "@/views/Tables/PositionsTable"
import ConfigCards from "@/views/System/Settings/ConfigCards"
export default {
  name: "tables",
  components: {ConfigCards, PositionsTable},
  data() {
    return {
    }
  },
  async mounted() {
  },
  methods: {
  }
}
</script>
<style></style>
