<template>
  <div class="" style="margin: 0">
    <div class="container not-found">
      <h1>404 - Page Not Found</h1>
      <p>Are you lost?</p>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>Return to the <a href="/">homepage</a>.</p>
    </div>
</div>
</template>
<script>
export default {
  name: "404Page",
  components: { },
  props: {
    // desc: {
    //   type: String,
    //   default: "The page you are looking for does not exist.",
    //   description: "error description",
    // },
  },
  data() {
    return {
      desc: "The page you are looking for does not exist.",
      showModal: false
    }
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if(this.$route.params.hasOwnProperty("desc")) {
      this.desc = this.$route.params.desc
    }
  }
}
</script>

<style>

.container.not-found {
  text-align: center;
  margin-top: 50px;
}

.container.not-found h1 {
  font-size: 3em;
  color: #333;
}

.container.not-found p {
  font-size: 1.2em;
  color: #666;
}

</style>