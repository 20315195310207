import { createSSRApp } from "vue"
import App from "./App.vue"
import { store } from "./store"
import router from "./router"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { initializeApp } from "firebase/app"
import { getAnalytics, setUserId } from "firebase/analytics"
import { getFirestore } from "firebase/firestore"

import GlobalComponents from "./helpers/globalComponents"
import Vue3EasyDataTable from "vue3-easy-data-table"
import "vue3-easy-data-table/dist/style.css"
// import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import "./assets/@fortawesome/fontawesome-free/css/all.min.css"
import VueDatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import moment from "moment"
import i18n from "./lang/lang.js"
import Multiselect from "vue-multiselect"
import "./assets/scss/style.scss"
import SidebarPlugin from "@/components/SidebarPlugin"
import { initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check"
import {createHead, VueHeadMixin} from "@unhead/vue"


let firebaseConfig
if (process.env.NODE_ENV === "production") {
    firebaseConfig = require("../prodConfig.json")
} else {
    firebaseConfig = require("../devConfig.json")
}

export const stripeSk = firebaseConfig.stripeSk
export const fireApp = initializeApp(firebaseConfig)

let site_key= ""
if (process.env.NODE_ENV === "production") {
    site_key = "6LdXUa4nAAAAAOZ-yXhNF5Ve4pLci45aNIJzp7qw"
} else {
    site_key = "6LdXUa4nAAAAAOZ-yXhNF5Ve4pLci45aNIJzp7qw"
}

initializeAppCheck(fireApp, {
    provider: new ReCaptchaV3Provider(site_key),
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: false,
})

export const analytics = getAnalytics(fireApp)
export const auth = getAuth(fireApp)

onAuthStateChanged(auth, async (user) => {
    // console.log("onAuthStateChanged", user)
    // console.log("onAuthStateChanged store user", store.state.user)

    if (user != null){
        setUserId(analytics, user.uid)
    }

    if (user == null && store.state.user.data !== null) {
        await store.dispatch("signOut")
        await router.push({ path: "/login" })
        return
    }

    if (
        (store.state.user.data == null || store.state.user.claims == null) &&
        user !== null
    ) {
        let result = await store.dispatch("fetchUser", user)
        // console.log("fetchUser result",result)
        // console.log("fetchUser claims",store.state.user.claims)
        if (result){
            if (store.state.user.claims.hasOwnProperty("role")){
                switch (store.state.user.claims.role) {
                    case "SYSTEM_USER":
                        await router.push({path: "/dashboard"})
                        break
                    case "EMPLOYEE":
                        await router.push({path: "/find-a-shift"})
                        break
                    case "CLIENT":
                        // console.log("sending to /requests")
                        await router.push({path: "/requests"})
                        break
                }

            } else {
                await router.push({path: "/"})
            }
        }

    }
    //
    // if ((store.state.user.data == null || store.state.user.claims == null)) {
    //     console.log("auth fetching user")
    //     let result = await store.dispatch("fetchUser", user)
    //     console.log("auth result",result)
    //     await router.push({path: "/dashboard"})
    // }
})

router.beforeEach(async (to, from, next) => {
    let isLoggedIn = await store.dispatch("isLoggedIn")
    let requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    let roleAccess = to.meta.role
    // console.log("requiresAuth", requiresAuth)
    // console.log("isLoggedIn", isLoggedIn)
    // console.log("store.state.user", store.state.user)
    // console.log("isAllowed()", isAllowed())
    // console.log("to.name", to.name)

    if (!isAllowed() && isLoggedIn) {
        next("404")
        return
    }

    if (to.name === "login" && isLoggedIn) {
        // console.log("going to clientList")
        switch (store.state.user.claims.role) {
            case "SYSTEM_USER":
                next("/dashboard")
                break
            case "EMPLOYEE":
                next("/find-a-shift")
                break
            case "CLIENT":
                next("/requests")
                break
        }
        return
    }

    if (to.name === "login" && isLoggedIn && !store.state.user.claims.hasOwnProperty("role")) {
        next("/")
        return
    }

    if ((requiresAuth && !isLoggedIn) || !isAllowed()) {
        next("login")
        return
    }
    else {
        next()
        return
    }

    function isAllowed() {
        if (roleAccess == null || store.state.user.claims == null) {
            return true
        }
        return roleAccess.includes(store.state.user.claims.role)
    }
})

export const firestore = getFirestore()
const appInstance = createSSRApp(App)
import Tooltip from "./components/Tooltip.vue"

// eslint-disable-next-line vue/multi-word-component-names
appInstance.component("multiselect", Multiselect)
appInstance.component("tooltip", Tooltip)
appInstance.component("VueDatePicker", VueDatePicker)
appInstance.component("easyDataTable", Vue3EasyDataTable)
appInstance.use(router)
appInstance.use(store)

moment.locale(store.state.locale)
appInstance.config.globalProperties.$moment = moment
appInstance.use(GlobalComponents)
appInstance.use(moment)
appInstance.config.devotools = true
appInstance.use(i18n)
appInstance.use(SidebarPlugin)
appInstance.use(VueSweetalert2)

const head = createHead()
appInstance.use(head)
appInstance.mixin(VueHeadMixin)

import directives from "./directives/"
directives(appInstance)
appInstance.mount("#app")