<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
      formClasses,
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend" >
      <span class="input-group-text" :class="{'disabled': disabled}">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot>
      <input
        :value="modelValue"
        v-bind="$attrs"
        class="form-control"
        :placeholder="$t(placeholder)"
        :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
          inputClasses,
        ]"
        :disabled="disabled"
        aria-describedby="addon-right addon-left"
        v-on="listeners"
      />
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text" :class="{'disabled': disabled}">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock" >
    </slot>
    <slot name="helpBlock">
      <div
        v-if="error"
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: "base-input",
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined,
    },
    disabled: {
      default: false,
      type: Boolean,
      description: "disable input",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    placeholder: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    formClasses: {
      type: String,
      description: "Form css classes",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    modelValue: {
      type: [String, Number],
      description: "Input value",
    },
    addonRight: {
      type: String,
      description: "Addon right",
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon",
    },
  },
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    listeners() {
      return {
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      }
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      )
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value
      this.$emit("update:modelValue", value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit("focus", value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit("blur", value)
    },
  },
}
</script>
<style>
.input-group-append {
  bottom: 6px;
  right: 7px;
  position: absolute;
  color: gray;
  font-size: small;
  z-index: 10;
}
input.form-control.form-control-alternative {
  width: 100%;
}

.input-group-alternative .form-control, .input-group-alternative .input-group-text {
  border: 0;
  box-shadow: none;
}

.input-group-alternative .form-control, .input-group-alternative .input-group-text {
  background-color: white;
}


.form-group.phone-input .input-group-prepend {
  border-radius: 5px 0 0 5px;
  overflow: hidden;
}

.form-group.phone-input input.form-control {
  border-radius: 0 5px 5px 0 !important;
  overflow: hidden;
}
</style>
