<template>
    <section class="section first-section section-lg section-shaped overflow-hidden my-0" style="    height: calc(100vh - 70.4px);
    min-height: 500px;">

      <div class="shape shape-style-1 shape-default shape-skew">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container ps-md-5 pe-md-5 py-0 pb-lg" style="    display: flex;">
            <div class="row justify-content-between align-items-center">
                <div class="col-lg-12 mb-5 mb-lg-0 text-start">
                    <h3 class="text-primary fw-bold custom-header-text">{{ $t("Are you a certified nurse?") }}</h3>
                    <h3 class="text-primary fw-bold custom-header-text">{{ $t("Would you like to increase your salary?") }}</h3>
                  <h3 class="text-primary fw-bold custom-header-text">{{ $t("Would you like to have flexibility?") }}</h3>
                    <h1 class="text-primary fw-bold p-2 mt-3 ps-0 custom-header-text">{{ $t("You are in the right place!") }}</h1>
<!--                    <p class="text-start text-white mt-4">Argon Design System comes with four pre-built pages to help you get-->
<!--                        started faster. You can change the text and images and you're good to go.</p>-->
                    <a href="#whatWeDo"
                       class="btn btn-default mt-4 fw-bold">{{ $t("Learn more") }}</a>
                </div>
<!--                <div class="col-lg-6 mb-lg-auto hideOn1200">-->
<!--                  <div class="d-block w-100">-->
<!--                  <h1 style="    font-size: 700px;-->
<!--    background: -webkit-linear-gradient(#ffdd58, #7aa8bc);-->
<!--    -webkit-background-clip: text;-->
<!--    -webkit-text-fill-color: transparent;    position: absolute;-->
<!--    right: 1%;-->
<!--    bottom: -24px;" class="fa fa-arrow-trend-up text-white"></h1>-->
<!--                    <img width="300" src="@/assets/logo_icon.png">-->
<!--                  </div>-->
<!--                  <img src="@/assets/images/successful-medical.jpg" class="d-block w-100 rounded-4" alt="...">-->

                  <!--                    <div class="rounded shadow-lg overflow-hidden transform-perspective-right">-->
<!--                      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">-->
<!--                        <div class="carousel-indicators">-->
<!--                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>-->
<!--&lt;!&ndash;                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>&ndash;&gt;-->
<!--&lt;!&ndash;                          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="carousel-inner">-->
<!--                          <div class="carousel-item active">-->
<!--                            <img src="@/assets/images/successful-medical.jpg" class="d-block w-100" alt="...">-->
<!--                          </div>-->
<!--&lt;!&ndash;                          <div class="carousel-item">&ndash;&gt;-->
<!--&lt;!&ndash;                            <h1>Helloooo</h1>&ndash;&gt;-->
<!--&lt;!&ndash;                            <img src="@/assets/images/successful-medical.jpg" class="d-block w-100" alt="...">&ndash;&gt;-->
<!--&lt;!&ndash;                          </div>&ndash;&gt;-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </section>
</template>
<script>
export default {
}
</script>
<style>
.section-shaped .shape-style-1.shape-default {
  background: linear-gradient(150deg,#046380 15%,#046380 70%, #057b9f 94%);
}

/*.section-shaped .shape-skew {*/
/*  -webkit-transform: skewY(-8deg);*/
/*  transform: skewY(-8deg);*/
/*  -webkit-transform-origin: 0;*/
/*  transform-origin: 0;*/
/*}*/

.section-shaped .shape {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.section-shaped {
  margin: 180px 0;
  position: relative;
}

.section-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.overflow-hidden {
  overflow: hidden!important;
}

.section-shaped .shape.shape-skew+.container {
  padding-top: 0;
}
.section-shaped .shape+.container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 0;
  height: 100%;
}

.section-shaped .shape-style-1 span {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

.section-shaped .shape span {
  position: absolute;
}

.section-shaped .shape-style-1 :first-child {
  left: -4%;
  bottom: auto;
  background: hsla(0,0%,100%,.1);
  /*background-image: url(../assets/images/nurseicons.png);*/
  /*background-position: -136px 0;*/
  /*background-size: 264px;*/
  /*background-repeat: no-repeat;*/
  /*opacity: 0.1;*/
}

/*.section-shaped .shape-style-1 :nth-child(2) {*/
/*  right: 4%;*/
/*  top: 10%;*/
/*  background: hsla(0,0%,100%,.1);*/
/*}*/

.section-shaped .shape-style-1 :nth-child(3) {
  top: 224px;
  right: 3.66666%;
  background: hsla(0,0%,100%,.3);
  /*background-image: url(../assets/images/nurseicons.png);*/
  /*background-position: -7px 0;*/
  /*background-size: 264px;*/
  /*background-repeat: no-repeat;*/
  /*opacity: 0.1;*/
}

.section-shaped .shape-style-1 :nth-child(4) {
  top: 377px;
  right: 1%;
   background: hsla(0,0%,100%,.15);
  /*background-image: url(http://localhost:8080/img/nurseicons.a59f3c04.png);*/
  /*background-position: -7px -140px;*/
  /*background-size: 265px;*/
  /*background-repeat: no-repeat;*/
  /*opacity: 0.1;*/
}

.section-shaped .shape-style-1 :nth-child(5) {
  top: 38%;
  left: 1%;
  right: auto;
  background: hsla(0,0%,100%,.05);
  /*background-image: url(../assets/images/nurseicons.png);*/
  /*background-position: -7px 0;*/
  /*background-size: 264px;*/
  /*background-repeat: no-repeat;*/
  /*opacity: 0.1;*/
}

.section-shaped .shape-style-1 :nth-child(6) {
  width: 200px;
  height: 200px;
  top: 44%;
  left: 10%;
  right: auto;
  background: hsla(0,0%,100%,.15);
  /*background-image: url(../assets/images/nurseicons.png);*/
  /*background-position: -221px -223px;*/
  /*background-size: 430px;*/
  /*background-repeat: no-repeat;*/
  /*opacity: 0.1;*/
}

.section-shaped .shape-style-1 span {
  height: 120px;
  width: 120px;
  border-radius: 50%;
}

/*.section-shaped .shape-skew span {*/
/*  -webkit-transform: skew(8deg);*/
/*  transform: skew(8deg);*/
/*}*/

@media (min-width: 992px) {
  .transform-perspective-right {
    -webkit-transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
    transform: scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg);
  }

}
@media (max-width: 992px) {
  section.section.first-section.section-lg.section-shaped.overflow-hidden.my-0 {
    height: inherit !important;
  }
}


</style>
