<template>
  <div>
    <base-header class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
          <div v-if="loadingProcess === '' && requests.length === 0" class="col-xl-12 order-xl-1">
            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row align-items-center">
                    <div class="col-8 text-start">
                      <h5 v-if="!isUpdate" class="mb-0">{{ $t("Create multiple requests") }}</h5>
                      <h5 v-else class="mb-0">Update Request</h5>
                    </div>
                  </div>

                </div>
              </template>
              <form>
                <!--                <div class="text-start fw-bolder text-uppercase text-muted">Personal Details </div>-->
                <!--                <hr class="mt-0">-->
                <div class="ps-lg-4">
                  <div class="row">
                    <div v-if="userRole === 'SYSTEM_USER'" class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.clientId.$error }">
                        <label class="form-label">{{ $t("Select Client") }}</label>
                        <multiselect
                            v-model="model.clientId"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            :placeholder="$t('Select Client')"
                            :options="clients" :multiple="false"
                            :error="v$.model.clientId.$errors.map( err => { return err.$message })[0]"

                        ></multiselect>
                        <div v-if="v$.model.clientId.$invalid" class="text-danger invalid-feedback"
                             style="display: block;">
                          {{
                            v$.model.clientId.$errors.map(err => {
                              return err.$message
                            })[0]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.position.$error }">
                        <label class="form-label">{{ $t("Job position") }}</label>
                        <multiselect
                            v-model="model.position"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            :placeholder="$t('Select position')"
                            :options="positions" :multiple="false"
                            :error="v$.model.position.$errors.map( err => { return err.$message })[0]"

                        ></multiselect>
                        <div v-if="v$.model.position.$invalid" class="text-danger invalid-feedback"
                             style="display: block;">
                          {{
                            v$.model.position.$errors.map(err => {
                              return err.$message
                            })[0]
                          }}
                        </div>
                      </div>
                    </div>
                                        <div class="col-lg-6">
                                          <div class="form-group has-label">
                                            <label class="form-label">{{ $t("Deadline") }}</label>
                                            <multiselect
                                                v-model="model.deadlineDays"
                                                :show-labels="false"
                                                label="label"
                                                track-by="key"
                                                :placeholder="$t('Select deadline days')"
                                                :options="deadlineDaysOptions" :multiple="false"
                                            >
                                              <template #singleLabel="val">
                                                {{val.option.label}}
                                              </template>
                                            </multiselect>
                                          </div>
                                        </div>
                  </div>
                </div>
<!--                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Request timeframe") }}</div>-->
<!--                <hr class="mt-0">-->
                <div class="ps-lg-4">
                  <div class="row align-items-center shift-times">
                    <div class="col-lg-4 text-start"
                         :class="{'text-success fw-bold': model.morning.startDate != null && model.morning.endDate != null}"> {{ $t("Morning / Long day") }}: </div>
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.morning.startDate.$error }">
                        <label class="form-label">{{ $t("Start time") }}</label>
                        <VueDatePicker
                            v-model="model.morning.startDate"
                            time-picker
                            :start-time="{ hours: 10, minutes: 0 }"
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            model-type="HH:mm"
                            format="HH:mm"
                            :flow="['time']"
                            :placeholder="$t('Start time')"
                        ></VueDatePicker>
                        <div v-if="v$.model.morning.startDate.$invalid" class="text-danger invalid-feedback"
                             style="display: block;">
                          {{
                            v$.model.morning.startDate.$errors.map(err => {
                              return err.$message
                            })[0]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.morning.endDate.$error }">
                        <label class="form-label">{{ $t("End time") }}</label>
                        <VueDatePicker
                            v-model="model.morning.endDate"
                            :start-time="{ hours: 10, minutes: 0 }"
                            time-picker
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            format="HH:mm"
                            model-type="HH:mm"
                            :flow="['time']"
                            :placeholder="$t('End time')"
                        ></VueDatePicker>
                        <!--                        <div v-if="v$.model.endDate.$invalid" class="text-danger invalid-feedback"-->
                        <!--                             style="display: block;">-->
                        <!--                          {{-->
                        <!--                            v$.model.endDate.$errors.map(err => {-->
                        <!--                              return err.$message-->
                        <!--                            })[0]-->
                        <!--                          }}-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center shift-times">
                    <div class="col-lg-4 text-start"
                         :class="{'text-success fw-bold': model.evening.startDate != null && model.evening.endDate != null}"
                    >
                      {{ $t("Evening") }}:
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.evening.startDate.$error }">
                        <label class="form-label">{{ $t("Start time") }}</label>
                        <VueDatePicker
                            v-model="model.evening.startDate"
                            time-picker
                            :start-time="{ hours: 10, minutes: 0 }"
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            model-type="HH:mm"
                            format="HH:mm"
                            :flow="['time']"
                            :placeholder="$t('Start time')"
                        ></VueDatePicker>
                        <div v-if="v$.model.evening.startDate.$invalid" class="text-danger invalid-feedback"
                             style="display: block;">
                          {{
                            v$.model.evening.startDate.$errors.map(err => {
                              return err.$message
                            })[0]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.evening.endDate.$error }">
                        <label class="form-label">{{ $t("End time") }}</label>
                        <VueDatePicker
                            v-model="model.evening.endDate"
                            :start-time="{ hours: 10, minutes: 0 }"
                            time-picker
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            format="HH:mm"
                            model-type="HH:mm"
                            :flow="['time']"
                            :placeholder="$t('End time')"
                        ></VueDatePicker>
                        <!--                        <div v-if="v$.model.endDate.$invalid" class="text-danger invalid-feedback"-->
                        <!--                             style="display: block;">-->
                        <!--                          {{-->
                        <!--                            v$.model.endDate.$errors.map(err => {-->
                        <!--                              return err.$message-->
                        <!--                            })[0]-->
                        <!--                          }}-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center shift-times mb-3">
                    <div class="col-lg-4 text-start"
                         :class="{'text-success fw-bold': model.night.startDate != null && model.night.endDate != null}"
                    >
                      {{ $t("Night") }}:
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.night.startDate.$error }">
                        <label class="form-label">{{ $t("Start time") }}</label>
                        <VueDatePicker
                            v-model="model.night.startDate"
                            time-picker
                            :start-time="{ hours: 10, minutes: 0 }"
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            model-type="HH:mm"
                            format="HH:mm"
                            :flow="['time']"
                            :placeholder="$t('Start time')"
                        ></VueDatePicker>
                        <div v-if="v$.model.night.startDate.$invalid" class="text-danger invalid-feedback"
                             style="display: block;">
                          {{
                            v$.model.night.startDate.$errors.map(err => {
                              return err.$message
                            })[0]
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-group has-label" :class="{'has-danger': v$.model.night.endDate.$error }">
                        <label class="form-label">{{ $t("End time") }}</label>
                        <VueDatePicker
                            v-model="model.night.endDate"
                            :start-time="{ hours: 10, minutes: 0 }"
                            time-picker
                            minutes-increment="10"
                            minutes-grid-increment="10"
                            format="HH:mm"
                            model-type="HH:mm"
                            :flow="['time']"
                            :placeholder="$t('End time')"
                        ></VueDatePicker>
                        <!--                        <div v-if="v$.model.endDate.$invalid" class="text-danger invalid-feedback"-->
                        <!--                             style="display: block;">-->
                        <!--                          {{-->
                        <!--                            v$.model.endDate.$errors.map(err => {-->
                        <!--                              return err.$message-->
                        <!--                            })[0]-->
                        <!--                          }}-->
                        <!--                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-start fw-bolder text-uppercase text-muted">{{ $t("Request Dates") }}</div>
                <hr class="mt-0">
                <div class="ps-lg-4">
                  <div class="row text-start">
                    <VueDatePicker
                        v-model="model.dates"
                        format="dd/MM/yyyy"
                        model-type="dd/MM/yyyy"
                        :enable-time-picker="false"
                        :multi-dates="{ limit: 500 }"
                        :min-date="$moment().add(model.deadlineDays.key, 'days').toDate()"
                        placeholder="Select dates"
                    />
                    <div v-if="v$.model.dates.$invalid" class="text-danger invalid-feedback" style="display: block;">
                      {{ v$.model.dates.$errors.map( err => { return err.$message })[0] }}
                    </div>
                    <label>*{{ $t("Some days might be disabled based on the deadline option selected") }}</label>
                  </div>
                </div>
                <div class="col-12 text-end">
                  <base-button v-if="!isUpdate" class="mt-2 me-2" outline type="info" @click="resetForm()">
                    {{ $t("Reset") }}
                  </base-button>
<!--                  <submit-button :alert-message="errorMessage" :state="loadingProcess" @clicked="submit">-->
<!--                    {{ $t(isUpdate ? 'Update' : 'Submit') }}-->
<!--                  </submit-button>-->
                  <base-button class="mt-2" @click="calculateRequests()">
                    {{ $t('Calculate shifts') }}
                  </base-button>
                </div>
              </form>
            </card>
          </div>
          <div v-if="requests.length > 0" class="col-xl-12 order-xl-1 mt-3">

            <card shadow type="secondary">
              <template #header>
                <div class="border-0">
                  <div class="row text-start">
                    <div class="col-8 text-start">
                      <h5 v-if="loadingProcess !== ''" class="mb-0">{{ $t(`Requests sent for creation`) }}</h5>
                      <h5 v-else class="mb-0">{{ requests.length }} {{ $t("requests to be submitted") }}</h5>

                    </div>
                    <div class="col-4 text-end">
                      <button v-if="loadingProcess === ''" class="btn btn-primary" @click="resetForm(true)">{{ $t("Edit") }}</button>
                      <button v-if="loadingProcess === ''" class="btn btn-danger ms-2" @click="resetForm()">{{ $t("Reset") }}</button>
                      <button v-if="loadingProcess === 'done'" class="btn btn-primary ms-2" @click="resetForm()">{{ $t("Submit New") }}</button>
                    </div>
                  </div>
                </div>
              </template>

              <div class="ps-lg-4">
                <div class="row">
                  <table id="requestsTable" class="table">
                    <tr>
                      <th>{{ $t("Position") }}</th>
                      <th>{{ $t("Shift date/time") }}</th>
                      <th>{{ $t("Claim deadline") }}</th>
                      <th v-if="userRole !== 'CLIENT'">{{ $t("Company commission") }}</th>
                      <th v-if="userRole !== 'CLIENT'">{{ $t("Employee earnings") }}</th>
                      <th>{{ $t("Total cost") }}</th>
                      <th></th>
                    </tr>
                    <tr v-for="(request, index) in requests" :key="request">
                      <td>{{ request.position.name }}</td>
                      <td>{{ $moment(request.startDate).format("DD/MM/YYYY HH:mm") }} {{ $moment(request.endDate).format(" - HH:mm") }}</td>
                      <td>{{ $moment(request.deadline).format("DD/MM/YYYY HH:mm") }}</td>
                      <td v-if="userRole !== 'CLIENT'">{{ request.cost.companyCommission/100+'€' }}</td>
                      <td v-if="userRole !== 'CLIENT'">{{ request.cost.employeeEarnings/100+'€' }}</td>
                      <td>{{ request.cost.totalCost/100+'€' }}</td>
                      <td>
                        <div v-if="request.hasOwnProperty('success')" class="bg-transparent">
                          <i class="fa fa-check text-success"></i>
                          Created
                        </div>
                        <div v-else-if="request.hasOwnProperty('failed')" class="bg-transparent">
                          <i class="fa fa-times text-danger"></i>
                          Failed
                        </div>
                        <i v-else-if="loadingProcess === 'done'" class="fa fa-check"> </i>
                        <button v-if="loadingProcess === ''" class="btn btn-outline-danger" @click="removeRequest(index)"><i class="fa fa-times"></i></button>
                      </td>
                    </tr>
                  </table>
                </div>
                <div v-if="loadingProcess === ''" class="row">
                  <div class="col-12 text-end">
                  <base-button class="mt-2" @click="submitMulti()">
                    {{ $t('Submit shifts') }}
                  </base-button>
                  </div>
                </div>
              </div>
            </card>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirebaseFunctionsHelper from "@/helpers/firebaseFunctionsHelper"
import useVuelidate from "@vuelidate/core"
import {helpers, maxValue, minValue, required, requiredIf} from "@vuelidate/validators"
import {calculateShiftCost} from "@/helpers/shiftCostCalculator"
import {toCents} from "@/helpers/common"
import BaseButton from "@/components/BaseButton.vue"
//
// const maxIfNotEmpty = (param) => helpers.withParams(
//     { type: "maxIfNotEmpty", value: param }, (value) => {
//
//       if (param == null || param === "" || value == null || value === ""){
//         return true
//       }
//
//       return param < value
//     })
export default {
  name: "RequestFormMulti",
  components: {BaseButton},
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      clientConfigs: null,
      requests: [],
      refreshKey: 1,
      positions: [],
      clients: [],
      isUpdate: false,
      errorMessage: "",
      loadingProcess: "",
      loadingMessage: "",
      deadlineDaysOptions: [
          {key: 1, label: this.$t("countDays", {count: 1})},
          {key: 3, label: this.$t("countDays", {count: 3})},
          {key: 5, label: this.$t("countDays", {count: 5})},
          {key: 7, label: this.$t("countDays", {count: 7})},
          {key: 10, label: this.$t("countDays", {count: 10})},
          {key: 15, label: this.$t("countDays", {count: 15})},
          {key: 20, label: this.$t("countDays", {count: 20})},
      ],
      model: {
        // requestStatus: {id: "PENDING", name: this.$t("Pending")},
        clientId: null,
        deadlineDays: {key: 3, label: this.$t("countDays", {count: 3})},
        morning: {
          startDate: null,
          endDate: null,
        },
        evening: {
          startDate: null,
          endDate: null,
        },
        night: {
          startDate: null,
          endDate: null,
        },
        position: null,
        dates: []
      }
    }
  },
  validations() {
    return {
      model: {
        clientId: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        deadlineDays: {
          required: helpers.withMessage(this.$t("Value is required"), required),
          // maxValue: helpers.withMessage(this.$t("Deadline must be equal or before Shift start time."), maxValue(this.model.startDate))
        },
        morning: {
          startDate: {
            requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.morning.endDate ?? false)),
            // maxValue: maxValue(this.model.endDate),
            // maxIfNotEmpty: helpers.withMessage("The maximum value allowed is "+ this.$moment(this.model.endDate).format(), maxIfNotEmpty(this.model.endDate))
          },
          endDate: {
            requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.morning.startDate)),
            // minValue: minValue(this.model.startDate),
          },
        },
        evening: {
          startDate: {
            requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.evening.endDate)),
            // maxValue: maxValue(this.model.endDate),
            // maxIfNotEmpty: helpers.withMessage("The maximum value allowed is "+ this.$moment(this.model.endDate).format(), maxIfNotEmpty(this.model.endDate))
          },
          endDate: {
            requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.evening.startDate)),
            // minValue: minValue(this.model.startDate),
          },
        },
        night: {
          startDate: {
            requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.night.endDate)),
            // maxValue: maxValue(this.model.endDate),
            // maxIfNotEmpty: helpers.withMessage("The maximum value allowed is "+ this.$moment(this.model.endDate).format(), maxIfNotEmpty(this.model.endDate))
          },
          endDate: {
            requiredIf: helpers.withMessage(this.$t("Value is required"), requiredIf(this.model.night.startDate)),
            // minValue: minValue(this.model.startDate),
          },
        },
        position: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
        dates: {
          required: helpers.withMessage(this.$t("Value is required"), required)
        },
      },
    }
  },
  computed: {
    userRole() {
      return this.$store.state.user.claims.role ?? null
    },
    // requestCost() {
    //   this.refreshKey
    //   this.v$.$touch()
    //
    //   if (this.v$.model.$invalid) {
    //     console.log(this.v$.$errors)
    //     return false
    //   }
    //   console.log("calculatioingggg", this.model.clientId)
    //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //   this.model.cost = calculateShiftCost(this.model.position, this.$store.state.settings, this.model.startDate, this.model.endDate, this.model.clientId.id)
    //   console.log("this.model.cost", this.model.cost)
    //   return true
    // }
  },
  watch: {
    "model.morning.startDate": function (newValue) {
      console.log("newValue", newValue)
      if (newValue && this.model.morning.endDate == null) {
        this.model.morning.endDate = this.$moment(newValue, "HH:mm").add("8", "hours").format("HH:mm")
      }
    },
    "model.clientId": async function (client) {
      console.log("model.clientId", client)
      if (client) {
        await this.getSettings(client.id)
      }
    },
    "model.evening.startDate": function (newValue) {
      console.log("newValue", newValue)
      if (newValue &&  this.model.evening.endDate == null) {
        this.model.evening.endDate = this.$moment(newValue, "HH:mm").add("8", "hours").format("HH:mm")
      }
    },
    "model.night.startDate": function (newValue) {
      console.log("newValue", newValue)
      if (newValue && this.model.night.endDate == null) {
        this.model.night.endDate = this.$moment(newValue, "HH:mm").add("8", "hours").format("HH:mm")
      }
    },
    // "model.dates": function (newValue) {
    //   console.log("newValue", newValue)
    //   if (this.model.endDate == null) {
    //     this.model.endDate = this.$moment(newValue).add("8", "hours").toDate()
    //   }
    // },
  },
  async mounted() {
    await this.getPositions()
    if (this.userRole === "SYSTEM_USER") {
      await this.getClients()
    } else {
      await this.getSettings(this.$route.params.id)
    }
    if (this.$route.params.hasOwnProperty("id")) {
      this.isUpdate = true
      await this.getRequest()
    }
  },
  methods: {
    resetForm(forEdit = false){
      if (!forEdit) {
        this.model = {
          clientId: null,
          deadlineDays: {key: 3, label: this.$t("3 days before")},
          morning: {
            startDate: null,
            endDate: null,
          },
          evening: {
            startDate: null,
            endDate: null,
          },
          night: {
            startDate: null,
            endDate: null,
          },
          position: null,
          dates: []
        }
      }
      this.loadingProcess = ""
      this.requests = []
      this.v$.$reset()
    },
    removeRequest(index){
      const dateString = this.$moment(this.requests[index].startDate).format("DD/MM/YYYY")
      const dateIndex = this.model.dates.findIndex(r => r === dateString)
      this.model.dates.splice(dateIndex, 1)
      this.requests.splice(index, 1)
    },
    async calculateRequests() {
      this.v$.$touch()

      if (this.v$.model.$invalid) {
        console.log(this.v$.$errors)
        return
      }

      this.model.dates.forEach(date => {
        if (this.model.morning.startDate != null){
          const request = this.createRequestPayload(date, this.model.morning.startDate, this.model.morning.endDate)
          this.requests.push(request)
        }
        if (this.model.evening.startDate != null){
          const request = this.createRequestPayload(date, this.model.evening.startDate, this.model.evening.endDate)
          this.requests.push(request)
        }
        if (this.model.night.startDate != null){
          const request = this.createRequestPayload(date, this.model.night.startDate, this.model.night.endDate)
          this.requests.push(request)
        }

      })

      await this.$nextTick()
      document.getElementById("requestsTable").scrollIntoView()
    },
    /**
     *
     * @param date
     * @param startDate
     * @param endDate
     * @returns {{clientId: null, cost: {companyCommission: *|number, employeeEarnings: *|number, currency: string, totalCost: *|number}, endDate: string, position: null, deadline: string, startDate: string}}
     */
    createRequestPayload(date, startDate, endDate){
      console.log("calculateRequests date", date + " " + startDate)
      const startDateFormatted = this.$moment(date + " " + startDate, "DD/MM/YYYY HH:mm")
      const endDateFormatted = this.$moment(date + " " + endDate, "DD/MM/YYYY HH:mm").add(endDate < startDate ? 1 : 0, "day")
      const deadlineFormatted = this.$moment(date + " " + startDate, "DD/MM/YYYY HH:mm").add(-this.model.deadlineDays.key, "days")

      return {
        clientId: this.model.clientId,
        deadline: deadlineFormatted.toISOString(),
        startDate: startDateFormatted.toISOString(),
        endDate: endDateFormatted.toISOString(),
        position: this.model.position,
        cost: calculateShiftCost(this.model.position, this.clientConfigs, startDateFormatted.toISOString(), endDateFormatted.toISOString(), this.model.clientId.id)
      }
    },
    async getSettings(id) {
      const clientSettings = await this.$store.dispatch("fetchClient", id)
      if (!clientSettings.hasOwnProperty("configs") || clientSettings.configs == null) {
        this.clientConfigs = await this.$store.dispatch("fetchSettings")
      } else {
        this.clientConfigs = clientSettings.configs
      }
    },
    async getClients() {
      await this.$store.dispatch("fetchClients").then((data) => {
        // console.log("fetchClients", data)
        this.clients = data
      })
    },
    async getPositions() {
      this.positions = []
      let allPositions = await this.$store.dispatch("fetchAllPositions")
      if (this.userRole === "SYSTEM_USER") {
        this.positions = allPositions
      } else {
        await this.$store.dispatch("fetchClient", this.$store.state.user.claims.clientId).then((data) => {
          console.log("fetchClient fetchClient", data)
          if (data.hasOwnProperty("name")) {
            this.model.clientId = {id: this.$store.state.user.claims.clientId, name: data.name}
          }
          if (data.hasOwnProperty("positionIds")) {
            data.positionIds.forEach(p => {

              let selected = allPositions.find(pos => pos.id === p)

              console.log("allPositions selected", selected)
              if (selected ?? false) {
                console.log("allPositions selected got it")
                this.positions.push(selected)
              }
            })
          }
        })
      }
    },
    customCityLabel(option) {
      return this.$t(option)
    },
    async getRequest() {
      const data = await this.$store.dispatch("fetchRequest", this.$route.params.id)

      let selectedPositions = []
      data.forEach(positionsId => {
        let selected = this.positions.find(pos => pos.key === positionsId)
        if (selected) {
          selectedPositions.push(selected)
        }
      })

      this.model = data
      this.model.positionIds = selectedPositions
    },
    async submitMulti(){
      this.loadingProcess = "processing"
      this.$swal.fire({
        // title: "Creating requests...",
        html: "<b>Creating requests...</b>",
        timer: 0,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })
      const multiShiftPayload = {
        clientId: this.model.clientId.id,
        requests: []
      }

      for (const request of this.requests) {
        let payload = JSON.parse(JSON.stringify(request))

        payload.positionId = payload.position.id
        delete payload.position

        payload.clientId = payload.clientId.id
        // payload.requestStatus = payload.requestStatus.id

        console.log("payload", payload)

        multiShiftPayload.requests.push(payload)
      }


      console.log("multiShiftPayload", multiShiftPayload)
      await FirebaseFunctionsHelper.callFunction(
          "request-create",
          multiShiftPayload
      )
          .then((result) => {
            console.log("res", result)
            // eslint-disable-next-line no-prototype-builtins
            if (result != null && result.hasOwnProperty("message")) {
              console.log("result message", result.message)
              this.loadingProcess = "failed"
              this.$swal.fire({
                icon: "error",
                html: result.message,
                title: "Something went wrong!",
              })
              return
            }
            console.log("submitUser result", result)
            this.loadingProcess = "done"
            this.$swal.fire({
              icon: "success",
              html: `Successfully created ${multiShiftPayload.requests.length} requests.`,
              timer: 1500,
              timerProgressBar: true,
              title: "Requests created!",
              showConfirmButton: false
            })
          })
          .catch((error) => {
            this.loadingProcess = "failed"
            this.$swal.fire({
              icon: "error",
              html: error.message,
              title: "Something went wrong!",
            })
            console.log("submitUser error", error)
          })
    },
  },
}
</script>
<style>
.form-group.has-label {
  padding-top: 0.5rem !important;
}
.dp__selection_preview div {
  display: none !important;
}

.shift-times.row.align-items-center:hover {
  background-color: #dcddde99;
  transition: 0.3s;
}


button.btn.btn-outline-danger:hover {
  color: white;
}

button.btn.btn-outline-danger {
  border-bottom-width: initial;
  padding: 6px 12px;
  color: red;
  box-shadow: none;
}
</style>