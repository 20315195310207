<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h5 class="mb-0 text-start" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h5>
        </div>
        <div class="col text-end">
          <base-button tag="a" href="/clients/new" type="primary" size="sm">{{ $t("Add New") }}</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush text-start"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="tableData"
      >
        <template #columns>
          <th>Name</th>
          <th>Status</th>
          <th>Date Created</th>
          <th></th>
        </template>

        <template #default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <a :href="'/clients/' + row.item.id + '/info'">
                  <span class="name mb-0 text-sm fw-bolder">{{ row.item.name }}</span>
                </a>
              </div>
            </div>
          </th>
          <td>
            <div class="badge p-1 font-size-xs" :class="row.item.status === 'VERIFIED' ? 'bg-gradient-green' : 'bg-danger'">
              <span class="status">{{ $t(row.item.status) }}</span>
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              <!--              <span class="completion mr-2">{{ row.item.completion }}%</span>-->
              <!--              <div>-->
              <!--                <base-progress-->
              <!--                  :type="row.item.statusType"-->
              <!--                  :show-percentage="false"-->
              <!--                  class="pt-0"-->
              <!--                  :value="row.item.completion"-->
              <!--                />-->
              <!--              </div>-->
              <div class="name mb-0 text-md-left">{{ row.item.createdAt }}</div>
            </div>
          </td>

          <td class="text-end">
            <base-button
              tag="a"
              type="primary"
              :href="'/clients/' + row.item.id"
              class="border-0 me-2 text-white"
              size="md"
              >
              {{ $t("View") }}
            </base-button>
<!--            <base-button-->
<!--              type="danger"-->
<!--              class="border-0"-->
<!--              size="md"-->
<!--              >-->
<!--              Delete-->
<!--</base-button>-->
          </td>
        </template>
      </base-table>
    </div>

    <!--    <div-->
    <!--      class="card-footer d-flex justify-content-end"-->
    <!--      :class="type === 'dark' ? 'bg-transparent' : ''"-->
    <!--    >-->
    <!--      <base-pagination total="30"></base-pagination>-->
    <!--    </div>-->
  </div>
</template>
<script>
export default {
  name: "clients-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      loading: true,
      tableData: [],
    }
  },
  async mounted() {
    await this.getClients()
  },
  methods: {
    async getClients() {
      // if (
      //   this.$store.state.system.companies.length > 0 &&
      //   this.$store.getters.isCacheExpired
      // ) {
      //   this.tableData = this.$store.state.system.companies
      //   return
      // }
      await this.$store.dispatch("fetchClients").then((data) => {
        // console.log("fetchClients", data)
        this.tableData = data
        this.loading = false
      })
    },
  },
}
</script>
<style></style>
