<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-8 pt-md-5">
      <!-- Card stats -->
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <employees-table title="Partner List"></employees-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmployeesTable from "../../Tables/EmployeesTable"

export default {
  name: "employees-list",
  components: {
    EmployeesTable,
  },
  head() {
    return {
      title: "Partners List"
    }
  },
}
</script>
<style></style>
